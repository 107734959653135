<template>
    <div>
        <div class="digital-voting gutter">
            <div class="digital-voting-voting-view post">
                <div class="header">
                    <div class="return-button">
                        <router-link :to="{path: '/digitalvoting/' + eventId}">
                            <i class="mdi mdi-chevron-left"></i>
                            <span>Gå tillbaka</span>
                        </router-link>
                    </div>
                    <div class="header-content" v-if="voting && currentEvent">
                        <div class="icon-wrapper">
                            <img src="@/assets/digital-voting-icon.svg" alt="">
                        </div>
                        <div class="content-header">{{ voting.title }}</div>
                    </div>
                </div>

                <div class="digital-voting-template" v-if="voting && currentEvent">
                    <div class="content-header orange">{{ currentEvent.title }}</div>
                    <div class="content-header">{{ voting.title }}</div>
                    <div class="content">{{ voting.description }}</div>

                    <div class="options-wrapper">
                        <span class="max-options-disclaimer">Du kan välja totalt {{ voting.max_selected_options }} val</span>

                        <div class="options">
                            <div
                                v-for="option in options"
                                :key="option.id"
                                class="option emphasized-check"
                                :class="(voting.max_selected_options > 1) ? 'styled-checkbox' : 'styled-radio'">
                                <input
                                    name="options"
                                    v-model="selectedOptions"
                                    :type="(voting.max_selected_options > 1) ? 'checkbox' : 'radio'"
                                    :id="'option-' + option.id"
                                    :value="option.id"
                                    :disabled="isDisabled(option.id)"
                                />
                                <label :for="'option-' + option.id">
                                    {{ option.title }} <span class="check"></span>
                                </label>
                            </div>
                        </div>

                        <div class="centered">
                            <span class="selected-options-count" :class="[(canSubmit) ? 'valid' : 'invalid']">{{ getSelectedOptions().length }} / {{ voting.max_selected_options }}</span>
                        </div>

                        <div class="centered">
                            <button
                                type="button"
                                class="submit-vote"
                                :class="[(canSubmit) ? 'green' : 'inactive']"
                                :disabled="!canSubmit"
                                @click="submitVote">
                                Rösta
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal ref="successModal" type="window" class-name="light-header" :show-close="false">
            <template slot="header">Tack för din röst!</template>
            <p slot="body">Din röst har nu registrerats. Du kan ändra din röst tills dess att röstningen är avslutad genom att klicka på voteringen igen.</p>
            <template slot="footer">
                <button type="button" class="blue" @click="goBack">Gå tillbaka till mötet</button>
            </template>
        </modal>
        <modal ref="errorModal" type="window" class-name="light-header">
            <template slot="header">Något gick fel</template>
            <p slot="body">Din röst kunde inte sparas. Prova igen eller kontakta support.</p>
            <template slot="footer">
                <button type="button" class="blue" @click="closeErrorModal">Ok</button>
            </template>
        </modal>
        <loader type="component" />
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Loader from '@/components/Loader';
import Modal from '@/components/Modal';
import apiCall from '@/utils/api';
import sortBy from 'lodash/sortBy';

export default {
    props: {
        eventId: [Number, String],
        id: [Number, String],
        preloadedVoting: {
            type: Object,
            required: false
        }
    },

    components: {
        Loader,
        Modal
    },

    data() {
        return {
            loading: true,
            voting: null,
            selectedOptions: [],
            options: []
        };
    },

    computed: {
        ...mapGetters({
            events: 'digitalVoting/events'
        }),

        currentEvent() {
            if (this.events && this.events.length) {
                return this.events.find((event) => Number(event.id) === Number(this.eventId));
            }

            return null;
        },

        canSubmit() {
            return !!(this.getSelectedOptions().length);
        }
    },

    async beforeMount()
    {
        this.showLoading('component');
        this.options = await this.getOptions(this.id);

        this.voting = this.preloadedVoting || null;
        if (!this.voting) {
            this.voting = await this.getVoting(this.id);
        }

        this.hideLoading('component');
    },

    methods: {
        ...mapActions({
            showLoading: 'loader/show',
            hideLoading: 'loader/hide'
        }),

        async getOptions(votingId) {
            this.loading = true;
            let options = [];
            const optionsRequest = await apiCall('/user/getDigitalVotingOptions', {
                data: {
                    voting_id: votingId
                }
            }).catch(error => null);
            if (optionsRequest && optionsRequest.hasOwnProperty('options') && optionsRequest.options.length) {
                options = sortBy(optionsRequest.options, 'order');
            }
            this.loading = false;
            return options;
        },

        async getVoting(votingId) {
            this.loading = true;
            let voting = null;
            const votingRequest = await apiCall('/user/getDigitalVoting', {
                data: {
                    voting_id: votingId
                }
            }).catch(error => null);
            if (votingRequest && votingRequest.hasOwnProperty('voting') && votingRequest.voting) {
                voting = votingRequest.voting;
            }
            this.loading = false;
            return voting;
        },

        isDisabled(optionId) {
            // Never disable the other options if they are radios
            if (Number(this.voting.max_selected_options) === 1) {
                return false;
            }

            const selectedOptions = this.getSelectedOptions();
            return (Number(this.voting.max_selected_options) <= selectedOptions.length && !selectedOptions.includes(optionId));
        },

        getSelectedOptions() {
            return (Array.isArray(this.selectedOptions)) ? this.selectedOptions : [this.selectedOptions];
        },

        async submitVote() {
            this.showLoading('component');

            const response = await apiCall('/user/storeDigitalVote', {
                data: {
                    voting_id: this.voting.id,
                    options: this.getSelectedOptions()
                }
            }).catch(error => null);

            this.hideLoading('component');

            if (!response || !response.success) {
                this.$refs.errorModal.showModal();
            } else {
                this.$refs.successModal.showModal();
            }
        },

        goBack() {
            this.$router.push({
                name: 'digitalvotingspage',
                params: {
                    id: this.eventId
                }
            });
        },

        closeErrorModal() {
            this.$refs.errorModal.close();
        }
    }
}
</script>

<style lang="scss">
    @import '../scss/essentials/variables';

    .digital-voting .header {
        height: auto;
        min-height: 111px;

        .header-content {
            margin-top: 20px;
            display: flex;

            .icon-wrapper {
                min-width: 40px;
            }

            .content-header {
                word-wrap: break-word;
                overflow-wrap: break-word;
                hyphens: auto;
                min-width: 0;
            }
        }

        .icon-wrapper {
            max-height: 40px;
            max-width: 40px;
            display: flex;
            align-self: flex-start;
            border-radius: 20px;
            background-color: $lib_green;
            margin-right: 10px;
        }
    }

    .max-options-disclaimer {
        font-size: 0.9rem;
    }

    .options {
        margin: 20px 0;

        .option {
            margin-bottom: 10px;
        }
    }

    .centered {
        display: flex;
        justify-content: center;
    }

    .selected-options-count {
        margin-bottom: 20px;
        font-weight: bold;
        color: #aaa;

        &.valid {
            color: $lib_green;
        }

        &.invalid {
            color: $lib_red;
        }
    }

    .submit-vote {
        &[disabled] {
            cursor: default;
        }
    }

    .modal .modal_footer {
        display: flex;
        justify-content: center;
    }
</style>
