import apiCall from '@/utils/api';

export default {
    namespaced: true,
    state: {
        votings: [],
        votingsLoaded: false
    },
    getters: {
        votingIsHappening(state)
        {
            return !!(state.votings.length);
        },
        votingsLoaded(state)
        {
            return state.votingsLoaded;
        },
        votings(state)
        {
            return state.votings;
        }
    },
    actions: {
        async getVotings({ commit, state, getters, dispatch, rootGetters })
        {
            if (!getters.votingsLoaded) {
                dispatch('loader/show', 'component', { root: true });
            }

            let votingsRequest = await apiCall('/user/getAssemblyVotings').catch(error => null);
            if (votingsRequest && (votingsRequest.hasOwnProperty('votings'))) {
                commit('SET_VOTINGS', votingsRequest.votings);
            }

            commit('SET_VOTINGS_LOADED', true);
            if (rootGetters['loader/isLoading']) {
                dispatch('loader/hide', 'component', { root: true });
            }
        }
    },
    mutations: {
        SET_VOTINGS_LOADED(state, data)
        {
            state.votingsLoaded = data;
        },
        SET_VOTINGS(state, data)
        {
            state.votings = data;
        }
    }

}
