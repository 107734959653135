<template>
    <transition name="modal">
        <div class="modal-holder" v-show="open">
                <div class="create_modal">
                    <div class="modal_header">
                        <slot name="header"></slot>
                        <div class="closer">
                            <div v-on:click="close()"><i class="mdi mdi-close"></i></div>
                        </div>
                    </div>
                    <div class="modal_body">
                        <div class="modal_content">
                            <slot name="body"></slot>
                        </div>
                    </div>
                    <div class="modal_footer">
                        <slot name="footer"></slot>
                    </div>
                </div>
                <!--<div class="modal-overlay" id="modal-overlay"></div>-->
        </div>
    </transition>
</template>

<script>

    export default {
        name: "Modal",

        props: {
            title: {
                type: String,
                default: ""
            },
        },

        data: function() {
            return {
                open: false
            }
        },

        methods: {
            toggleModal() {
                this.open = !this.open;
            },
            showModal() {
                this.open = true;
                this.$emit('opened');
            },
            close() {
                this.open = false;
                this.$emit('closed');
            }
        }
    }
</script>
