<template>
    <div id="moreModal">
        <Modal ref="more_modal" :className="'more-modal'" :showIndicator="showIndicator">
            <template slot="header">
                <div class="header">
                    Mer
                </div>
            </template>
            <template slot="body">
                <div class="toggle more-menu" id="moreMenu">
                    <router-link class="nav-item" to="/mypage" @click.native="$refs.more_modal.close()">
                        <div class="nav-profile">
                            <img :src=" baseUrl + 'avatar/id/' + user_id " />
                        </div>

                        <div class="nav-text">
                            Min sida
                        </div>
                    </router-link>

                    <router-link class="nav-item" to="/specialevents" @click.native="$refs.more_modal.close()" v-if="eventIsHappening">
                        <div class="nav-icon">
                            <i class="mdi mdi-calendar-month"></i>
                        </div>
                        <div class="nav-text">
                            {{specialEventTitle}}
                        </div>
                    </router-link>

                    <router-link class="nav-item" to="/billing" @click.native="$refs.more_modal.close()">
                        <div class="nav-icon">
                            <i class="mdi mdi-file-table"></i>
                        </div>
                        <div class="nav-text">
                            Mina fakturor
                        </div>
                    </router-link>

                    <router-link v-if="isAdmin" class="nav-item" to="/associations" @click.native="$refs.more_modal.close()">
                        <div class="nav-icon">
                            <i class="mdi mdi-account-multiple"></i>
                        </div>
                        <div class="nav-text">
                            Förening
                        </div>
                    </router-link>

                    <router-link v-if="isAdmin" class="nav-item" to="/groups" @click.native="$refs.more_modal.close()">
                        <div class="nav-icon">
                            <i class="mdi mdi-message-text-outline"></i>
                        </div>
                        <div class="nav-text">
                            SMS till grupp
                        </div>
                    </router-link>

                    <router-link class="nav-item" to="/newsletter" @click.native="$refs.more_modal.close()">
                        <div class="nav-icon">
                            <i class="mdi mdi-email-outline"></i>
                        </div>
                        <div class="nav-text">
                            Prenumerera
                        </div>
                    </router-link>
                    <router-link class="nav-item" to="/communication" @click.native="$refs.more_modal.close()">
                        <div class="nav-icon">
                            <i class="mdi mdi-message-outline"></i>
                        </div>
                        <div class="nav-text">
                            Kommunikation
                        </div>
                    </router-link>
                    <router-link class="nav-item" to="/donation" @click.native="$refs.more_modal.close()">
                        <div class="nav-icon">
                            <i class="mdi mdi-gift-outline"></i>
                        </div>
                        <div class="nav-text">
                            Donera
                        </div>
                    </router-link>
                    <router-link class="nav-item" to="/links" @click.native="$refs.more_modal.close()">
                        <div class="nav-icon">
                            <i class="mdi mdi-link"></i>
                        </div>
                        <div class="nav-text">
                            Kontakt och länkar
                        </div>
                    </router-link>
                    <router-link class="nav-item" to="/help" @click.native="$refs.more_modal.close()">
                        <div class="nav-icon">
                            <i class="mdi mdi-help-circle-outline"></i>
                        </div>
                        <div class="nav-text">
                            Hjälp
                        </div>
                    </router-link>
                    <a href="#" class="nav-item" v-on:click="logout">
                        <div class="nav-icon">
                            <i class="mdi mdi-logout"></i>
                        </div>
                        <div class="nav-text">
                            Logga ut
                        </div>
                    </a>
                </div>
            </template>
        </Modal>
    </div>

</template>

<script>
    import Modal from "@/components/Modal";

    export default {
        name: "MoreModal",
        components: {
            Modal
        },
        data() {
            return {
                baseUrl: process.env.VUE_APP_API_DOMAIN+"backend/",
                showIndicator: true,
                bottom: false
            }
        },
        computed: {
            isAdmin(){
                return this.$store.getters["user/isAdmin"];
            },
            user_id() {
                return this.$store.getters["user/userData"].id;
            },
            specialEventTitle(){
                return this.$store.getters["specialEvents/title"];
            },
            eventIsHappening(){
                return this.$store.getters["specialEvents/eventIsHappening"];
            }
        },
        methods: {
            logout(){
                this.$emit('logout');
            },
            toggleModal(){
                this.$refs.more_modal.toggleModal();
            },
            bottomVisible() {
                const list = document.getElementById("modalContent");
                return list.scrollTop === (list.scrollHeight - list.offsetHeight);
            },
            handleScroll(){
                this.bottom = this.bottomVisible();
            },
        },
        mounted () {
            document.getElementById("modalContent").addEventListener('scroll', this.handleScroll)
        },
        destroyed () {
            document.getElementById("modalContent").removeEventListener('scroll', this.handleScroll);
        },
        watch: {
            bottom(bottom) {
                if (bottom) {
                    this.showIndicator = false;
                }
            }
        },
    }
</script>

<style scoped>

</style>
