<template>
    <div class="gutter communication">
        <Loader/>
        <div class="headline blue">Kommunikation</div>
        <p>Bestäm hur du vill ha din kommunikation från Liberalerna.</p>
        <div class="communication-header">
            <div class="communication-header-title">

            </div>
            <div class="communication-header-checkbox-title">
                Mejl
            </div>
            <div class="communication-header-checkbox-title">
                Push
            </div>
        </div>
        <template v-for="(item, index) in user_data.areas">
            <Area v-if="typeof settings[item.id] !== 'undefined'" :key="`area${index}`" :item="item" :setting="settings[item.id]" />
        </template>
        <Area :item="comment" v-if="typeof settings.comments !== 'undefined'" :setting="settings.comments" />
        <template v-if="groups.length > 0">
            <div class="communication-header group">
                <div class="communication-header-title">
                    <div class="content-header blue">Grupper</div>
                </div>
                <div class="communication-header-checkbox-title">
                    Mejl
                </div>
                <div class="communication-header-checkbox-title">
                    Push
                </div>
            </div>
            <template v-for="(group, index) in groups">
                <Area :item="group" :setting="groupSetting(group.id)" :key="`group${index}`" v-if="typeof groupSetting(group.id) !== 'undefined'" />
            </template>
        </template>
        <template v-if="eventIsHappening && loaded">
            <div class="communication-header">
                <div class="communication-header-title event">
                    <div class="content-header blue">Landsmöten</div>
                </div>
                <div class="communication-header-checkbox-title">
                    Push
                </div>

            </div>
            <div class="communication-item">
                <div class="communication-title event">
                    {{ title }}
                </div>
                <div :class="borderClass"></div>
                <div class="communication-checkbox" :class="checkedClass" >
                    <input type="checkbox" id="push" v-model="eventSettings.push" v-on:change="saveNotificationSettings" />
                    <label for="push"></label>
                </div>
            </div>
        </template>

    </div>
</template>

<script>
    import apiCall from "@/utils/api";
    import { mapActions, mapState, mapGetters } from "vuex";
    import Loader from "@/components/Loader";
    import Area from "../components/Area";

    export default {
        name: "Communication",
        data() {
            return {
                settings: {},
                eventSettings: {
                    email: false,
                    push: false,
                    area: ""
                },
                comment: {
                    id: "comments",
                    name: "Kommentarer",
                    level: 0
                },
                loaded: false
            }
        },
        components: {
            Loader,
            Area
        },
        computed: {
            ...mapState({
                user_data: state => state.user.data
            }),
            ...mapGetters({
                groups: 'user/groups',
                eventIsHappening: 'specialEvents/eventIsHappening',
                event: 'specialEvents/specialEvent',
                title: 'specialEvents/title'
            }),
            groupSetting(groupID){
                return groupID => {
                    let group = `groups_${groupID}`;
                    if(this.settings[group]){
                        return this.settings[group];
                    } else {
                        return this.settings.groups
                    }
                }
            },
            borderClass(){
                if(this.eventSettings.push === true){
                    return 'border blue';
                }
                return 'border';
            },
            checkedClass(){
                if(this.eventSettings.push === true){
                    return 'checked';
                }
                return '';

            },
        },

        methods: {
            ...mapActions({
                showLoading: 'loader/show',
                hideLoading: 'loader/hide',
            }),
            saveNotificationSettings() {
                let id = `sevent_${this.event.id}`;

                let data = {
                    area_id: id,
                    email: 0,
                    push: this.eventSettings.push
                };

                apiCall("/user/saveNotificationSettings", { data });
            }
        },
        created() {
            this.showLoading("component");
            apiCall("/user/getNotificationSettings").then(res => {
                this.hideLoading("component");
                this.settings = res;
                this.loaded = true;
                let event = `sevent_${this.event.id}`;
                console.log(res[event]);
                console.log({... res[event]});
                this.eventSettings = {... res[event] }

            });
        }
    }

</script>
