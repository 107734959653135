export const DetectIosApp = {
    methods: {
        detectIosApp() {
            let standalone = window.navigator.standalone,
                userAgent = window.navigator.userAgent.toLowerCase(),
                safari = /safari/.test( userAgent ),
                ios = /iphone|ipod|ipad/.test( userAgent );

            if( ios ) {
                if ( !standalone && safari ) {
                    //browser
                } else if ( standalone && !safari ) {
                    //standalone
                } else if ( !standalone && !safari ) {
                    //uiwebview
                    return true;
                }
            } else {
                //not iOS
            }
            return false;
        },
    }
}
