import apiCall from '@/utils/api';
import router from '@/router';
import store from '@/store/';
import persistentStorage from '../../utils/persistentStorage';
import pushToken from '../../utils/pushToken';

function initialState(){
    return {
        logged_in: false,
        to: '',
        data: {
            areas: [],
            groups: [],
            acl : {
                posts: {
                    write: false
                },
                events: {
                    write: false
                },
                files: {
                    write: false
                },
                areas: {

                }
            }
        },
        available_areas: [],
        current_group: {},
        notifications: [],
        notification_date: "",
        isConfirmingGdpr: false
        // unseenNotifications: 0
    }
}

export default {
    namespaced: true,
    state: {
        initialState: initialState,
        ...initialState(),
    },
    getters: {
        unseenNotifications(state){
            if(state.notifications){
                return state.notifications.filter(n => parseInt(n.seen) === 0);
            } return [];
        },
        isAdminAuthenticated(state) {
            return state.data.admin_authenticated;
        },
        isLoggedIn(state){
          return state.logged_in;
        },
        notifications(state, getters, rootState, rootGetters){
            if(state.notifications){
                return state.notifications.filter(n => {
                    if(rootGetters['specialEvents/eventIsHappening'] === false ) {
                        return n.type.substr(0,7) !== 'sevent_';
                    }
                    return true;
                });
            }
        },
        // unseenNotifications(state){
        //   return state.unseenNotifications;
        // },
        notificationsDate(state) {
            if(state.notification_date){
                return state.notification_date;
            }
            return "";
        },
        hasAccessToType(state){
          return type => {
              /**
               * Only check that the user has permission to the type, not area
               */
              switch(type) {
                  case "post":
                      return state.data.acl.posts.write;
                  case "event":
                      return state.data.acl.events.write;
                  case "file":
                      return state.data.acl.files.write;
                  case "folder":
                      return state.data.acl.files.write;
              }
              return false;
          }
        },
        hasAccess(state){
            return (type, chosenArea) => {

                /**
                 * Determine if user has permission to edit, delete item etc by type AND area
                 */
                const area = state.available_areas.filter(area => area.area === chosenArea && area.type === type);
                return area.length > 0;
            }
        },
        isGroupOwner(state){
            return (groupID) => {
                const group = state.data.groups.find(group => parseInt(group.id) === parseInt(groupID));
                let isOwner = false;
                if(group && group.subscription_owners){
                    isOwner = group.subscription_owners.filter(userID => parseInt(userID) === parseInt(state.data.id)).length > 0;
                }

                return isOwner;
            }
        },
        isAdmin(state){
          let areas =  state.data.areas.filter(area => {
              if(area.role && area.role.rules && area.role.rules.members){
                  const members = area.role.rules.members;
                  return Object.keys(members).every(function(key){ return members[key] === true });
              }
          })
           return areas.length > 0;
        },
        availableAreas(state) {
            return type => {
                return state.available_areas
                    .filter(area => area.type === type && area.permission === true);
            }
        },
        allAreas(state) {
            return state.data.areas;
        },
        currentGroup(state) {
            return state.current_group;
        },
        currentGroupId(state) {
            return (state.current_group.id > 0) ? state.current_group.id : null;
        },
        myUserToPublishAs(state) {
            if(state.data !== null) {
                if (typeof state.data.publish_as !== 'undefined' && state.data.publish_as.length > 0) {
                    return state.data.publish_as;
                }
            }
            return [];
        },
        mySubscriptionGroups(state) {
            if(state.data !== null) {
                if (typeof state.data.subscriptions !== 'undefined') {
                    let my_subscription_groups = [];
                    for(var i in state.data.subscriptions) {
                        if(state.data.subscriptions[i].owner === state.data.id) {
                            my_subscription_groups.push(state.data.subscriptions[i]);
                        }
                    }
                    return my_subscription_groups;
                }
            }
            return [];
        },
        groups(state) {
                if(typeof state.data.groups !== 'undefined') {
                    return state.data.groups.filter(group => group.subscription_group !== "1");
                }
                return [];
        },
        subscriptionGroups(state){
                if(typeof state.data.groups !== 'undefined') {
                    return state.data.groups.filter(group => group.subscription_group === "1");
                }
                return [];
        },
        userData(state) {
            return state.data;
        },
        restricted(state){
            return parseInt(state.data.restricted) === 1;
        }
    },
    actions: {
        async loginUser({ commit, dispatch, getters }, payload) {
            let loginRequest;
            try {
                loginRequest = await apiCall('/user/loginUser', {
                    data: {
                        username: payload.username,
                        password: payload.password,
                        device: await persistentStorage.getItem('device_id')
                    }
                });
            } catch (err) {
                console.log("API ERROR!");
                await persistentStorage.removeItem('persu');
                await persistentStorage.removeItem('uuid');
                await persistentStorage.removeItem('session_id');
            }


            if (loginRequest.logged_in) {
                /**
                 * Load events to notify unanswered events
                 */
                dispatch('items/loadItems', "event", {root: true});
                dispatch('specialEvents/getEvent', "", {root: true});

                commit('USER_LOGGED_IN', true);
                commit('SET_DATA', await loginRequest.User);
                dispatch('setAvailableAreas');

                await persistentStorage.setItem('persu', loginRequest.persu);
                await persistentStorage.setItem('uuid', loginRequest.User.uuid);
                await persistentStorage.setItem('session_id', loginRequest.session_id);

                pushToken.store(loginRequest.User.uuid);

//                VueCookies.set("persu", loginRequest.persu, "30D", "/", window.location.hostname, true);
//                VueCookies.set("uuid", loginRequest.User.uuid, "30D", "/", window.location.hostname, true);
//                VueCookies.set("session_id", loginRequest.session_id, "30D", "/", window.location.hostname, true);

                let hasConfirmedGdpr = false;

                if(getters["isAdmin"] === true){
//                    VueCookies.set("isAdmin", true, "30D", "/", window.location.hostname, true);
                    await apiCall("/gdprconfirm/hasConfirmed").then(res => {
                        if(res === true){
                            hasConfirmedGdpr = true;
                        }
                    });
                }

                if(getters["isAdmin"] === false || hasConfirmedGdpr){
                    let after_login = "/";
                    if(loginRequest.User.logins <= 1) {
                        after_login = '/help/newbie';
                    }
                    if(payload.ios === true) {
                        document.location = after_login;
                    } else {
                        router.push(after_login);
                    }
                } else if (getters["isAdmin"] === true && !hasConfirmedGdpr){
                    let after_login = "/confirmgdpr";
                    commit('SET_CONFIRMING_GDPR', true);
                    // if(loginRequest.User.logins <= 1) {
                    //     after_login = '/help/newbie';
                    // }
                    if(payload.ios === true) {
                        document.location = after_login;
                    } else {
                        router.push(after_login);
                    }
                }

            } else if(loginRequest.una === true) {
                this._vm.$notify({
                    type: 'information',
                    group: 'general',
                    title: 'Fel',
                    text: 'Det kan vara så att ditt användarkonto inte är aktiverat. Prova att aktivera dig nedan. Finns din mejladress registrerad i vårt system kommer vi att skicka ett aktiveringsmejl till dig.',
                    duration: 10000,
                    speed: 1000
                });
            } else if(loginRequest.lockout === true) {
                commit('USER_LOGGED_IN', false);
                commit('SET_DATA', null);
                this._vm.$notify({
                    type: 'error',
                    group: 'general',
                    title: 'Du är utelåst',
                    text: 'Du har försökt logga in för många gånger med felaktiga uppgifter, du är nu avstängd under en tid. Återkom gärna och prova igen vid ett senare tillfälle.',
                    duration: 10000,
                    speed: 1000
                });
            } else if(loginRequest.berror === true) {
                commit('USER_LOGGED_IN', false);
                commit('SET_DATA', null);
                this._vm.$notify({
                    type: 'error',
                    group: 'general',
                    title: 'Fel',
                    text: 'Systemfel, kontakta support',
                    duration: 10000,
                    speed: 1000
                });
            } else {
                commit('USER_LOGGED_IN', false);
                commit('SET_DATA', null);
                this._vm.$notify({
                    type: 'error',
                    group: 'general',
                    title: 'Fel',
                    text: 'Fel användarnamn eller lösenord',
                    duration: 10000,
                    speed: 1000
                });
            }
        },
        async keyLogin({ commit, dispatch }, loginRequest) {
            commit('USER_LOGGED_IN', true);
            commit('SET_DATA', await loginRequest.User);
            await dispatch('setAvailableAreas');

            await persistentStorage.setItem('persu', loginRequest.persu);
            await persistentStorage.setItem('uuid', loginRequest.User.uuid);
            await persistentStorage.setItem('session_id', loginRequest.session_id);

            pushToken.store(loginRequest.User.uuid);

//            VueCookies.set("persu", loginRequest.persu, "30D", "/", window.location.hostname, true);
//            VueCookies.set("uuid", loginRequest.User.uuid, "30D", "/", window.location.hostname, true);
//            VueCookies.set("session_id", loginRequest.session_id, "30D", "/", window.location.hostname, true);

        },
        async logoutUser({ commit, dispatch }) {
            // console.log("** LOGOUT USER **");

//            let location = window.location.hostname;
//            if(location !== "localhost"){
//                location = "." + location;
//            }
//            document.cookie =  "persu=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=" + location + ";";
//            document.cookie =  "uuid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=" + location + ";"

            await persistentStorage.removeItem('persu');
            await persistentStorage.removeItem('uuid');
            await persistentStorage.removeItem('session_id');

//            VueCookies.remove('persu', {domain: window.location.hostname});
//            VueCookies.remove('uuid', {domain: window.location.hostname});
            dispatch("items/resetItems", {}, {root:true});
            dispatch("setNotificationDate", {});
            commit("RESET_STATE");
            try {
                await apiCall('/user/logoutUser', {
                    data: {
                        device: await persistentStorage.getItem('device_id')
                    }
                });
                router.replace('login/logout');
            } catch (err) {
                console.log("API ERROR!");
            }
            commit('USER_LOGGED_IN', false);
        },
        async sendMessage({ commit, dispatch, getters }, data) {

            dispatch('loader/show', "component", {root: true});

            let sendRequest;
            try {
                sendRequest = await apiCall("/user/sendMessage", { data });
            } catch (err) {
                console.log("API ERROR!");
            }

            if (sendRequest.error){
                this._vm.$notify({
                    type: 'error',
                    group: 'general',
                    title: 'Kunde inte skicka meddelandet',
                    text: 'Ett fel uppstod - '+sendRequest.error,
                    duration: 10000,
                    speed: 1000
                });
            } else if (sendRequest.success === true) {
                this.subject = "";
                this.message = "";

                this._vm.$notify({
                    type: 'success',
                    group: 'general',
                    title: 'Meddelande skickat',
                    text: 'Ditt meddelande är nu skickat',
                    duration: 10000,
                    speed: 1000
                });
            } else {
                this._vm.$notify({
                    type: 'error',
                    group: 'general',
                    title: 'Kunde inte skicka meddelandet',
                    text: 'Ett okänt fel uppstod',
                    duration: 10000,
                    speed: 1000
                });
            }

            dispatch('loader/hide', "component", {root: true});

        },
        setCurrentGroup({ commit }, group) {
            commit('CURRENT_GROUP', group);
        },
        userLoggedIn({ commit }, payload) {
            commit('USER_LOGGED_IN', payload);
        },
        setUserData({commit, dispatch}, data) {
            commit('SET_DATA', data);
            dispatch('setAvailableAreas');
        },
        setGroups({commit}, groups){
            commit("UPDATE_SUBSCRIPTION_GROUPS", [groups]);
        },
        setNotifications({commit}, data){
            commit("SET_NOTIFICATIONS", data);
        },
        setNotificationDate({commit}, data){
            commit("SET_NOTIFICATION_DATE", data);
        },
        // setUnseenNotifications({commit}, data){
        //     commit("SET_UNSEEN_NOTIFICATIONS", data);
        // },
        clearUnseenNotifications({commit}){
          commit("CLEAR_UNSEEN_NOTIFICATIONS");
        },
        clearGroupNotifications({commit}, groupID){
            let data = {
                group_id: groupID
            };

            apiCall('user/clearGroupNotifications', { data }).then(res => {
                if(res){
                    //Set unseen to 1 on the group notifications, and minus the amount of unseen notifications
                    commit("CLEAR_GROUP_NOTIFICATIONS", groupID);
                }
            });
        },
        setAdminAuthenticated({ commit }, data) {
            commit("SET_ADMIN_AUTHENTICATED", data);
        },
        setConfirmingGdpr({commit}, data){
          commit("SET_CONFIRMING_GDPR", data);
        },
        setAvailableAreas({ commit, dispatch, state}){
            let available_areas = [];

            for(var i in state.data.areas) {
                let area = state.data.areas[i];
                if(area.role !== false && area.role.rules !== false) {
                    if ((Object.entries(area.role.rules).length > 0 && area.role.rules.constructor === Object) || area.role.rules.length > 0) {
                        available_areas.push(
                            {
                                type: "post",
                                area: area.id,
                                permission: area.role.rules.publishing.post.write,
                                name: area.name
                            },
                            {
                                type: "event",
                                area: area.id,
                                permission: area.role.rules.publishing.event.write,
                                name: area.name
                            },
                            {
                                type: "file",
                                area: area.id,
                                permission: area.role.rules.publishing.file.write,
                                name: area.name
                            },
                            {
                                type: "admin",
                                area: area.id,
                                permission: area.role.rules.members.manage,
                                name: area.name
                            }
                        )
                    }
                }
            }
                commit('SET_AVAILABLE_AREAS', available_areas);
            },
            replaceRoute() {
                if (
                    store.state.user.to &&
                    router.currentRoute.path !== store.state.user.to &&
                   !(router.currentRoute.path === '/' && store.state.user.to === '')
                ) {
                    router.replace(store.state.user.to);
                    store.state.user.to = "";
                }
            },
            addNotification({getters, commit}, item){

                let nameOfAction = "";
                let viewUrl = "";
                if(item.type === "post"){
                    nameOfAction = "skrev";
                    viewUrl = "/post/" + item.slug;
                } else if(item.type === "event"){
                    nameOfAction = "skapade ett evenemang";
                    viewUrl = "/calendar/" + item.slug;
                } else if(item.type === "file"){
                    nameOfAction = "laddade upp en fil";
                    viewUrl = "/archive/" + item.slug;
                } else if(item.type === "folder"){
                    nameOfAction = "skapade mappen";
                    viewUrl = "archive/" + item.slug;
                }

                const notification = {
                    area: item.area,
                    area_name: item.area_name,
                    creation_date: item.creation_date,
                    creator: getters.userData,
                    seen: 0,
                    id: item.id,
                    name_of_action: nameOfAction,
                    name_of_subject: item.title,
                    view_url: viewUrl,
                    text: item.text,
                    name_of_person: getters.userData.full_name,
                    group: item.group
                };
                commit('ADD_NOTIFICATION', notification);
            },
    },

    mutations: {
        SET_CONFIRMING_GDPR(state, payload){
          state.isConfirmingGdpr = payload;
        },
        ADD_NOTIFICATION(state, item){
            state.notifications.unshift(item)
        },
        CLEAR_GROUP_NOTIFICATIONS(state, groupID) {

            const nonSubscriptionGroups = state.data.groups.map(g => {
                if (g.subscription_group === "0") {
                    return g.id;
                }
            });
          const groupNotifications = state.notifications.filter(n => n.group === groupID && parseInt(n.seen) === 0);

            if(state.unseenNotifications > 0 && groupNotifications){
                state.unseenNotifications = state.unseenNotifications - groupNotifications.length;
            }

          state.notifications = state.notifications.map(n => {
              if(n.group === groupID && parseInt(n.seen) === 0){
                  n.seen = 1;
              } return n;
          });
        },
        // SET_UNSEEN_NOTIFICATIONS(state, value){
        //     state.unseenNotifications = value;
        // },
        CLEAR_UNSEEN_NOTIFICATIONS(state){
            state.notifications = state.notifications.map(n => {
                if(parseInt(n.seen) === 0){
                    n.seen = 1;
                } return n;
            });
        },
        SET_ADMIN_AUTHENTICATED(state, value) {
            state.data.admin_authenticated = value;
        },
        SET_NOTIFICATIONS(state, value) {
            state.notifications = value;
        },
        SET_NOTIFICATION_DATE(state, value) {
            state.notification_date = value;
        },
        CURRENT_GROUP(state, value) {
            state.current_group = value;
        },
        USER_LOGGED_IN(state, value){
            state.logged_in = value;
            pushToken._loggedIn = value;
        },
        SET_DATA(state, value) {
            let acl = {
                posts: {
                  write: false
                },
                events: {
                  write: false
                },
                files: {
                  write: false
                },
                areas: {

                }
            };

            // Set ACL per type
            if(value !== null && value !== undefined) {
                for (var i in value.areas) {
                    let area = value.areas[i];
                    if (area.role !== false) {
                        if(typeof area.role.rules !== "undefined" && typeof area.role.rules.publishing !== "undefined") {
                            if (typeof area.role.rules.publishing.event.write !== "undefined" && area.role.rules.publishing.event.write === true) {
                                acl.events.write = true;
                            }
                            if (typeof area.role.rules.publishing.file.write !== "undefined" && area.role.rules.publishing.file.write === true) {
                                acl.files.write = true;
                            }
                            if (typeof area.role.rules.publishing.post.write !== "undefined" && area.role.rules.publishing.post.write === true) {
                                acl.posts.write = true;
                            }
                        }
                    }
                }
                value.acl = acl;
                state.data = value;
            }
        },
        SET_AVAILABLE_AREAS(state, value){
            state.available_areas = value;
        },
        RESET_STATE(state){
            // acquire initial state
            const s = initialState();
            Object.keys(s).forEach(key => {
                state[key] = s[key]
            })
        },
        UPDATE_SUBSCRIPTION_GROUPS(state, groups){
            state.data.groups = state.data.groups.filter(group => group.subscription_group === "1")
            state.data.groups.concat(groups);
        }
    }
}
