<template>
    <div id="app" :class="[`page-${$router.currentRoute.name}`]">
        <notifications group="general" position="top right" width="100%" classes="lib-notices">
            <template slot="body" slot-scope="props">
                <div :class="props.item.type+' lib-notices'">
                    <div class="notice-body">
                        <div class="notice-icon">
                            <i v-if="props.item.type === 'error'" class="mdi mdi-alert-circle-outline"></i>
                            <i v-if="props.item.type === 'info'" class="mdi mdi-information-outline"></i>
                            <i v-if="props.item.type === 'success'" class="mdi mdi-check"></i>
                            <i v-if="props.item.type === 'warn'" class="mdi mdi-alert-outline"></i>
                        </div>
                        <div>
                            <a class="title">
                                {{props.item.title}}
                            </a>
                            <div v-html="props.item.text"></div>
                        </div>
                    </div>
                    <div>
                        <a class="close" @click="props.close">
                            <i class="mdi mdi-close"></i>
                        </a>
                    </div>

                </div>
            </template>
        </notifications>
        <div id="nav" v-show="user_logged_in && !isConfirmingGdpr">
            <div class="gradient"></div>
            <div class="menu">

                <router-link to="/" exact @click.native="scrollToTop">
                    <div class="menu_item">
                        <svg viewBox="0 0 19 22">
                            <path d="M6.66048 22V3.33024C6.66048 1.49121 5.1697 0 3.33024 0C1.49078 0 0 1.49121 0 3.33024V18.6693C0 20.5084 1.49121 21.9996 3.33024 21.9996H6.66048V22Z" />
                            <path d="M7.36816 22H14.8532C16.6927 22 18.1834 20.5087 18.1834 18.6697C18.1834 16.8303 16.6922 15.3395 14.8532 15.3395H10.6984C8.85894 15.3395 7.36816 16.8307 7.36816 18.6697V22Z" />
                        </svg>
                        <div>Hem</div>
                    </div>
                </router-link>

                <router-link v-if="false" to="/archive">
                    <div class="menu_item">
                        <svg viewBox="0 0 22 22">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 2C0 0.895431 0.895431 0 2 0H9L12 3H20C21.1046 3 22 3.89543 22 5V20C22 21.1046 21.1046 22 20 22H2C0.89543 22 0 21.1046 0 20V3V2ZM20 5H2V20H20V5Z" />
                        </svg>
                        <div>Filer</div>
                    </div>
                </router-link>

                <router-link v-if="false" to="/calendar">
                    <div class="menu_item">
                        <svg viewBox="0 0 22 22">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0H4V2H2C0.895431 2 0 2.89543 0 4V20C0 21.1046 0.89543 22 2 22H20C21.1046 22 22 21.1046 22 20V4C22 2.89543 21.1046 2 20 2H19V0H17V2H6V0ZM2 7H20V20H2V7ZM9 9H4V14H9V9Z" />
                        </svg>
                        <div>Kalender</div>
                        <transition name="fade">
                            <span v-if="unAnsweredEvents > 0" class="badge">
                                {{ unAnsweredEvents }}
                            </span>
                        </transition>
                    </div>
                </router-link>

                <a href="#" @click.prevent="loadUniversumCampaignUrl()">
                    <div class="menu_item">
                        <AnnounceIcon/>
                        <div>Kampanj</div>
                    </div>
                </a>

                <router-link to="/notices">
                    <div :class="(shake_notification_bell) ? 'apply-shake menu_item' : 'menu_item'">
                        <svg viewBox="0 0 22 24">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.78652 22.394C8.19947 21.8069 7.86967 21.0107 7.86967 20.1805H9.86774C9.86774 20.4808 9.98703 20.7688 10.1994 20.9811C10.4117 21.1935 10.6997 21.3128 11 21.3128C11.3003 21.3128 11.5883 21.1935 11.8006 20.9811C12.0129 20.7688 12.1322 20.4808 12.1322 20.1805H14.1303C14.1303 21.0107 13.8005 21.8069 13.2134 22.394C12.6264 22.981 11.8302 23.3108 11 23.3108C10.1698 23.3108 9.37356 22.981 8.78652 22.394Z" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.27012 2.37339C6.78978 0.853736 8.85088 0 11 0C13.1491 0 15.2102 0.853736 16.7299 2.37339C18.2495 3.89305 19.1033 5.95415 19.1033 8.10328V11.6554C19.1033 12.8677 19.5691 14.3134 20.0881 15.5244C20.3414 16.1154 20.5953 16.6231 20.7854 16.9824C20.8803 17.1616 20.9588 17.3028 21.0128 17.3979C21.0397 17.4454 21.0605 17.4813 21.0741 17.5045L21.089 17.5298L21.0921 17.535L22 19.0483H0L0.907496 17.5358L0.911049 17.5298L0.925882 17.5045C0.939467 17.4813 0.960254 17.4454 0.987217 17.3979C1.04117 17.3028 1.11968 17.1616 1.21456 16.9824C1.40473 16.6231 1.65857 16.1154 1.91186 15.5244C2.43085 14.3134 2.89672 12.8677 2.89672 11.6554V8.10328C2.89672 5.95415 3.75046 3.89305 5.27012 2.37339ZM3.41495 17.0502H18.5851C18.477 16.8223 18.3644 16.5745 18.2516 16.3115C17.705 15.036 17.1052 13.2848 17.1052 11.6554V8.10328C17.1052 6.48407 16.462 4.93119 15.317 3.78624C14.1721 2.64129 12.6192 1.99807 11 1.99807C9.3808 1.99807 7.82791 2.64129 6.68297 3.78624C5.53802 4.93119 4.89479 6.48407 4.89479 8.10328V11.6554C4.89479 13.2848 4.29503 15.036 3.74838 16.3115C3.63564 16.5745 3.52298 16.8223 3.41495 17.0502Z" />
                        </svg>
                        <div>Aviseringar</div>
                        <transition name="fade">
                            <span v-if="unseenNotifications.length > 0" class="badge">
                                {{ unseenNotifications.length }}
                            </span>
                        </transition>
                    </div>
                </router-link>

                <span @click="toggleMoreMenu">
                    <div class="menu_item" :class="{'active': moreActive }">
                        <svg viewBox="0 0 22 6">
                            <path d="M11 5.75C12.5188 5.75 13.75 4.51878 13.75 3C13.75 1.48122 12.5188 0.25 11 0.25C9.48122 0.25 8.25 1.48122 8.25 3C8.25 4.51878 9.48122 5.75 11 5.75Z" />
                            <path d="M19.25 5.75C20.7688 5.75 22 4.51878 22 3C22 1.48122 20.7688 0.25 19.25 0.25C17.7312 0.25 16.5 1.48122 16.5 3C16.5 4.51878 17.7312 5.75 19.25 5.75Z" />
                            <path d="M2.75 5.75C4.26878 5.75 5.5 4.51878 5.5 3C5.5 1.48122 4.26878 0.25 2.75 0.25C1.23122 0.25 0 1.48122 0 3C0 4.51878 1.23122 5.75 2.75 5.75Z" />
                        </svg>
                        <div>Mer</div>
                    </div>
                </span>

            </div>
        </div>

        <MoreModal ref="more_modal" @logout="doLogout" />

        <Modal class="universumModal" ref="universumModal">
            <template v-slot:header>
                <div class="header">
                    Du lämnar appen
                </div>
            </template>
            <template v-slot:body>
                <p class="universumModal-description">
                    Kampanjappen kommer att öppnas i en extern webbläsare
                </p>
                <div class="universumModal-buttons">
                    <a
                        class="universumModal-link"
                        href="#"
                        @click.prevent="$refs.universumModal.close()"
                    >Avbryt</a>
                    <a
                        class="universumModal-link is--primary"
                        :href="universumCampaignsUrl"
                        target="_blank"
                        @click="$refs.universumModal.close()"
                    >
                        <span>Gå vidare</span>
                        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.99558 4.9957C5.65977 4.33151 6.56061 3.95837 7.49992 3.95837H9.16658C9.51176 3.95837 9.79158 3.67855 9.79158 3.33337C9.79158 2.9882 9.51176 2.70837 9.16658 2.70837H7.49992C6.22909 2.70837 5.01031 3.21321 4.1117 4.11182C3.21309 5.01043 2.70825 6.22921 2.70825 7.50004V12.5C2.70825 13.7709 3.21309 14.9896 4.1117 15.8883C5.01031 16.7869 6.22909 17.2917 7.49992 17.2917H12.4999C13.7707 17.2917 14.9895 16.7869 15.8881 15.8883C16.7867 14.9896 17.2916 13.7709 17.2916 12.5V10.8334C17.2916 10.4882 17.0118 10.2084 16.6666 10.2084C16.3214 10.2084 16.0416 10.4882 16.0416 10.8334V12.5C16.0416 13.4393 15.6684 14.3402 15.0043 15.0044C14.3401 15.6686 13.4392 16.0417 12.4999 16.0417H7.49992C6.56061 16.0417 5.65977 15.6686 4.99558 15.0044C4.33139 14.3402 3.95825 13.4393 3.95825 12.5V7.50004C3.95825 6.56073 4.33139 5.6599 4.99558 4.9957Z"/>
                            <path d="M16.2752 3.72477C16.3351 3.78469 16.3803 3.85375 16.4108 3.92747C16.4414 4.00116 16.4583 4.08196 16.4583 4.16671V7.50004C16.4583 7.84522 16.1784 8.12504 15.8333 8.12504C15.4881 8.12504 15.2083 7.84522 15.2083 7.50004V5.67559L11.2752 9.60865C11.0311 9.85273 10.6354 9.85273 10.3913 9.60865C10.1472 9.36457 10.1472 8.96884 10.3913 8.72477L14.3244 4.79171H12.4999C12.1547 4.79171 11.8749 4.51189 11.8749 4.16671C11.8749 3.82153 12.1547 3.54171 12.4999 3.54171H15.8333C15.918 3.54171 15.9988 3.55857 16.0725 3.58913C16.1462 3.61963 16.2153 3.66484 16.2752 3.72477Z"/>
                        </svg>
                    </a>
                </div>
            </template>
        </Modal>

        <div id="app_content">
            <router-view v-if="loaded"/>
        </div>
        <Loader type="global" />
    </div>
</template>

<script>
    import {mapActions, mapState} from "vuex";
    import Loader from "@/components/Loader"
    import apiCall from "@/utils/api"
    import { Settings } from 'luxon'
    import {DetectIosApp} from "./mixins/DetectIosApp";
    import MoreModal from "@/views/MoreModal";
    import AnnounceIcon from '@/components/icons/AnnounceIcon.vue';
    import Modal from '@/components/Modal.vue';

    Settings.defaultLocale = 'sv';

    export default {
        name: "App",
        components: {
            Modal,
            Loader,
            MoreModal,
            AnnounceIcon,
        },
        mixins: [
            DetectIosApp
        ],
        data: function() {
            return {
                baseUrl: process.env.VUE_APP_API_DOMAIN+"backend/",
                heartbeat_timout: 15000,
                heartbeat_interval: null,
                full_beat: false,
                user_data: {},
                shake_notification_bell: false,
                loaded: false,
                moreActive: false,
                moreViews: ["specialevents", "groups", "mypage", "newsletter", "communication", "donation", "links", "help", "specialeventspage", "happening"],
                universumCampaignsUrl: '',
            }
        },
        computed: {
            unseenNotifications(){
              return this.$store.getters["user/unseenNotifications"];
            },
            ...mapState({
                user_logged_in: state => state.user.logged_in,
                isConfirmingGdpr: state => state.user.isConfirmingGdpr,
                eventIsHappening: state => state.specialEvents.eventHappening
            }),
            isAdmin(){
                return this.$store.getters["user/isAdmin"];
            },
            user_id() {
                return this.$store.getters["user/userData"].id;
            },
            unAnsweredEvents(){
                let today = new Date();
                let events =  this.$store.getters["items/events"].filter(event => {
                    let eventEnd = new Date(event.end);
                    return today < eventEnd && event.attend === 0 && parseInt(event.allow_attend) !== 0 && event.status === 'published';
                });
                /**
                 * Remove duplicates if event spans multiple days
                 */
                return events.filter((event, index, self) =>
                    index === self.findIndex((t) => (
                        t.place === event.place && t.slug === event.slug
                    ))).length;
            }
        },

        async created() {
            this.showLoading("global");

            let deviceId = await this.$persistentStorage.getItem('device_id').catch(() => null);

            if(!deviceId){
                let deviceId = this.guid();
                await this.$persistentStorage.setItem('device_id', deviceId);
//                VueCookies.set("device_id", this.guid(), "30D", "/", window.location.hostname, true);
            }

            apiCall("/default/init", {
                data: {
                    param1: false,
                    param2: false,
                    param3: deviceId
                }
            }).then(async res => {
                if (res.version) {
                    let local_version = localStorage.getItem('version');
                    let remote_version = res.version;

                    if (remote_version !== local_version) {
                        localStorage.setItem('version', remote_version);
                        window.location.reload(true);
                    }
                }

                if (res.user) {

                    this.$store.dispatch("specialEvents/getEvent");

                    // User was already logged in
                    this.loaded = true;
                    this.hideLoading("global");
                    this.setUserData(res.user);
                    /**
                     * Load events to notify unanswered events
                     */
                    this.$store.dispatch("items/loadItems", "event");

                    this.$store.dispatch("user/setNotifications", res.notifications);
                    this.$store.dispatch("user/setNotificationDate", res.notifications_updated);
                    this.handleNotifications(res.notifications, res.unseen_notifications);

                    this.replaceRoute();
                } else {
                    // User is not logged in
                    const persu = await this.$persistentStorage.getItem('persu');
                    if (persu) {
                        // Try key login
                        apiCall("/user/keyLogin", {
                            data: {
                                device_id: deviceId,
                                token: persu
                            }
                        }).then(res => {
                            if(res.logged_in === true) {
                                // User is now logged in
                                this.$store.dispatch("user/setNotifications", res.notifications);
                                this.$store.dispatch("user/setNotificationDate", res.notifications_updated);
                                this.handleNotifications(res.notifications, res.unseen_notifications)
                                res.ios = this.detectIosApp();
                                this.$store.dispatch("specialEvents/getEvent");

                                this.keyLogin(res);
                            } else {
                                this.logoutAction();
                            }
                            this.loaded = true;
                            this.hideLoading("global");
                        }).catch(err => {
                            this.logoutAction();
                            this.loaded = true;
                            this.hideLoading("global");
                        });
                    } else {
                        // Move user to login screen if it's not register view
                        const allowed_routes = ["loginActivation","register","registerResult","login"];
                        if(!allowed_routes.includes(this.$router.currentRoute.name)) {
                            this.$router.replace('/login');
                        }
                        this.loaded = true;
                        this.hideLoading("global");
                    }
                }

            });
            this.isMoreViewsActive(this.$router.currentRoute.name);
        },

        watch: {
            user_id: {
                handler(val) {
                    if(!val) {
                        clearInterval(this.heartbeat_interval);
                    } else {
                        this.doHeartBeat();
                    }
                },
                immediate: true,
            },
            '$route' (to, from) {
                this.isMoreViewsActive(to.name)
            }
        },

        methods: {
            async loadUniversumCampaignUrl() {
                await this.showLoading('component');
                let error = null;
                let ret = null;
                try {
                    const resp = await apiCall('/user/getUniversumUrl');
                    if (resp.error) {
                        error = resp.error;
                    } else {
                        ret = resp.url;
                        this.universumCampaignsUrl = ret;
                        this.$refs.universumModal.showModal();
                    }
                } catch (err) {
                    error = err;
                }
                if (error) {
                    console.warn(error);
                    this.$notify({
                        type: 'error',
                        group: 'general',
                        title: 'Knacka svarar inte just nu',
                        text: 'Det verkar inte vara någon hemma just nu, var god försök senare',
                        duration: 10000,
                        speed: 1000,
                    });
                }
                await this.hideLoading('component');
                return ret;
            },

            isMoreViewsActive(route){
                this.moreActive = this.moreViews.includes(route);
            },

            scrollToTop() {
                // if(this.$store.getters["items/posts"].length > 0){
                //     let scrollAmount = window.scrollY;
                //
                //     if(scrollAmount > 0){
                //         const slideTimer = setInterval(function() {
                //             console.log(scrollAmount);
                //             window.scrollTo( {top: scrollAmount});
                //             scrollAmount -= 100;
                //             if (scrollAmount <= -100) {
                //                 clearInterval(slideTimer);
                //             }
                //         }, 10);
                //     }
                // }
            },
            guid() {
                function s4() {
                    return Math.floor((1 + Math.random()) * 0x10000)
                        .toString(16)
                        .substring(1);
                }
                return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
            },
            toggleMoreMenu() {
                this.$refs.more_modal.toggleModal();
            },
            scheduleHeartBeat() {
                let vm = this;
                clearInterval(this.heartbeat_interval);
                this.heartbeat_interval = setInterval(function(){
                    vm.doHeartBeat();
                }, this.heartbeat_timout);
            },
            async doHeartBeat(force_full) {
                let vm = this;
                let data = {
                    full_beat: this.full_beat
                };

                this.full_beat = (force_full ? true : !this.full_beat);

                try {
                    let heartbeatResult = await apiCall('/default/heartbeat', {data});
                    if (!heartbeatResult.user || !heartbeatResult.user.is_logged_in) {
                        if (!await this.tryKeyLogin()) {
                            this.logoutFromHeartbeat();
                            return false;
                        } else {
                            heartbeatResult = await apiCall('/default/heartbeat', {data});
                            if (!heartbeatResult.user || !heartbeatResult.user.is_logged_in) {
                                this.logoutFromHeartbeat();
                                return false;
                            }
                        }
                    }

                    vm.handleHeartBeat(heartbeatResult);
                } catch (err) {
                    console.error('Hearbeat error', err);
                }

                if(this.heartbeat_interval === null){
                    this.scheduleHeartBeat();
                }

                this.$store.dispatch('specialEvents/getEvent');
                this.$store.dispatch('digitalVoting/getEvents');
                this.$store.dispatch('assemblyVoting/getVotings');
            },

            async tryKeyLogin() {
                // User is not logged in lets try keyLogin first
                const persu = await this.$persistentStorage.getItem('persu');
                const deviceId = await this.$persistentStorage.getItem('device_id');
                let isLoggedIn = false;

                if (persu && deviceId) {
                    isLoggedIn = await apiCall("/user/keyLogin", {
                        data: {
                            device_id: deviceId,
                            token: persu
                        }
                    }).then(res => {
                        if (res.logged_in) {
                            this.keyLogin(res);
                        }
                        return !!(res.logged_in);
                    }).catch(err => {
                        return false;
                    });
                }

                return isLoggedIn;
            },

            logoutFromHeartbeat() {
                this.userLoggedIn(false);
                clearInterval(this.heartbeat_interval);
                this.logoutAction();
            },

            handleHeartBeat(result) {
                let local_version = localStorage.getItem('version');
                let remote_version = result.version;

                if(remote_version !== local_version) {
                    localStorage.setItem('version', remote_version);
                    window.location.reload(true);
                }

                this.userLoggedIn(true);
                if(result.full_beat === true) {
                    this.setUserData(result.user);
                }

                let notificationsUpdated = new Date(result.notifications_updated);
                let notificationsDate = new Date(this.$store.getters["user/notificationsDate"]);


                if(notificationsUpdated.getTime() > notificationsDate.getTime() || result.notifications_updated && this.$store.getters["user/notificationsDate"] === "") {
                // if(result.notifications_updated > this.$store.getters["user/notificationsDate"] || result.notifications_updated && this.$store.getters["user/notificationsDate"] === "") {
                    console.log("*** Getting notifications")
                    apiCall("/user/getNotifications").then(res => {
                        this.handleNotifications(res.notifications, res.unseen_notifications);
                        this.$store.dispatch("user/setNotifications", res.notifications);
                        this.$store.dispatch("user/setNotificationDate", res.notifications_updated);
                    });
                }
                this.$store.dispatch("items/updateLastUpdate", result.activity);
                return true;
            },
            handleNotifications(notifications, unseen) {
                if(unseen > 0) {
                    this.shake_notification_bell = true;
                    let vm = this;
                    setTimeout(function() {
                        vm.shake_notification_bell = false;
                    }, 3000);
                }
            },
            doLogout() {
                clearInterval(this.heartbeat_interval);
                this.toggleMoreMenu();
                this.logoutAction();
            },
            ...mapActions({
                userLoggedIn: 'user/userLoggedIn',
                setUserData: 'user/setUserData',
                keyLogin: 'user/keyLogin',
                replaceRoute: 'user/replaceRoute',
                showLoading: 'loader/show',
                hideLoading: 'loader/hide',
                logoutAction: 'user/logoutUser',
            })
        },
        beforeDestroy() {
            clearInterval(this.heartbeat_interval);
        }
    }

</script>

<style lang="scss">
    @import 'src/scss/global.scss';
</style>
