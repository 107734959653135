import apiCall from "@/utils/api";
import moment from 'moment';
import Vue from "vue";

function initialState () {
    return {
        post: [],
        file: [],
        archive: {
            items: [],
            path: []
        },
        event: [],
        // TODO: check if needed
        loaded_date: {
            post: null,
            file: null,
            archive: null,
            event: null
        },
        reload: false,
        last_update: {
            post: null,
            file: null,
            event: null,
            archive: null
        },
        settings: {
            post: {
                items_per_page: 10,
                current_item: 10
            },
            event: {
                items_per_page: 100
            },
            file: {
                items_per_page: 10
            },
            archive: {
                items_per_page: 10
            }
        }
    }
}

export default {
    actions: {
        resetItems({commit}) {
            commit('RESET_STATE');
        },
        updateLastUpdate({commit, state, dispatch}, last_update) {
            commit('UPDATE_LAST_UPDATE', last_update);
            for(var i in last_update) {
                if(moment(last_update[i] || 0).unix() > moment(state.loaded_date[i] || 0).unix()) {
                    dispatch("loadItems", i);
                }
            }
        },
        editItem({ commit }, [type, item]) {
            commit('UPDATE_ITEM', [type, item]);
        },
        addItem({ commit, dispatch, getters }, [type, item, editing, group, postEvent]) {
            return new Promise((resolve, reject) => {
                let data = {
                    post: item
                };
                apiCall("/item/saveItem", {data}).then(res => {
                    if(type === "event" && res.files && res.files.length > 0){
                        const files = res.files.map(f => {
                            f.area_level = res.area_level;
                            f.area = res.area;
                            f.type = "file";
                            f.event_id = res.id;
                            f.event_file = true;
                            return f;
                        });
                        commit('ADD_ITEMS', ["file", files]);
                    }
                    if(editing){
                        if(postEvent !== undefined){
                            res.event_info = postEvent;
                        }
                        commit('UPDATE_ITEM', [type, res]);

                        if(type === "event") {
                            resolve(res);
                            dispatch("loadItems","event")

                            let lastPath = getters["path"].slice(-1).pop();
                            if(lastPath && lastPath.id){
                                dispatch("loadArchive", [lastPath.id, "/item/getFolder"]);
                            }
                        } else {
                            resolve(res);
                        }
                    } else {
                        dispatch("loadItems", "event");
                        let lastPath = getters["path"].slice(-1).pop();
                        if(lastPath && lastPath.id){
                            dispatch("loadArchive", [lastPath.id, "/item/getFolder"]);
                        }
                        if(postEvent !== null){
                            res.event_info = postEvent;
                        }
                        commit('ADD_ITEM', [type, res]);
                        dispatch('user/addNotification', res, {root:true});
                        resolve(res);
                    }
                });
            });
        },
        deleteItem({ commit, state }, [type, itemID]) {
            return new Promise((resolve, reject) => {
                let data = {
                    id: itemID
                };
                //TODO: If checkboxes are permanently removed, the first if-statement is unnecessary
                // if(Array.isArray((itemID))){
                //     for(let id of itemID) {
                //         if(type === "archive" || type === "file"){
                //             commit('DELETE_ARCHIVE_ITEM', id);
                //             commit('DELETE_ITEM', ["file", id]);
                //         } else {
                //             commit('DELETE_ITEM', [type, id]);
                //         }
                //     }
                // } else {
                if(type === "archive" || type === "file"){
                    commit('DELETE_ARCHIVE_ITEM', itemID);
                    commit('DELETE_ITEM', ["file", itemID]);
                } else if(type === "event") {
                    commit('DELETE_ITEM', [type, itemID]);
                    commit('DELETE_EVENT_FOLDER', itemID);
                } else {
                    commit('DELETE_ITEM', [type, itemID]);
                }
                // }
                apiCall("/item/deleteItem", {data}).then(res => {
                    // TODO: handle error
                });
                resolve(true);
            });
        },
        loadItems({ dispatch, commit, rootGetters, state }, type) {
            if (type === 'archive') {
                return dispatch('loadArchive', [0, "/item/getFolder"]);
            }
            return new Promise((resolve, reject) => {
                let data = {
                    param_type: type,
                    param_all: (rootGetters["user/currentGroupId"]) ? false : true,
                    param_group: (rootGetters["user/currentGroupId"]) ? rootGetters["user/currentGroupId"] : null,
                    param_limit: state.settings[type].items_per_page,
                };
                setTimeout(function() {
                    apiCall("/item/loadItems", {data}).then(res => {
                        if (typeof res.error === "string" && typeof res.code === "number") {
                            console.error("ERR - Got error when fetching items");
                        } else {
                            commit('SET_ITEMS', [type, res]);
                            commit('SET_DATE', type);
                        }
                        resolve(true);
                    });
                }, 2000);
            });
        },
        loadArchive({ commit, rootGetters }, [folder, url]){
            return new Promise((resolve, reject) => {
                let key = url === "/item/getFolder" ? "id" : "slug";
                let data = {};
                data[key] = folder;
                data.param_group =  (rootGetters["user/currentGroupId"]) ? rootGetters["user/currentGroupId"] : null;
                apiCall(url, {data}).then(res => {
                    let items = [];
                    if(typeof res.Files !== "undefined") {
                        items = res.Files.concat(res.Folders);
                    }
                    items.sort(item => {
                        if(item.type === 'folder') return -1
                    });
                    commit('SET_ARCHIVE', items);
                    commit('SET_PATH', res.Path);
                    commit('SET_DATE', "archive");
                    resolve(true);
                });
            });
        },
        loadMoreItems({ commit, rootGetters, state }, payload) {
            return new Promise((resolve, reject) => {
                const payloadType = (payload.hasOwnProperty('type')) ? payload.type : payload;
                let lastItem = !isNaN(payload.start_at) ? payload.start_at : state.settings[payloadType].current_item;
                let data = {
                    param_type: payloadType,
                    param_all: (rootGetters["user/currentGroupId"]) || payload.area > 0 ? false : true,
                    param_group: (rootGetters["user/currentGroupId"]) ? rootGetters["user/currentGroupId"] : null,
                    param_limit: state.settings[payloadType].items_per_page,
                    param_last_item: lastItem,
                    param_area: payload.area ? payload.area : 0
                };
                apiCall("/item/loadItems", {data}).then(res => {
                    if(typeof res.error === "string" && typeof res.code === "number") {
                        console.error("ERR - Got error when fetching items");
                    } else {
                        commit('ADD_ITEMS', [payloadType, res]);
                        commit('SET_DATE', payloadType);
                        commit('UPDATE_SETTING', [payloadType, "current_item", (state.settings[payloadType].current_item + state.settings[payloadType].items_per_page) ])
                    }
                    resolve(true);
                });
            });
        },
        loadAllItems({dispatch}) {
            return new Promise((resolve, reject) => {

                const types = ["post", "event","file", "archive"];
                let done = {
                    post: false,
                    event: false,
                    file: false,
                    archive: false
                };

                for(let i in types) {
                    let action;
                    let args;
                    if(types[i] === "archive"){
                        action = "loadArchive";
                        args = [0, "/item/getFolder"];
                    } else {
                        action = "loadItems";
                        args = types[i];
                    }
                    dispatch(action, args).then(res => {
                        done[types[i]] = true;

                        let all_done = true;
                        for(var j in done) {
                            if(done[j] === false) {
                                all_done = false;
                            }
                        }

                        if(all_done) {
                            resolve(true);

                        }
                    });
                }

            });
        },
        async saveReaction({commit}, [reactionName, itemID, type]) {
            let data = {
                name: reactionName,
                item_id: itemID
            };
            commit("SAVE_REACTION", [reactionName, itemID, type]);
            let reactionResponse;
            try {
                reactionResponse = await apiCall("/item/saveReaction", { data })
            } catch(error) {
                console.error(error);
            }

        },
        async removeReaction({commit}, itemID) {
            let data = {
                item_id: itemID
            };
            commit("REMOVE_REACTION", itemID);
            let response;
            try {
                response = await apiCall("/item/removeReaction", { data })
            } catch(error) {
                console.error(error)
            }

        },

        setAttend({commit, rootGetters}, [data]) {
            return new Promise((resolve, reject) => {
                apiCall("/item/setAttend", {data}).then(res => {
                    commit('SET_ATTEND', [data.answer, data.item, rootGetters["user/userData"].id]);
                    resolve(true);
                });
            });
        },
        tagPost({commit}, [itemID, tags]){
            return new Promise((resolve, reject) => {
                let data = {
                    item_id: itemID,
                    tag_name: tags
                };
                apiCall("/tag/tag", {data}).then(res => {
                    commit("TAG_POST", [itemID, tags]);
                    resolve(true);

                });
            })
        }
    },

    namespaced: true,

    state: {
        initialState: initialState,
        ...initialState(),
    },

    mutations: {
        ADD_ITEM(state, [type, item]){
            if(type !== "folder"){
                state[type].unshift(item);
            }
            // if(type === "file"){
            //     state.archive.items.push(item);
            // }
        },
        ADD_ITEMS(state, [type, items]){
            if(type === "file"){
                // First remove all files associated with event
                state.file = state.file.filter(f => f.event_id !== items[0]["event_id"]);
                state[type].unshift(...items);
            } else {
                state[type].push(...items);
            }
        },
        SET_ITEMS(state, [type, items]) {
            state[type] = items;
        },
        // ADD_ARCHIVE_ITEM(state, item){
        //     state.archive.items.unshift(item);
        // },
        SET_DATE(state, type) {
            state.loaded_date[type] = moment().format("YYYY-MM-DD HH:mm:ss");
        },
        SET_ARCHIVE(state, items){
            state.archive.items = items;
        },
        SET_PATH(state, path){
            state.archive.path = path;
        },
        UPDATE_SETTING(state, [type, setting, value]){
            state.settings[type][setting] = value;
        },
        DELETE_ITEM(state, [type, itemID]){
            state[type] = state[type].filter(item => item.id !== itemID)
            // state[type].splice(index, 1);
        },
        DELETE_EVENT_FOLDER(state, eventID){
          state.archive.items = state.archive.items.filter(item => parseInt(item.event_id) !== parseInt(eventID));
          state.file = state.file.filter(item => parseInt(item.event_id) !== parseInt(eventID));
        },
        DELETE_ARCHIVE_ITEM(state, itemID){
            // state.archive.items.splice(index, 1);
            state.archive.items = state.archive.items.filter(item => item.id !== itemID)
        },
        SET_ATTEND(state, [answer, event, userID]){
          const events = state.event.map(e => {
              if(e.id === event.id){
                  if(e.attendees){
                      let object = e.attendees;
                      if(typeof object === "object") {
                          let key = Object.keys(object).find(key => object[key]["id"] === userID);
                          if (typeof key !== "undefined") {
                              Vue.delete(object, parseInt(key));
                          }
                      }
                  } else {
                      e.attendees = [];
                  }
                  if(answer === 1){
                      e.attend = answer;
                      e.attend_total++;
                      e.attendees.push({
                          attend: "1",
                          id: userID,
                          name: this.getters["user/userData"].full_name
                      });
                  } else if(answer === 2){
                      e.attend = answer;
                      if(e.attend_total > 0){
                          e.attend_total--;
                      }
                      e.attendees.push({
                          attend: "2",
                          id: userID,
                          name: this.getters["user/userData"].full_name
                      });
                  } else {
                      if(e.attend_total > 0){
                          e.attend_total--;
                      }
                      e.attend = 0;
                  }
                  return e;
              } else {
                  return e;
              }
          });
            Vue.set(state, 'event', events);

        },
        UPDATE_ITEM(state, [type, item]) {
            let index = state[type].findIndex( s => s.id === item.id );
            Vue.set(state[type], index, item);
        },
        UPDATE_LAST_UPDATE(state, last_update) {
            state.last_update = last_update;
        },
        RESET_STATE(state){
            // acquire initial state
            const s = initialState();
            Object.keys(s).forEach(key => {
                state[key] = s[key]
            })
        },
        TAG_POST(state, [postID, tags]){
            const index = state.post.findIndex( post => post.id === postID);
            // state.post[index].tags = tags.map(tag => {
            //     return {
            //         name: tag,
            //     }
            // });
            state.post[index].tags = tags;
        },
        SAVE_REACTION(state, [reaction, itemID, type]){
            const index = state[type].findIndex( item => item.id === itemID);

            if(!state[type][index].user_reaction) {
                if (!state[type][index].reactions[reaction]) {
                    if(state[type][index].reactions.length <= 0){
                        //Reactions is empty array, post has no reactions
                        state[type][index].reactions = {};
                    }
                    state[type][index].reactions[reaction] = {
                        name: reaction,
                        count: 1
                    };
                } else {
                    state[type][index].reactions[reaction]["count"]++;
                }
                state[type][index].user_reaction = {
                    name: reaction
                };
            }
        },
        REMOVE_REACTION(state, itemID){

            const newState = Object.values({ ...state.post }).map(post => {
               if (parseInt(post.id ) === parseInt(itemID)) {
                   const p = post;
                   const reactionName = p.user_reaction.name;

                   if (p.reactions[reactionName].count === 1) {
                        delete p.reactions[reactionName];
                   } else {
                       p.reactions[reactionName].count--;
                   }

                   p.user_reaction = null;

                   return p;
               }
               return post;
            });

            Vue.set(state, 'post', newState);
        }
    },

    getters: {
        posts(state){
            return state.post;
        },
        events(state){
            return state.event;
        },
        files(state){
            return state.file;
        },
        archive(state){
            return state.archive.items;
        },
        path(state){
            return state.archive.path;
        },
        postsNeedsInitialLoad(state) {
            if (state.loaded_date.post === null) {
                return true;
            } else {
                return false;
            }
        },
        eventsNeedsInitialLoad(state) {
            if (state.loaded_date.event === null) {
                return true;
            } else {
                return false;
            }
        },
        filesNeedsInitialLoad(state){
            return state.loaded_date.file === null;
        },
        archiveNeedsInitialLoad(state){
            return state.loaded_date.archive === null;
        }
    }
};
