<template>
    <div class="calendar gutter single-item">
        <div v-if="item.id">

            <div class="return-button">
                <div @click="goBack"><i class="mdi mdi-chevron-left"></i> <span v-if="item.type === 'event'"> Tillbaka till {{backString}} </span>
                    <span v-else>Gå tillbaka</span></div>
            </div>

            <Form ref="Form" :type="item.type" :edit-action="editItem" :postEvent="postEvent"/>

            <!--<listitem :item="item" :type="this.item.type" />-->
            <div class="post" :class="[itemClass]">

                <div class="post_images" v-if="item.main_image != null">
                    <div class="images">
                        <div class="main-image image-instance">
                            <img :src="item.main_image.url + '_medium'" />
                        </div>
                        <template v-if="item.images.length > 0">
                            <div class="collage image-instance">
                                <div class="image" v-for="(file, index) in item.images" :key="index">
                                    <a :href="file.url" v-if="file.type == 'image'"><img :title="file.name" :src="file.url" /></a>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>

                <div class="post_video" v-if="item.video != null">
                    <iframe width="560" height="315" :src="item.video.url" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>

                <div class="post_creator">
                    <div class="post_creator_inner">
                        <div class="gravatar">
                            <img :src=" baseUrl + 'avatar/id/' + item.user " />
                        </div>
                        <div>
                            <div class="name">
                                {{ item.creator_name }}
                            </div>
                            <div v-if="item.creator_title != ''" class="title">{{ item.creator_title }}</div>
                            <div class="date">
                                {{ humanDate }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="post_content_wrapper">
                    <div class="post_tags">
                        {{ item.area_name }}
                    </div>

                    <event-dates v-if="item.type === 'event'" :event="item"/>

                    <template v-if="item.type === 'event'">
                        <div class="location-wrapper" v-if="item.location_zip.length > 0 || item.location_city.length > 0 || item.location_address.length > 0">
                            <div v-if="item.location_address.length > 0">{{ item.location_address }}</div>
                            <span v-if="item.location_zip.length > 0">{{ item.location_zip }}</span><span v-if="item.location_city.length > 0">{{ item.location_city }}</span>
                        </div>
                    </template>

                    <div class="post_header">
                        {{ (item.type === 'event' ? (item.event_type === 'meeting' ? 'Möte:' : 'Kampanj:' ) : '') }} {{ item.title }}
                    </div>

                    <p class="post_content">
                        <span v-html="item.html_text"></span>
                    </p>

                    <!--<div class="post_reactions" v-if="Object.keys(this.item.reactions).length > 0">-->
                        <!--<div class="reaction" v-for="(reaction, i) in this.item.reactions" :key="`B-${i}`" v-on:click="onReaction(reaction.name)">-->
                            <!--{{ reaction.name }}-->
                        <!--</div>-->
                        <!--<div class="reaction-count">  {{ total_reactions }}</div>-->
                    <!--</div>-->


                    <div class="post_link" v-if="item.type === 'post ' && (item.link !== null || item.link !== '')">
                        <a :href="item.link.url" target="_blank">
                            <div class="post_link_image">
                                <img :src="item.link.preview.cover" />
                            </div>
                            <div class="post_link_content">
                                <img class="link_icon" src="@/assets/link.svg" />
                                <div class="post_link_title">{{ item.link.preview.title }}</div>
                                <a>{{ item.link.url }}</a><br/>
                                <p>{{ item.link.preview.description }}</p>
                            </div>
                        </a>
                    </div>
                </div>

                <EventAttend v-if="item.type === 'event' && parseInt(item.allow_attend) !== 0" :eventID="item.id" @setAttend="setAttend($event)"/>

                <div class="event-service" v-if="item.type === 'event'">
                    <a :href="item.ics_url" target="_blank" class="button">
                        <i class="mdi mdi-calendar"></i> Spara till kalender
                    </a>
                    <a :href="item.gmaps_url" target="_blank" class="button">
                        <i class="mdi mdi-map"></i> Visa vägbeskrivning
                    </a>
                    <div class="button" @click="togglePostEventForm" v-if="item.user === user_data.id">
                        <i class="mdi mdi-file-document-box"></i>Posta event i flödet
                    </div>
                </div>


                <div class="post_content_wrapper" v-if="total_reactions > 0">
                    <div class="post_reactions">
                        <img
                                class="reaction"
                                v-for="(reaction) in reactionList"
                                :src="reactionUrl(reaction.name)"
                                :key="reaction.name"
                                @click="onReaction(reaction.name)"/>
                        <div class="reaction-count">  {{ total_reactions }}</div>
                    </div>
                </div>

                <div class="post_tools">
                    <div class="post_tools_wrapper reactions">
                        <div class="react-button" v-on:click="show_reactions = !show_reactions" v-show="!item.user_reaction"><i class="mdi mdi-thumb-up"></i> Gilla</div>
                        <div class="react-button" v-on:click="removeReaction" v-show="item.user_reaction">Sluta gilla</div>
                        <transition name="popup">
                            <div class="reaction-popup" v-if="show_reactions">
                                <img class="reaction" v-show="show_reactions" v-for="(reaction, index) in reactions" :src="reactionUrl(reaction)" :key="index + 'r'" @click="onReaction(reaction)"/>
                            </div>
                        </transition>

                    </div>

                    <div class="post_tools_wrapper" v-if="item.allow_comments">
                        <div class="comments_button">
                            <a href="javascript:void(0)" v-on:click="showComments()">
                                <i class="mdi mdi-comment"></i> Kommentarer ({{ comments.length }})
                            </a>
                        </div>
                    </div>

                    <div class="post_tools_wrapper" v-if="hasAccess">
                        <div class="dropdown-wrapper">
                            <div class="icon" v-on:click="showChoices = !showChoices" v-on-clickaway="closeChoices">
                                <svg viewBox="0 0 22 6">
                                    <path d="M11 5.75C12.5188 5.75 13.75 4.51878 13.75 3C13.75 1.48122 12.5188 0.25 11 0.25C9.48122 0.25 8.25 1.48122 8.25 3C8.25 4.51878 9.48122 5.75 11 5.75Z" />
                                    <path d="M19.25 5.75C20.7688 5.75 22 4.51878 22 3C22 1.48122 20.7688 0.25 19.25 0.25C17.7312 0.25 16.5 1.48122 16.5 3C16.5 4.51878 17.7312 5.75 19.25 5.75Z" />
                                    <path d="M2.75 5.75C4.26878 5.75 5.5 4.51878 5.5 3C5.5 1.48122 4.26878 0.25 2.75 0.25C1.23122 0.25 0 1.48122 0 3C0 4.51878 1.23122 5.75 2.75 5.75Z" />
                                </svg>
                            </div>
                            <transition name="popup">
                                <div class="dropdown" v-if="showChoices">
                                    <ul class="more">
                                        <li v-on:click.prevent="toggleForm(item)"><i class="mdi mdi-pencil"></i>Editera</li>
                                        <li v-show="!suredelete" v-on:click="suredelete = true"><i class="mdi mdi-trash-can"></i>Ta bort</li>
                                        <li v-show="suredelete" v-on:click.prevent="deleteItem(item.id)" class="background-red suredelete"><i class="mdi mdi-trash-can background-red"></i>Säker?</li>
                                    </ul>
                                </div>
                            </transition>
                        </div>
                    </div>

                </div>

                <comments v-if="show_comments && item.allow_comments" :comments="comments" :item="item" @postComment="postComment($event)" @removeComment="removeComment" :baseUrl="baseUrl" />

                <div class="files" v-if="item.files.length > 0">
                    <div class="content-header pale-header">Bifogade filer</div>
                    <FileItem v-for="(file, i) in files" :key="`A-${i}`" :item="file"/>
                </div>

            </div>
        </div>
        <Loader type="component" />
        <div v-if="!this.item.id && !this.loading">
            Det gick tyvärr inte att hitta den posten.
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import {mapState} from "vuex";
    // import Calendar from '@/components/Calendar.vue'
    import { mapActions }from "vuex";
    import apiCall from "@/utils/api";
    import moment from 'moment';
    import Form from "@/components/Form.vue";
    import Loader from "@/components/Loader"
    // import { VCALENDAR, VEVENT } from 'ics-js';
    import Comments from "@/components/Comments";
    import EventDates from "@/components/EventDates";
    import { mixin as clickaway } from 'vue-clickaway';
    import EventAttend from "@/components/EventAttend";
    import FileItem from "@/components/Fileitem";


    export default {
        name: 'SingleItem',
        components: {
            Form,
            Loader,
            Comments,
            EventDates,
            EventAttend,
            FileItem
        },
        mixins: [clickaway],
        data: function() {
            return {
                baseUrl: process.env.VUE_APP_API_DOMAIN+"backend/",
                item: {
                    images: [],
                    files: [],
                    attendees: []
                },
                slug: this.$route.params.slug,
                comment: "",
                comments: [],
                showChoices: false,
                show_comments: true,
                show_reactions: false,
                loading: false,
                reactions: ["thumb-up", "angel-face", "cake", "heart", "laugh-17", "malicious", "party-popper", "smile"],
                componentKey: 0,
                postingEvent: false,
                suredelete: false,
                prevRoute: null
            }
        },
        watch: {
            suredelete(val) {
                let vm = this;
                if(val) {
                    setTimeout(function() {
                        vm.suredelete = false;
                    }, 3000)
                }
            }
        },
        filters: {
            formatDate: function(value) {
                if (value) {
                    return moment(String(value)).format('DD/MM HH:mm')
                }
            }
        },
        computed: {
            files(){
              if(this.item.files.length > 0){
                  return this.item.files.map(f => {
                      f.area_level = this.item.area_level;
                      f.area = this.item.area;
                      f.type = "file";
                      return f;
                  })
              }
              return [];
            },
            hasAccess(){
                if(this.currentGroup.id > 0 || parseInt(this.item.area_level) === 0){
                    return this.item.user === this.user_data.id || this.$store.getters['user/isGroupOwner'](this.item.group);
                } else {
                    let type = this.item.type;
                    return this.$store.getters['user/hasAccess'](type, this.item.area);
                }
            },
            backString() {
                if (this.$route.params.postEvent !== undefined) {
                    return "inläggsflödet";
                } else {
                    return "kalendern";
                }
            },
            postEvent() {
                if (this.item.type === 'event') {
                    return {
                        location_address: this.item.location_address,
                        location_city: this.item.location_city,
                        id: this.item.id,
                        title: this.item.title,
                        start: this.item.start,
                        end: this.item.end,
                        area_level: this.item.area_level,
                        attend: this.item.attend,
                        main_image: this.item.main_image,
                        slug: this.item.slug,
                        attend_total: this.item.attend_total,
                        area: this.item.area,
                        area_name: this.item.area_name,
                        group: this.item.group
                    };
                }
                return null;

            },
            reactionUrl(url) {
                return url => {
                    return require("@/assets/libemo/" + url + ".svg");
                }
            },
            reactionList() {
                return this.item.reactions;
            },
            total_reactions() {
                this.componentKey;
                const reactions = this.item.reactions;
                return Object.values(reactions).reduce((acc, val) => {
                    return acc + val.count;
                }, 0);
            },
            itemClass() {
                let area_level = parseInt(this.item.area_level);
                if (area_level === 1) {
                    return "riks";
                } else if (area_level === 2) {
                    return "forbund";
                } else if (area_level === 3 || area_level === 4) {
                    return "kommun";
                } else if (area_level === 0){
                    return "group";
                }
                return "";
            },
            humanDate() {
                return moment(this.item.creation_date).locale("sv").format('DD MMMM [kl.] HH:mm');
            },
            currentGroup(){
                return this.$store.getters['user/currentGroup'];
            },
            ...mapState({
                user_data: state => state.user.data,
                events: state => state.items.event
            }),
        },
        methods: {
            ...mapActions({
                showLoading: 'loader/show',
                hideLoading: 'loader/hide',
            }),
            goBack(){
              if(this.prevRoute === null){
                  if(this.item.type === "post"){
                      this.$router.push("/");
                  } else if(this.item.type === "file" || this.item.type === "folder"){
                      this.$router.push("/archive");
                  } else if(this.item.type === "event"){
                      this.$router.push("/calendar");
                  }
              } else {
                  this.$router.go(-1);
              }
            },
            closeChoices(){
                if(!this.suredelete) {
                    this.showChoices = false;
                }
            },
            toggleForm(item) {
                this.$refs.Form.toggleForm(item);
            },
            togglePostEventForm(){
                this.postingEvent = true;
                this.$refs.Form.openForm('post');
            },
            showComments() {
                this.show_comments = !this.show_comments;
            },
            getComments() {
                let data = {
                    parent: this.item.type,
                    parent_identifier: this.item.id
                };
                return apiCall("/comment/getComments", { data });
            },
            postComment(comment) {
                let vm = this;
                if(comment.length > 0) {
                    let data = {
                        parent: this.item.type,
                        parent_id: this.item.id,
                        comment: comment
                    };
                    apiCall("/comment/makeComment", { data }).then(res => {
                        vm.item.comments++;
                        this.comments.push(res);
                    });
                }
            },
            removeComment(id) {
                let data = {
                    id: id
                };
                apiCall("/comment/removeComment", { data }).then(res => {
                    if(!res.error) {
                        this.item.comments--;
                        this.comments = this.comments.filter(obj => {
                            return obj.id !== id
                        });
                        this.$notify({
                            type: 'success',
                            group: 'general',
                            title: 'Tog bort kommentar',
                            text: '',
                            duration: 10000,
                            speed: 1000
                        });
                    }
                });
            },
            loadItem(id) {
                this.loading = true;
                this.showLoading("component");
                if(id) {
                    let data = {
                        id: id
                    };
                    apiCall("/item/loadItem", {data}).then(res => {
                        this.loading = false;
                        this.item = res;
                        this.getComments().then(res => {
                            this.comments = res;
                            this.hideLoading("component");
                        });
                    });
                } else {
                    let data = {
                        type: "event",
                        slug: this.slug
                    };
                    apiCall("/item/loadItemFromSlug", {data}).then(res => {
                        this.loading = false;
                        this.item = res;
                        this.getComments().then(res => {
                            this.comments = res;
                            this.hideLoading("component");
                        });
                    });
                }
            },
            editItem(item) {
                this.loadItem(this.item.id)
            },
            deleteItem() {
                this.$store.dispatch("items/deleteItem", [this.item.type, this.item.id]).then(res => {
                    if(this.item.type === "event"){
                        this.$router.push({name: 'calendar'});
                    } else {
                        this.$router.push({name: 'home'})
                    }
                })
            },
            onReaction(name) {
                if(!this.item.user_reaction) {

                    if (!this.item.reactions[name]) {
                        this.item.reactions = {};
                        this.item.reactions[name] = {
                            name: name,
                            count: 1
                        };
                    } else {
                        this.item.reactions[name]["count"]++;
                    }
                    this.item.user_reaction = {
                        name: name
                    };
                    this.$forceUpdate();
                    this.componentKey += 1;

                    this.show_reactions = false;

                    let data = {
                        name: name,
                        item_id: this.item.id
                    };
                    apiCall("/item/saveReaction", { data }).then(res => {
                        // console.log(res)
                    })
                }
            },
            removeReaction() {
                if(this.item.user_reaction) {
                    let name = this.item.user_reaction.name;
                    this.item.reactions[name].count--;
                    if(this.item.reactions[name].count < 1) {
                        this.$delete(this.item.reactions, name);
                    }
                    this.$delete(this.item, "user_reaction");
                    let data = {
                        item_id: this.item.id
                    };
                    apiCall("/item/removeReaction", { data }).then(res => {
                        // console.log(res)
                    })
                }
            }
        },
        created() {
            this.loadItem();
        },
        beforeRouteEnter(to, from, next) {
            const vueFrom = from.name;
            next(vm => {
                vm.prevRoute = vueFrom;
            })
        },
    }
</script>
