<template>
    <div class="group-picker" >
        <div class="group" v-for="(group, index) in groups" :key="index + 'group'" v-on:click="openGroup(group)">
            <div class="group-icon">
                <div>{{ group.short }}</div>
                <span class="badge" v-if="group.notifications > 0">{{group.notifications}}</span>
            </div>
            <div class="group-description">
                <div class="group-title">{{ group.title }}</div>
                <div>{{ group.members }} <span v-if="parseInt(group.members) > 1 || parseInt(group.members) === 0">medlemmar</span><span v-if="parseInt(group.members) === 1">medlem</span></div>
            </div>
        </div>
    </div>
</template>

<script>
    import apiCall from "@/utils/api"

    export default {
        name: "GroupPicker",
        props: {
            groups: {
                type: Array
            }
        },
        methods: {
            openGroup(group){
                this.$emit('openGroup', group);

                this.$store.dispatch("user/clearGroupNotifications", group.id);


            }
        }
    }
</script>

<style scoped>

</style>
