<template>

    <div>
        <img v-if="!isFile" :src="file.url" />
        <div class="file-name">
            <i v-if="isFile" class="mdi mdi-file"></i>
            <span class="uploaded-file-name">{{ file.title }}</span>
            <button v-show="!sureDelete" @click="sureDelete = true" type="button" class="btn warning"><i class="mdi mdi-trash-can"></i>Ta bort {{ uploadedFileString }}</button>
            <button v-show="sureDelete" class="btn warning" @click="removeFile" type="button"><i class="mdi mdi-trash-can background-red"></i>Säker?</button>
        </div>
    </div>

</template>

<script>
    export default {
        props: {
            isFile: {
                type: Boolean
            },
            file: {
                type: Object,
                default: () => {}
            },
            uploadedFileString: {
                type: String
            }
        },
        data() {
          return {
              sureDelete: false,
          }
        },
        methods: {
            removeFile(){
                this.$emit('removeFile', this.file.id)
            }
        }
    };
</script>
