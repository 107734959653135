import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import MyPage from './views/Mypage.vue'
import Calendar from './views/Calendar.vue'
import SingleItem from './views/SingleItem.vue'
import Archive from './views/Archive.vue'
import Notices from './views/Notices.vue'
import Newsletter from './views/Newsletter';
import Billing from './views/Billing';
import Register from './views/Register';
import Links from './views/Links';
import Help from './views/Help';
import Donation from './views/Donation';
import Login from '@/components/Login'
import ConfirmGdpr from "./views/ConfirmGdpr"
import Communication from "./views/Communication";
import SpecialEvents from "./views/SpecialEvents";
import SpecialEventItemView from "./components/specialEvents/HappeningView";
import Associations from "./views/Associations";
import Groups from "./views/Groups";
import DigitalVotings from '@/views/DigitalVotings';
import DigitalVoting from '@/views/DigitalVoting';
import Campaigns from '@/views/Campaigns.vue';


Vue.use(Router);

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if(to.hash){
          resolve({ selector: to.hash })
        } else {
          resolve({ x: 0, y: 0 })
        }
      }, 500)
    })
  },
  routes: [
      {
          path: '/',
          name: 'home',
          component: Home
      },
      {
          path: '/notices',
          name: 'notices',
          component: Notices
      },
      {
          path: '/post/:slug',
          component: SingleItem
      },
      {
          path: '/campaigns/',
          component: Campaigns,
          name: 'campaigns',
      },
      // {
      //     path: '/calendar',
      //     name: 'calendar',
      //     component: Calendar,
      // },
      // {
      //     path: '/calendar/:slug/:postEvent',
      //     component: SingleItem
      // },
      // {
      //     path: '/calendar/:slug/',
      //     component: SingleItem
      // },
      {
          path: '/archive',
          name: 'archive',
          component: Archive,
      },
      {
          path: '/archive/:folderSlug',
          name: 'archiveFolder',
          component: Archive,
      },
      {
          path: '/archive/:folderSlug/:fileSlug',
          name: 'archiveFile',
          component: Archive,
      },
      {
          path: '/mypage',
          name: 'mypage',
          component: MyPage
      },
      {
          path: '/login',
          name: 'login',
          component: Login
      },
      {
          path: '/login/:activation_key',
          name: 'loginActivation',
          component: Login
      },
      {
          path: '/associations',
          name: 'associations',
          component: Associations
      },
      {
          path: '/groups',
          name: 'groups',
          component: Groups
      },
      {
          path: '/donation',
          name: 'donation',
          component: Donation
      },
      {
          path: '/donation/:success',
          name: 'donationSuccess',
          component: Donation
      },
      {
          path: '/newsletter',
          name: 'newsletter',
          component: Newsletter
      },
      {
          path: '/billing',
          name: 'billing',
          component: Billing
      },
      {
          path: '/billing/success/:invoice_number',
          name: 'billingSuccess',
          component: Billing
      },
      {
          path: '/help',
          name: 'help',
          component: Help
      },
      {
          path: '/help/:newbie',
          name: 'helpNewbie',
          component: Help
      },
      {
          path: '/register/:result',
          name: 'registerResult',
          component: Register
      },
      {
          path: '/register',
          name: 'register',
          component: Register
      },
      {
          path: '/confirmgdpr',
          name: 'confirmgdpr',
          component: ConfirmGdpr
      },
      {
          path: '/links',
          name: 'links',
          component: Links
      },
      {
          path: '/communication',
          name: 'communication',
          component: Communication
      },
      {
          path: '/specialevents',
          name: 'specialevents',
          component: SpecialEvents,
      },
      {
          path: '/happening/:id',
          name: 'happening',
          component: SpecialEventItemView,
      },
      {
          path: '/specialevents/:id',
          name: 'specialeventspage',
          component: SpecialEvents
      },
      {
          path: '/digitalvoting/:id',
          name: 'digitalvotingspage',
          component: DigitalVotings,
          props: true
      },
      {
          path: '/digitalvoting/:eventId/voting/:id',
          name: 'digitalvotingpage',
          component: DigitalVoting,
          props: (route) => ({
              eventId: route.params.eventId,
              id: route.params.id,
              preloadedVoting: route.params.preloadedVoting
          })
      },

      /**
       * Fallback
       */
      {
          path: '*',
          redirect: { name: 'home'}
      },
  ]
});

export default router;
