<template>
    <div id="list">
        <div id="sticky-header">
            <Search @opened="searchView = true" @closed="searchView = false"/>
            <Areafilter ref="Areafilter" v-show="!searchView" @switch="onAreaSwitch"/>
        </div>
        <div v-show="!searchView">
            <Form ref="Form" :type="this.type"/>
<!--            <div v-if="noItems">-->
<!--                <p>Här var det tomt! Men var inte ledsen, så fort det börjar hända saker kommer det vara fullt här.</p>-->
<!--            </div>-->
            <transition-group name="fade" tag="div">
                <listitem v-for="item in filteredList" :key="item.id" :item="item" :type="type" @filterTaggedItems="filterTaggedItems($event)" />
            </transition-group>
            <template v-if="noItems">
                <div v-if="showLoadMore" @click="loadMoreItems" class="text-center">
                    <button class="btn btn-default blue">Ladda fler</button>
                </div>
                <div v-if="showNoPostsMessage" class="text-center">
                    Tyvärr kunde vi inte hitta några aktuella inlägg
                </div>
            </template>
            <div class="load_wrapper">
                <Loader type="component" />
            </div>
        </div>
    </div>
</template>

<script>

    import {mapActions, mapGetters} from "vuex";
    import Form from "./Form";
    import Listitem from "./Listitem";
    import Loader from "@/components/Loader";
    import Search from "./Search";
    import Areafilter from "./Areafilter";
    import moment from 'moment';
    import uniqBy from 'lodash/uniqBy';

    export default {
        name: "List",
        components: {
            Areafilter,
            Search,
            Form,
            listitem: Listitem,
            Loader
        },
        props: {
            type: {
                type: String,
                default: "post"
            }
        },
        data() {
            return {
                componentLoaded: false,
                title: "Nyheter",
                single_name: "nyheten",
                items: [],
                searchView: false,
                bottom: false,
                current_bottom: 10,
                items_per_page: 10,
                chosenTag: "",
                fetchedItems: false,
                headerBar: "",
                offset: "",
                showLoadMore: true
            }
        },
        computed: {
            ...mapGetters({
                posts: 'items/posts',
                currentGroup: 'user/currentGroup',
                userData: 'user/userData'
            }),
            filteredList() {
                if(!this.componentLoaded) {
                    return null;
                }

                if(this.currentGroup.id > 0) {
                    return uniqBy(this.draftFilteredList, 'id');
                } else {
                    return uniqBy(this.draftFilteredList.filter(item => {
                        return this.$refs.Areafilter.filters.includes(parseInt(item.area_level))
                    }), 'id');
                }
            },
            draftFilteredList() {
                if(!this.componentLoaded) {
                    return [];
                }
                if(this.currentGroup.id > 0) {
                    return this.posts;
                } else {
                    return this.posts.filter(item => {
                        return item.status !== 'draft' || item.user === this.userData.id
                    });
                }
            },
            noItems() {
                if(this.fetchedItems){
                    return this.filteredList.length < this.items_per_page;
                } else {
                    return false;
                }
            },
            showNoPostsMessage() {
                return !this.filteredList.length && !this.showLoadMore;
            }
        },
        filters: {
            formatDate: function(value) {
                if (value) {
                    return moment(String(value)).format('DD/MM HH:mm')
                }
            }
        },
        created() {
            if(this.type === "file") {
                this.title = "Filer";
                this.single_name = "filen";
            }
            if(this.$store.getters["items/postsNeedsInitialLoad"] === true) {
                this.showLoading("component");
                this.$store.dispatch("items/loadItems", "post").then(res => {
                    this.fetchedItems = true;
                    this.hideLoading("component");
                });
            }
        },
        mounted () {
            if(this.posts.length > 0){
                this.fetchedItems = true;
            }
            this.componentLoaded = true;
            this.headerBar = document.getElementById("sticky-header");
            this.offset = document.getElementById("sticky-header").offsetTop;
            window.addEventListener('scroll', this.handleScroll)
        },
        destroyed () {
            window.removeEventListener('scroll', this.handleScroll);
        },
        watch: {
            bottom(bottom) {
                if (bottom) {
                    this.loadMoreItems()
                }
            }
        },
        methods: {
            onAreaSwitch() {
                this.showLoadMore = true;

                if (!this.filteredList.length < this.items_per_page) {
                    this.loadMoreItems();
                }
            },
            bottomVisible() {
                const list = document.getElementById("home");
                if(list){
                    const listHeight = list.clientHeight - 300;
                    const scrollY = window.scrollY + window.innerHeight;
                    return scrollY > listHeight;
                } else {
                    return false;
                }
            },
            addStickyToHeader(){
                if (window.pageYOffset >= this.offset) {
                    this.headerBar.classList.add("sticky")
                } else {
                    this.headerBar.classList.remove("sticky");
                }
            },
            handleScroll(){
                this.bottom = this.bottomVisible();
                this.addStickyToHeader();
            },
            ...mapActions({
                showLoading: 'loader/show',
                hideLoading: 'loader/hide',
            }),
            deleteItem(postID, index) {
                this.$store.dispatch("items/deleteItem", ["post", postID,index]);
            },
            loadMoreItems() {
                this.showLoading("component");
                const currentListLength = this.filteredList.length;
                let payload = {
                    type: 'post'
                };
                if (this.$refs.Areafilter.filters.length) {
                    payload.area = this.$refs.Areafilter.filters[0];
                    payload.start_at = this.filteredList.length;
                }
                this.$store.dispatch("items/loadMoreItems", payload).then(res => {
                    this.hideLoading("component");
                    this.showLoadMore = (currentListLength !== this.filteredList.length);
                });
            },
            filterTaggedItems(tagName){
                this.chosenTag = tagName;
            },
            exitTag(){
              this.chosenTag = "";
            },
        },
    }

</script>

<style type="scss">
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>
