import apiCall from '@/utils/api';

export default {
    namespaced: true,
    state: {
        events: [],
        eventsLoaded: false
    },
    getters: {
        eventIsHappening(state)
        {
            return !!(state.events.length);
        },
        eventsLoaded(state)
        {
            return state.eventsLoaded;
        },
        events(state)
        {
            return state.events;
        }
    },
    actions: {
        async getEvents({ commit, state, getters, dispatch, rootGetters })
        {
            if (!getters.eventIsHappening) {
                dispatch('loader/show', 'component', { root: true });
            }

            let eventRequest = await apiCall('/user/getDigitalVotingEvents').catch(error => null);

            if (eventRequest && eventRequest.hasOwnProperty('events') && eventRequest.events.length) {
                commit('SET_EVENTS', eventRequest.events);
            } else {
                commit('SET_EVENTS', []);
            }

            commit('SET_EVENTS_LOADED', true);
            if (rootGetters['loader/isLoading']) {
                dispatch('loader/hide', 'component', { root: true });
            }
        }
    },
    mutations: {
        SET_EVENTS_LOADED(state, data)
        {
            state.eventsLoaded = data;
        },
        SET_EVENTS(state, data)
        {
            state.events = data;
        }
    }

}
