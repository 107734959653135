<template>
    <transition name="fade">
        <div :class="classes" v-if="show && visible">
            <div class="spinner">
                <!--<svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">-->
                    <!--<circle class="length" fill="none" stroke-width="8" stroke-linecap="round" cx="33" cy="33" r="28"></circle>-->
                <!--</svg>-->
                <!--<svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">-->
                    <!--<circle fill="none" stroke-width="8" stroke-linecap="round" cx="33" cy="33" r="28"></circle>-->
                <!--</svg>-->
                <!--<svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">-->
                    <!--<circle fill="none" stroke-width="8" stroke-linecap="round" cx="33" cy="33" r="28"></circle>-->
                <!--</svg>-->
                <!--<svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">-->
                    <!--<circle fill="none" stroke-width="8" stroke-linecap="round" cx="33" cy="33" r="28"></circle>-->
                <!--</svg>-->
            </div>
        </div>
    </transition>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'Loader',

        props: {
            type: {
                type: String,
                default: 'global'
            },
            visible: {
                type: Boolean,
                default: true
            }
        },

        computed: {
            show() {
                return this.loading[this.type] === true;
            },

            isGlobal() {
                return this.type === 'global';
            },

            classes() {
                return {
                    loading: true,
                    active: this.show && this.visible,
                    global: this.isGlobal,
                    component: !this.isGlobal
                };
            },

            ...mapState({
                loading: state => state.loader.elements
            })
        }
    };
</script>

