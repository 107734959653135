<template>

    <router-link :to="url" v-on:click.native="$emit('click')">
        <div class="search-item">
            <div class="search-item-icon">
                <i v-if="item.type === 'file'" class="mdi mdi-file" :class="itemClass(item.area_level)"></i>
                <i v-if="item.type === 'folder'" class="mdi mdi-folder" :class="itemClass(item.area_level)"></i>
                <i v-if="item.type === 'post'" class="mdi mdi-file-document-box" :class="itemClass(item.area_level)"></i>
            </div>
            <div class="search-item-info">
                <file-details :item="item"/>
            </div>
        </div>
    </router-link>

</template>

<script>
    import FileDetails from "@/components/FileDetails";

    export default {
        name: "SearchItem",
        components: {
            FileDetails
        },
        props: {
            item: {
                type: Object
            }
        },
        computed: {
            url(){
                if(this.item.type === "file"){
                    return {path: '/archive/' + this.item.parentSlug + '/' + this.item.slug, hash: this.item.slug};
                } else if (this.item.type === "folder"){
                    return {path: '/archive/' + this.item.slug}
                } else if(this.item.type === "post"){
                    return {path: '/post/' + this.item.slug};
                }

                return null;
            },
            itemClass(area) {
                return area => {
                    let area_level = parseInt(area);
                    if (area_level === 1) {
                        return "riks";
                    } else if (area_level === 2) {
                        return "forbund";
                    } else if (area_level === 3 || area_level === 4) {
                        return "kommun";
                    } else if(area_level === 0){
                        return "group";
                    }
                    return "";
                };
            },
        }
    }
</script>
