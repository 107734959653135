<template>
    <div class="agenda-wrapper" id="scroll">
        <template v-for="(date, index) in items">
            <div class="pale-header" :key="index">{{ date.dow }} - {{date.title}}</div>
            <template v-for="happening in date.happenings">
                <router-link :to="{ path: '/happening/' + happening.id }" :key="happening.id + 'happening'">
                    <HappeningItem :item="happening" :closest="closest === happening.id"/>
                </router-link>
            </template>
        </template>
    </div>
</template>

<script>
    import HappeningItem from "./HappeningItem";
    import { mapGetters } from "vuex";
    import moment from "moment";
    import VueCookies from "vue-cookies";

    export default {
        name: "Agenda",
        props: {
            scroll: {
                type: Boolean,
                default: false
            }
        },
        data(){
          return {
              closest: null
          }
        },
        components: {
            HappeningItem
        },
        computed: {
            ...mapGetters({
                happenings: "specialEvents/happenings",
                event: "specialEvents/specialEvent"
            }),

            items(){
                return this.happenings.filter(h => h.happenings.length > 0);
            },
        },
        watch: {
            'scroll'(){
                if(this.scroll === true){
                    this.scrollToHappening();
                }
            }
        },
        methods: {
            scrollToHappening(){
                // Determine closest event to today's date/time
                let closest = Infinity;
                for(let item of this.items){
                    for(let happening of item.happenings)
                        if (moment(happening.start) >= moment() && (moment(happening.start) < moment(closest) || moment(happening.start) < closest)) {
                            closest = happening;
                        } else if(moment(happening.end) >= moment() && (moment(happening.end) < moment(closest) || moment(happening.end) < closest)){
                            closest = happening;
                        }
                }
                if(closest.id){
                    this.closest = closest.id;

                    this.$nextTick(() => {
                        setTimeout(() => {
                            const el = document.getElementById('item' + closest.id);

                            let scrollAmount = window.scrollY;
                            let topPosition = this.offset(el).top;

                            const slideTimer = setInterval(function() {
                                window.scrollTo({top: scrollAmount});
                                scrollAmount += 100;
                                if (scrollAmount >= topPosition) {
                                    clearInterval(slideTimer);
                                }
                            }, 10);
                        }, 500);
                        // this.$router.push({hash: 'item' + closest.id });
                    })
                }
            },
            offset(el) {
                const rect = el.getBoundingClientRect(),
                    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
                    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
            }

        },
        mounted(){
            if(VueCookies.get("has_set_push_for_" + this.event.id)){
                this.scrollToHappening();
            }
        }
    }
</script>

<style scoped>

</style>
