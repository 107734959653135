<template>
    <div class="digital-voting-event">
        <div class="special-events-template">
            <div class="content-header">{{ event.title }}</div>
            <div class="content">{{ event.description }}</div>
        </div>

        <div class="active-votings" v-if="activeVotings.length">
            <div class="pale-header">Aktiva</div>

            <div
                v-for="voting in activeVotings"
                :key="voting.id"
                class="event-item"
                :class="{'has-voted': hasVoted(voting)}"
                @click="onVotingClick(voting)">
                <div class="event-icon">
                    <div class="event-icon-inner">
                        <div class="icon">
                            <svg v-if="!hasVoted(voting)" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1122 5.08176C12.009 4.6007 12.1442 4.0787 12.518 3.7049L14.7743 1.44862C15.3601 0.862833 16.3098 0.862829 16.8956 1.44862L24.7442 9.29717C25.33 9.88296 25.33 10.8327 24.7442 11.4185L22.4879 13.6748C22.1137 14.049 21.591 14.1841 21.1096 14.0803L18.962 16.2278L30.3079 27.5737C30.8936 28.1594 30.8936 29.1092 30.3079 29.695L29.6944 30.3084C29.1086 30.8942 28.1589 30.8942 27.5731 30.3084L16.2272 18.9625L14.0867 21.1029C14.1906 21.5845 14.0554 22.1072 13.6812 22.4815L11.4182 24.7444C10.8325 25.3302 9.88272 25.3302 9.29693 24.7444L1.44837 16.8959C0.862587 16.3101 0.862587 15.3603 1.44837 14.7746L3.71133 12.5116C4.08508 12.1379 4.60699 12.0026 5.088 12.1057L12.1122 5.08176ZM15.835 3.21638L22.9764 10.3578L21.4272 11.907C21.0618 11.5415 20.4981 11.4532 20.0717 11.7797C19.788 11.997 19.6502 12.34 19.6849 12.6765L12.7086 19.6525C12.3719 19.5973 12.0173 19.7166 11.7831 19.9932C11.4011 20.4443 11.5242 21.0316 11.9134 21.4208L10.3576 22.9767L3.21614 15.8352L4.77199 14.2794C5.1602 14.6676 5.74696 14.7882 6.19652 14.4085C6.47326 14.1748 6.59308 13.8205 6.53846 13.4837L13.5149 6.50753C13.8515 6.54255 14.1947 6.40508 14.4123 6.12148C14.7392 5.69533 14.6514 5.13115 14.2858 4.76556L15.835 3.21638Z"/>
                            </svg>
                            <svg v-else width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21.4067 11.7191C21.8948 12.2073 21.8948 12.9988 21.4067 13.4869L14.8849 20.0087C14.3968 20.4968 13.6053 20.4968 13.1171 20.0087L10.5085 17.4C10.0203 16.9118 10.0203 16.1203 10.5085 15.6322C10.9966 15.144 11.7881 15.144 12.2762 15.6322L14.001 17.357L19.6389 11.7191C20.127 11.231 20.9185 11.231 21.4067 11.7191Z"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.94824 25.9155C4.94824 26.4678 5.39596 26.9155 5.94824 26.9155H26.0517C26.604 26.9155 27.0517 26.4678 27.0517 25.9155V5.81206C27.0517 5.25978 26.604 4.81206 26.0517 4.81206H5.94824C5.39596 4.81206 4.94824 5.25978 4.94824 5.81206V25.9155ZM6.94824 24.9155V6.81206H25.0517V24.9155H6.94824Z"/>
                            </svg>
                        </div>
                    </div>
                </div>

                <div class="event-text">
                    <div class="event-content">
                        <div class="event-title">
                            {{ voting.title }}
                        </div>
                        <template v-if="!hasVoted(voting)">
                            <div class="event-address text-overflow" v-show="voting.description">
                                {{ voting.description }}
                            </div>
                        </template>
                        <template v-else>
                            <div class="event-address">
                                {{ voting.has_voted.length }} av {{ voting.has_access.length }} har röstat
                                <div class="progress">
                                    <div class="progress-bar" :style="{width: (voting.has_voted.length * (100 / voting.has_access.length)) + '%'}"></div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <div class="no-result" v-if="!activeVotings.length">
            Inga voteringar aktiva än. Så snart en votering startar kommer den att dyka upp här
        </div>

        <div class="active-votings" v-if="endedVotings.length">
            <div class="pale-header">Avslutade</div>

            <div v-for="voting in endedVotings" :key="voting.id" class="event-item inactive">
                <div class="event-icon">
                    <div class="event-icon-inner">
                        <div class="icon">
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21.4067 11.7191C21.8948 12.2073 21.8948 12.9988 21.4067 13.4869L14.8849 20.0087C14.3968 20.4968 13.6053 20.4968 13.1171 20.0087L10.5085 17.4C10.0203 16.9118 10.0203 16.1203 10.5085 15.6322C10.9966 15.144 11.7881 15.144 12.2762 15.6322L14.001 17.357L19.6389 11.7191C20.127 11.231 20.9185 11.231 21.4067 11.7191Z"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.94824 25.9155C4.94824 26.4678 5.39596 26.9155 5.94824 26.9155H26.0517C26.604 26.9155 27.0517 26.4678 27.0517 25.9155V5.81206C27.0517 5.25978 26.604 4.81206 26.0517 4.81206H5.94824C5.39596 4.81206 4.94824 5.25978 4.94824 5.81206V25.9155ZM6.94824 24.9155V6.81206H25.0517V24.9155H6.94824Z"/>
                            </svg>
                        </div>
                    </div>
                </div>

                <div class="event-text">
                    <div class="event-content">
                        <div class="event-title">
                            {{ voting.title }}
                        </div>
                        <div class="event-address text-overflow" v-show="voting.description">
                            {{ voting.description }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal ref="confirmRevoteModal" type="window" class-name="light-header" class="confirm-revote-modal">
            <template slot="header">Vill du ta bort din nuvarande röst?</template>
            <p slot="body">Du har redan en registrerad röst. Om du vill ändra din röst behöver vi ta bort den nuvarande rösten först. Är du säker?</p>
            <template slot="footer">
                <button type="button" class="warning" @click="onRevote">Ja</button>
                <button type="button" class="blue" @click="onCloseRevote">Nej</button>
            </template>
        </modal>

        <modal ref="errorModal" type="window" class-name="light-header">
            <template slot="header">Något gick fel</template>
            <p slot="body">Prova igen eller kontakta support.</p>
            <template slot="footer">
                <button type="button" class="blue" @click="closeErrorModal">Ok</button>
            </template>
        </modal>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import apiCall from '@/utils/api';
import Modal from '@/components/Modal';

export default {
    props: {
        event: Object
    },

    components: {
        Modal
    },

    data() {
        return {
            loadTimeout: null,
            loading: true,
            confirmingRevote: null,
            votings: []
        };
    },

    computed: {
        ...mapGetters({
            currentUser: 'user/userData'
        }),

        activeVotings() {
            return this.votings.filter(voting => Number(voting.status) === 10);
        },

        endedVotings() {
            return this.votings.filter(voting => Number(voting.status) === 20);
        }
    },

    async beforeMount()
    {
        clearTimeout(this.loadTimeout);

        this.showLoading('component');
        this.votings = await this.getVotings(this.event.uuid);
        this.hideLoading('component');

        this.queueRefetch(this.event.uuid);
    },

    beforeDestroy()
    {
        clearTimeout(this.loadTimeout);
    },

    methods: {
        ...mapActions({
            showLoading: 'loader/show',
            hideLoading: 'loader/hide'
        }),

        async getVotings(eventUuid) {
            this.loading = true;

            const votings = await apiCall(process.env.VUE_APP_API_DOMAIN + 'backend/content/uploads/digital_voting/' + eventUuid + '.json?cachebust=' + (new Date().getTime()), {
                direct_mode: true,
                withCredentials: false,
                method: 'GET',
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0'
                }
            }).catch(error => []);

            this.loading = false;
            return (votings && Array.isArray(votings) && votings.length) ? votings.filter(this.hasAccess) : [];
        },

        async queueRefetch(eventUuid) {
            if (!this.loading) {
                this.votings = await this.getVotings(eventUuid);
            }

            this.loadTimeout = setTimeout(() => {
                this.queueRefetch(eventUuid);
            }, 2000);
        },

        onVotingClick(voting) {
            if (Number(voting.status) !== 10) {
                return;
            }

            this.confirmingRevote = null;

            if (this.hasVoted(voting)) {
                this.confirmingRevote = voting;
                this.$refs.confirmRevoteModal.showModal();
            } else {
                this.goToVoting(voting);
            }
        },

        async onRevote() {
            this.showLoading('component');

            try {
                const response = await apiCall('/user/deleteDigitalVote', {
                    data: {
                        voting_id: this.confirmingRevote.id
                    }
                });

                if (!response || !response.success) {
                    throw Error('Unsucessful delete response');
                }

                this.goToVoting(this.confirmingRevote);
                this.onCloseRevote();
            } catch (error) {
                this.onCloseRevote();
                this.$refs.errorModal.showModal();
            }

            this.hideLoading('component');
        },

        onCloseRevote() {
            this.$refs.confirmRevoteModal.close();
            this.confirmingRevote = null;
        },

        goToVoting(voting) {
            this.$router.push({
                name: 'digitalvotingpage',
                params: {
                    id: voting.id,
                    eventId: this.event.id,
                    preloadedVoting: voting
                }
            });
        },

        closeErrorModal() {
            this.$refs.errorModal.close();
        },

        hasVoted(voting) {
            return (voting.hasOwnProperty('has_voted') && voting.has_voted.includes(this.currentUser.id));
        },

        hasAccess(voting) {
            return (voting.hasOwnProperty('has_access') && voting.has_access.includes(this.currentUser.id));
        }
    }
}
</script>

<style lang="scss">
    @import '../../scss/essentials/variables';

    .event-item {
        .icon svg {
            fill: $lib_blue;
        }

        &:hover {
            background-color: transparent;
        }

        &.has-voted {
            .icon svg {
                fill: $lib_green;
            }
        }

        &.inactive {
            .icon svg {
                fill: $lib_gray;
            }
        }
    }

    .text-overflow {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .progress {
        display: block;
        width: 100%;

        .progress-bar {
            display: block;
            background-color: $lib_green;
            height: 8px;
            border-radius: 5px;
        }
    }

    .no-result {
        text-align: center;
        padding: 20px;
        margin-bottom: 20px;
        color: $lib_darkgray;
    }

    .confirm-revote-modal {
        .modal {
            .modal_footer {
                justify-content: space-between;
            }
        }
    }
</style>
