<template>
    <div class="folder-tree">
        <div class="content-header pale">{{heading}}</div>
        <transition name="fade">
            <p v-if="error" class="error">
                {{error}}
            </p>
        </transition>
        <liquor-tree v-if="loaded" :data="filteredFolders" :options="treeOptions" @node:selected="validateFolder"/>
    </div>
</template>

<script>

    import LiquorTree from 'liquor-tree';
    import apiCall from "@/utils/api";
    import { mapActions } from "vuex";

    export default {
        props: {
            item: {
                type: Object,
                default: () => {}
            },
            newItem: {
                type: Boolean
            },
            type: {
                type: String
            },
            area: {
                type: String,
                default: null
            },
            currentFolder: {
                type: Object,
                default: () => {}
            },
        },
        data(){
            return {
                loaded: false,
                items: [],
                treeOptions: {
                    propertyNames: {
                        text: 'title',
                        children: 'folders'
                    },
                    parentSelect: true,
                },
                error: "",
                pluralName: this.type === "file" ? "filer" : "mappar",
                singularName: this.type === "file" ? "filen" : "mappen"
            }
        },
        components: {
            LiquorTree
        },
        computed: {
            filteredFolders(){
                let folders = this.items.map(f => {
                    f.data = {
                        event_folder: f.event_folder
                    };
                    return f;
                });
                if(this.area !== null && parseInt(this.area) !== 0){
                    folders = this.items.filter(item => item.area === this.area);
                } else {
                    folders = [...this.items];
                }

                let rootFolder = folders.filter(folder => {
                    return folder.parent === "0"
                });
                if(this.area === null || parseInt(this.area) === 0){
                    /**
                     * The rootfolder for groups is always "Dokument"
                     * */
                    rootFolder = rootFolder.filter(folder => {
                        return folder.title === "Dokument"
                    });
                }
                rootFolder = rootFolder[0];

                return {
                    id: rootFolder.id,
                    title: rootFolder.title,
                    folders: rootFolder.folders,
                    state: {
                        "expanded": true,
                        "selected": this.rootFolderSelected(rootFolder)
                    },
                    data: {
                        standard: rootFolder.standard
                    }
                };
            },

            heading(){
                if(this.newItem === true){
                    return "Lägg i";
                } else {
                    return "Flytta till";
                }
            },
            currentGroupID(){
                return this.$store.getters["user/currentGroupId"]
            }
        },
        methods: {
            ...mapActions({
                showLoading: "loader/show",
                hideLoading: "loader/hide"
            }),
            rootFolderSelected(rootFolder){
                if(this.currentFolder !== undefined){
                    return rootFolder.id === this.currentFolder.id;
                }
                return false;
            },
            getFolders(){
                this.showLoading("component");
                let group = null;
                if(this.currentGroupID){
                    group = this.currentGroupID.slice()
                } else if (!this.newItem && this.type === "file" && this.item.group && this.item.group.length > 0){
                    group = this.item.group.slice();
                }
                let data = {
                    id: 0,
                    param_group: group,
                };
                apiCall("item/getFolderTree", {data}).then(res => {
                    if(res.data !== null){

                        if(this.newItem === false){
                            /**
                             * Item is being moved to another folder, so set the "state" options for the folders
                             * */
                            this.items = this.setFolderOptions(res, this.item.id, this.item.parent);
                        } else {
                            /**
                             * Item is being created for the first time, only disable standard folders
                             * */
                            this.items = this.setFolderOptions(res);
                        }
                    }
                    this.loaded = true;
                    this.hideLoading("component");

                })
            },
            setFolderOptions(folders, id = null, parent_id = null){
                let sortedFolders = [];

                for(let folder of folders){
                    if(this.currentFolder !== undefined){
                        if(folder.id === this.currentFolder.id && this.currentFolder.id !== "0"){
                            folder.state = {
                                selected: true,
                            }
                        }
                        if(folder.id === this.currentFolder.parent){
                            folder.state = {
                                expanded: true
                            }
                        }
                    }
                    if(folder.standard === true){
                        folder.data = {
                            standard: true
                        }
                    }
                    if(id !== null && parent_id !== null){
                        /**
                         * Item is being moved to another folder, disable the folder itself and the parent folder
                         */
                        if(folder.id === id || folder.id === parent_id){
                            // folder.state = {
                            //     selectable: false,
                            //     disabled: true
                            // }
                        }
                    }
                    sortedFolders.push(folder);
                    if(folder.folders !== null){
                        /**
                         * Run function again if the current folder has children
                         */
                        if(id !== null && parent_id !== null) {
                            this.setFolderOptions(folder.folders, id, parent_id);
                        } else {
                            this.setFolderOptions(folder.folders);
                        }
                    }
                }
                return sortedFolders;
            },
            validateFolder(folder){
                if(folder.id === "Hem"){
                    this.error = "Välj en mapp att lägga " + this.singularName + " i";
                } else if(folder.data.standard === true){
                    this.error = "Du har inte behörighet att lägga " + this.pluralName + " i den här mappen";
                    this.folderSelect(folder)
                } else if(!this.newItem && ((folder.id === this.item.id && this.item.type === "folder") || (folder.id === this.item.parent))){
                    this.error = "Välj en mapp att lägga " + this.singularName + " i";
                } else {
                    this.error = "";
                    this.folderSelect(folder)
                }
            },
            folderSelect(folder){
                if(!this.newItem){
                    folder = folder.id
                }
                this.$emit('folderSelect', folder);
            },

        },
        created(){
            this.getFolders();
        },
        watch:{
            'area'(){
                /**
                 * Area has been changed, load folders again to only show specific area
                 */
                this.loaded = false;
                this.getFolders();
            }
        }
    }
</script>

<style type="scss">
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }

    .error{
        color: #EB5757;
    }
</style>
