<template>
    <div class="tags">
        <template v-for="tag in tags" >
            <div :key="tag.id">
                <span class="tag_name" @click="$emit('clicked', tag.name)">#{{tag.name}}</span>
            </div>
        </template>
    </div>
</template>

<script>

    export default {
        props: {
            tags: {
                type: Array
            }
        }
    }
</script>
