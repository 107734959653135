<template>
    <div class="event-attend" v-if="event">
        <div class="event_tools row no-gutter" v-if="event.attend === 0">
            <a href="javascript:void(0)" v-on:click="setAttend(1)" class="column button choice">
                Tacka ja <i class="mdi mdi-check green"></i>
            </a>
            <a href="javascript:void(0)" v-on:click="setAttend(2)" class="column button choice">
                Tacka nej <i class="mdi mdi-close red"></i>
            </a>
        </div>

        <div class="event_tools row no-gutter" v-if="event.attend" v-on:click="setAttend(3)">
            <a v-if="event.attend === 1" class="column background-green button">
                Du har tackat JA till detta event <p>(Klicka för att ångra)</p>
            </a>
            <a v-if="event.attend === 2" class="column background-red button">
                Du har tackat NEJ till detta event <p>(Klicka för att ångra)</p>
            </a>
        </div>

        <div class="event_tools row no-gutter">
            <div class="column attendence">
                {{ event.attend_total }} personer har tackat ja till detta event
            </div>
        </div>

        <div class="event_tools row no-gutter" v-if="event.attendees && event.attendees.length > 0">
            <div class="column attendences">
                <table>
                    <tr v-for="(attendee, index) in attendees" :key="'attendees' + index">
                        <td>
                            {{ attendee.name }}
                        </td>
                        <td>
                            {{ (attendee.attend === "1" ? "Ja" : "Nej") }}
                        </td>
                    </tr>
                    <template v-if="showMore && attendeesPaginated.length > 0">
                        <tr v-for="attendee in attendeesPaginated" :key="attendee.id">
                            <td>
                                {{ attendee.name }}
                            </td>
                            <td>
                                {{ (attendee.attend === "1" ? "Ja" : "Nej") }}
                            </td>
                        </tr>
                    </template>

                </table>
                <div class="show-more-attendees" v-if="attendeesPaginated.length > 0 && !showMore" @click="showMore = true">
                    Visa fler
                </div>
            </div>

        </div>

    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "EventAttend",
        data(){
            return {
                showMore: false,
            }
        },

        props: {
            eventID: {
                type: String
            }
        },
        computed : {
            ...mapGetters({
                events: 'items/events'
            }),
            event(){
                return this.events.filter(e => e.id === this.eventID)[0];
            },
            attendees(){
                if(this.event.attendees.length < 8){
                    return this.event.attendees;
                } else {
                    return this.event.attendees.slice(0, 8);
                }
            },
            attendeesPaginated(){
                return this.event.attendees.slice(8);
            }
        },
        methods: {
            setAttend(answer) {
                let data = {
                    item_id: this.event.id,
                    answer: answer,
                    type: "event",
                    item: this.event
                };
                this.$store.dispatch("items/setAttend", [data]).then(() => {

                });
            },
        }
    }
</script>

<style scoped>

</style>
