<template>
    <div>
        <div class="images">
            <div class="main-image image-instance" @click.prevent="show('main')">
                 <img :src="mainImage.url + '_medium'" />
            </div>
            <template v-if="secondaryImages.length > 0">
                <div class="collage image-instance">
                    <div class="image" v-for="(thumbnail, index) in secondaryImages" :key="thumbnail.id + 't'" @click.prevent="show(index)">
                        <img class="w-64" :src="thumbnail.url">

                        <template v-if="images.length > 5">
                            <div v-if="index === secondaryImages.length - 1" class="image-counter" @click="show(3)">
                                +{{ imageCount }}
                            </div>
                        </template>
                    </div>
                </div>
            </template>
        </div>

        <transition name="lightbox">
            <div class="lightbox" v-if="visible" @click="hide" :class="isSlidingToPrevious ? ' sliding-to-previous' : ''">
                <div class="close-button" @click.stop="hide">&times;</div>
                    <div @click.stop="prev" class="lb-nav" :class="{'invisible': ! hasPrev}">
                        <svg class="pointer-events-none" fill="#fff" height="48" viewBox="0 0 24 24" width="48" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"/>
                            <path d="M0-.5h24v24H0z" fill="none"/>
                        </svg>
                    </div>
                    <transition-group tag="div" class="lightbox-images" name="lightbox-images">
                        <template v-for="(image, index) in images">
                            <div :key="index + 'li'" v-if="current === index" class="lightbox-image" @click.stop="" v-touch:swipe.left="next" v-touch:swipe.right="prev">
                                <img :src="image.url">
                            </div>
                        </template>
                    </transition-group>
                    <div @click.stop="next" class="lb-nav" :class="{'invisible': ! hasNext}">
                        <svg class="pointer-events-none" fill="#fff" height="48" viewBox="0 0 24 24" width="48" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"/>
                            <path d="M0-.25h24v24H0z" fill="none"/>
                        </svg>
                    </div>
            </div>
        </transition>

    </div>
</template>

<script>
    export default {
        name: "Lightbox",
        props: {
            images: {
                type: Array,
                default: () => [],
            }
        },
        data() {
            return {
                visible: false,
                current: 0,
                isSlidingToPrevious: false
            };
        },
        computed: {
            hasNext() {
                return this.current + 1 < this.images.length;
            },
            hasPrev() {
                return this.current - 1 >= 0;
            },
            mainImage(){
              return this.itemImages[0];
            },
            secondaryImages(){
                return this.itemImages.slice(1)
            },
            itemImages(){
                if(this.images.length > 5) {
                    return this.images.slice(0,5);
                }
                return this.images
            },
            imageCount(){
                return this.images.length - 5;
            }
        },
        methods: {
            show(index) {
                if(index !== "main"){
                    index = index + 1;
                } else {
                    index = 0;
                }
                this.visible = true;
                this.current = index;
            },
            hide() {
                this.visible = false;
                this.current = 0;
            },
            prev() {
                if (this.hasPrev) {
                    this.current -= 1;
                }
                this.isSlidingToPrevious = true;
            },
            next() {
                if (this.hasNext) {
                    this.current += 1;
                }
                this.isSlidingToPrevious = false;
            },
        }
    }
</script>
