<template>
    <div class="confirm-gdpr">
        <h4>LIBERALERNAS PERSONPOLICY</h4>
        <p>
            Liberalernas medlemssystem är till för att hålla partiets medlemsregister men också fungera som partiets interna kommunikationsverktyg. Det finns grundlagsskydd för politiskt åsiktsfrihet i Sverige vilket innebär att de uppgifter som finns i vårt medlemsregister ska hanteras med mycket stor försiktighet och får inte röjas. Läs mer om hur vi arbetar med <a href="https://www.liberalerna.se/personuppgifter-och-integritet/" target="_blank">personuppgifter här. <i class="fa fa-external-link"></i></a>
        </p>
        <h4>DITT ANSVAR</h4>
        <p>
            Du är ansvarig för att ha genomgått <a href="https://goo.gl/forms/kNpA1wyINoYCRON93" target="_blank" id="education_link">denna utbildning <i class="fa fa-external-link"></i></a> innan du börjar arbeta i Liberalernas medlemsregister och medlemsapp. Det tar ungefär 10 minuter.
            Du ska även känna till och agera i enlighet med <a href="https://www.liberalerna.se/wp-content/uploads/stadgar.pdf" target="_blank">Liberalernas etiska riktlinjer <i class="fa fa-external-link"></i></a> (sid 15).
            Vårt register ska vara korrekt och uppdaterat och därför har du som administratör en skyldighet att rätta sådana uppgifter som är felaktiga eller daterade.
            Allting som alla administratörer gör i medlemsregistret och medlemsappen loggas för att i efterhand kunna följas upp. Vid felaktigt användande av medlemsregistret, medlemsappen eller information som finns däri kommer ditt konto att stängas av och eventuella ytterligare åtgärder att vidtas. Brottsliga handlingar polisanmäls alltid.
        </p>
        <button class="button blue" @click="confirmGdpr"><i class="mdi mdi-check" ></i> Jag godkänner</button>
        <button class="button white" @click="doLogout"><i class="mdi mdi-close-circle"></i> Logga ut</button>
    </div>
</template>

<script>

    import { mapActions } from 'vuex';
    import apiCall from "@/utils/api"

    export default {
        name: "ConfirmGdpr",
        methods: {
            confirmGdpr(){
                this.$store.dispatch("user/setConfirmingGdpr", false);
                apiCall("/gdprconfirm/confirm").then(res => {
                    this.$router.push('/');
                })
            },
            doLogout() {
                this.logoutAction();
            },
            ...mapActions({
                loginAction: 'user/loginUser',
                logoutAction: 'user/logoutUser',
            })
        }
    }
</script>

<style scoped>

</style>
