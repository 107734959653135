<template>
    <div class="event-item" :class="[itemClass]" v-if="event">
        <span class="badge" v-if="event.attend === 0 && parseInt(event.allow_attend) !== 0 && event.status === 'published'">!</span>

        <!--<div class="wrapper">-->
            <!--<div class="event-icon">-->
            <div class="event-icon" v-if="event.main_image === null || event.main_image === undefined">
                <div class="event-icon-inner">
                    <div class="icon" v-if="event.attend === 2 || event.attend === 0">
                        <svg viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg" :class="eventItemType" >
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.91311 0V2.26094H19.2175V0H21.4783V2.26094H23.7391C25.6121 2.26094 27.1304 3.77928 27.1304 5.65224V7.91297V10.1738V22.6088C27.1304 24.4817 25.6121 26.0001 23.7391 26.0001H3.3913C1.51834 26.0001 0 24.4817 0 22.6088V10.1738V7.91297V5.65224C0 3.77928 1.51834 2.26094 3.3913 2.26094H5.65224V0H7.91311ZM19.2175 4.52181V5.65217H21.4783V4.52181H23.7391C24.3635 4.52181 24.8696 5.02792 24.8696 5.65224V7.91297H2.26087V5.65224C2.26087 5.02792 2.76698 4.52181 3.3913 4.52181H5.65224V5.65217H7.91311V4.52181H19.2175ZM24.8696 10.1738V22.6088C24.8696 23.2331 24.3635 23.7392 23.7391 23.7392H3.3913C2.76698 23.7392 2.26087 23.2331 2.26087 22.6088V10.1738H24.8696Z" />
                        </svg>
                    </div>
                    <div class="icon" v-if="event.attend === 1">
                        <svg viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg" :class="eventItemType" >
                            <path d="M19.6857 13.5651L12.4348 20.816L8.57527 16.9564L10.1739 15.3577L12.4348 17.6186L18.087 11.9664L19.6857 13.5651Z"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.91304 2.26087V0H5.65217V2.26087H3.3913C1.51834 2.26087 0 3.77921 0 5.65217V22.6087C0 24.4817 1.51834 26 3.3913 26H23.7391C25.6121 26 27.1304 24.4817 27.1304 22.6087V5.65217C27.1304 3.77921 25.6121 2.26087 23.7391 2.26087H21.4783V0H19.2174V2.26087H7.91304ZM24.8696 7.91304V5.65217C24.8696 5.02785 24.3635 4.52174 23.7391 4.52174H21.4783V5.65217H19.2174V4.52174H7.91304V5.65217H5.65217V4.52174H3.3913C2.76698 4.52174 2.26087 5.02785 2.26087 5.65217V7.91304H24.8696ZM2.26087 10.1739V22.6087C2.26087 23.233 2.76698 23.7391 3.3913 23.7391H23.7391C24.3635 23.7391 24.8696 23.233 24.8696 22.6087V10.1739H2.26087Z"/>
                        </svg>
                    </div>
                </div>
            </div>

            <div v-else class="event-image">
                <img :src="event.main_image.url" />
            </div>
            <div class="event-text">
                <div class="event-content">
                    <!--<div class="event-icon-inner-small" v-if="postEvent">-->
                        <!--<div class="icon" v-if="event.attend === 2 || event.attend === 0">-->
                            <!--<svg viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg" :class="eventItemType" >-->
                                <!--<path fill-rule="evenodd" clip-rule="evenodd" d="M7.91311 0V2.26094H19.2175V0H21.4783V2.26094H23.7391C25.6121 2.26094 27.1304 3.77928 27.1304 5.65224V7.91297V10.1738V22.6088C27.1304 24.4817 25.6121 26.0001 23.7391 26.0001H3.3913C1.51834 26.0001 0 24.4817 0 22.6088V10.1738V7.91297V5.65224C0 3.77928 1.51834 2.26094 3.3913 2.26094H5.65224V0H7.91311ZM19.2175 4.52181V5.65217H21.4783V4.52181H23.7391C24.3635 4.52181 24.8696 5.02792 24.8696 5.65224V7.91297H2.26087V5.65224C2.26087 5.02792 2.76698 4.52181 3.3913 4.52181H5.65224V5.65217H7.91311V4.52181H19.2175ZM24.8696 10.1738V22.6088C24.8696 23.2331 24.3635 23.7392 23.7391 23.7392H3.3913C2.76698 23.7392 2.26087 23.2331 2.26087 22.6088V10.1738H24.8696Z" />-->
                            <!--</svg>-->
                        <!--</div>-->
                        <!--<div class="icon" v-if="event.attend === 1">-->
                            <!--<svg viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg" :class="eventItemType" >-->
                                <!--<path d="M19.6857 13.5651L12.4348 20.816L8.57527 16.9564L10.1739 15.3577L12.4348 17.6186L18.087 11.9664L19.6857 13.5651Z"/>-->
                                <!--<path fill-rule="evenodd" clip-rule="evenodd" d="M7.91304 2.26087V0H5.65217V2.26087H3.3913C1.51834 2.26087 0 3.77921 0 5.65217V22.6087C0 24.4817 1.51834 26 3.3913 26H23.7391C25.6121 26 27.1304 24.4817 27.1304 22.6087V5.65217C27.1304 3.77921 25.6121 2.26087 23.7391 2.26087H21.4783V0H19.2174V2.26087H7.91304ZM24.8696 7.91304V5.65217C24.8696 5.02785 24.3635 4.52174 23.7391 4.52174H21.4783V5.65217H19.2174V4.52174H7.91304V5.65217H5.65217V4.52174H3.3913C2.76698 4.52174 2.26087 5.02785 2.26087 5.65217V7.91304H24.8696ZM2.26087 10.1739V22.6087C2.26087 23.233 2.76698 23.7391 3.3913 23.7391H23.7391C24.3635 23.7391 24.8696 23.233 24.8696 22.6087V10.1739H2.26087Z"/>-->
                            <!--</svg>-->
                        <!--</div>-->
                    <!--</div>-->
                    <div class="event-title"> {{event.title}} <span v-if="event.status === 'draft'">(Ej publicerat)</span></div>
                    <div class="type" v-if="!postEvent">{{ (event.event_type === 'meeting' ? 'Möte' : 'Kampanj' ) }}</div>
                    <event-dates :event="event" />
                    <div class="event-address" v-if="postEvent && event.location_address">{{event.location_address}}</div>
                </div>
            </div>
        </div>
    <!--</div>-->
</template>


<script>
    import EventDates from "@/components/EventDates";
    import {mapGetters} from "vuex";

    export default {
        props: {
            eventID: {
                type: String
            },
            postEvent: {
                type: Boolean,
                default: false
            },
                searchItem: {
                type: Boolean,
                default: false
            }
        },
        components: {
          EventDates
        },

        computed: {
            ...mapGetters({
                events: 'items/events'
            }),
            isDraft() {
                return this.event.status === 'draft';
            },
            event(){
                return this.events.filter(e => e.id === this.eventID)[0];
            },
          eventItemType() {
              let area_level = parseInt(this.event.area_level);
              if (area_level === 1) {
                  return "riks";
              } else if (area_level === 2) {
                  return "forbund";
              } else if (area_level === 3 || area_level === 4) {
                  return "kommun";
              } else if (area_level === 0){
                  return "group";
              }
              return "";
          },
            itemClass(){
                let classes = [];
                // let area_level = parseInt(this.item.area_level);
                // if(area_level === 1) {
                //     class_string = "riks";
                // } else if(area_level === 2) {
                //     class_string = "forbund";
                // } else if(area_level === 3 || area_level === 4) {
                //     class_string = "kommun";
                // } else if(area_level === 0){
                //     class_string = "group";
                // }
                if(this.searchItem) {
                    classes.push("search");
                }
                if(this.isDraft) {
                    classes.push("draft");
                }
                return classes.join(" ");
            },
        },

        filters: {
            // formatDate: function(value) {
            //     if (value) {
            //         return moment(String(value)).locale('sv').format('D MMMM HH:mm')
            //     }
            // }
        },
    }
</script>
