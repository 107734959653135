<template>
    <transition name="modal">
        <div :class="'modal-holder' + ' ' + type" v-show="open">
                <div class="modal" :class="className">
                    <div class="modal_header">
                        <slot name="header"></slot>
                        <div class="closer" v-if="showClose">
                            <div v-on:click="close()"><i class="mdi mdi-close"></i></div>
                        </div>
                    </div>
                    <div class="modal_body">
<!--                        <div class="indicator" v-show="showIndicator"></div>-->
                        <div class="modal_content" id="modalContent">
                            <slot name="body"></slot>
                        </div>
                    </div>
                    <div class="modal_footer">
                        <slot name="footer"></slot>
                    </div>
                </div>
                <!--<div class="modal-overlay" id="modal-overlay"></div>-->
        </div>
    </transition>
</template>

<script>

    export default {
        name: "Modal",

        props: {
            title: {
                type: String,
                default: ""
            },
            type: {
                type: String,
                default: ""
            },
            className: {
                type: String,
                default: ""
            },
            showIndicator: {
                type: Boolean,
                default: false
            },
            showClose: {
                type: Boolean,
                default: true
            }
        },
        data: function() {
            return {
                open: false,
            }
        },
        methods: {
            toggleModal() {
                this.open = !this.open;
            },
            showModal() {
                this.open = true;
                this.$emit('opened');
            },
            close() {
                this.open = false;
                this.$emit('closed');
            }
        }
    }
</script>
