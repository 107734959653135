<template>
    <div class="special-events-template">
<!--        <template slot="header">-->
            <div class="content-header orange">{{ title }}</div>
            <div class="content-header">{{ page.header }}</div>
<!--        </template>-->
<!--        <template slot="body">-->
            <div class="content" v-html="page.description"></div>
<!--        </template>-->
        <template v-if="files.length > 0">
            <div class="files" :class="{single: files.length === 1}">
                <div class="pale-header">Bifogade filer</div>
                <template v-for="file in files">
                    <a :href="file.url" :key="file.id">
                        <FileItem :key="file.id" :item="file" :type="'file'" specialEventFile/>
                    </a>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
    import FileItem from "@/components/Fileitem";
    export default {
        name: "EventTemplate",
        props: {
            page: {
                type: Object
            }
        },
        data(){
            return {
                baseUrl: process.env.VUE_APP_API_DOMAIN+"backend/api/v1/controller/specialevent/file?type=page&id=",
            }
        },
        components: {
            FileItem
        },
        computed: {
            files(){
                return this.$store.getters["specialEvents/files"](this.page.id, "page").map(file => {
                    file.url = this.baseUrl + file.id;
                    return file;
                });
            },
            title(){
                return this.$store.getters["specialEvents/title"];
            }
        }
    }
</script>
