<template>
    <div class="archive gutter">
        <Archive/>
    </div>
</template>

<script>
    import Archive from '@/components/Archive.vue'

    export default {
        name: 'archive',
        components: {
            Archive
        }
    }
</script>
