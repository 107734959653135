<template>
    <div class="home gutter" id="home">
        <div class="banner-wrapper">
            <flag-banner/>
            <assembly-voting-banner @bannerClicked="showAssemblyVotingModal"/>
            <digital-voting-banner/>
        </div>
        <list type="post"/>
        <Modal ref="assemblyVotingModal" type="window" class="assembly-modal" class-name="light-header">
          <template slot="header">
            <div class="header">
              Provval finns tillgängligt
            </div>
          </template>
          <template slot="body">
            <p class="assembly-modal-body">Just nu pågår ett provval som du kan rösta i. Se mejl för mer info eller kontakta Liberalerna.</p>
          </template>
        </Modal>
        <modal v-if="firstActiveEvent" ref="digitalVotingModal" type="window" class="digital-voting-modal" class-name="light-header">
            <template slot="header">
                <div class="icon-wrapper">
                    <img src="@/assets/digital-voting-icon.svg" alt="">
                </div>
                <div class="header-content">
                    <div class="title">Möte aktivt</div>
                    <div class="sub-title">{{ firstActiveEvent.title }}</div>
                </div>
            </template>
            <template slot="body">
                <div class="content-header">{{ firstActiveEvent.title }}</div>
                <p>{{ firstActiveEvent.description }}</p>
            </template>
            <template slot="footer">
                <button type="button" class="blue" @click="goToDigitalVotingEvent(firstActiveEvent.id)">Gå till möte</button>
            </template>
        </modal>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    import List from '@/components/List';
    import FlagBanner from "@/components/specialEvents/FlagBanner";
    import DigitalVotingBanner from '@/components/digitalVoting/DigitalVotingBanner';
    import AssemblyVotingBanner from '@/components/AssemblyVotingBanner';
    import Modal from '@/components/Modal';

    export default {
        name: 'home',
        components: {
            list: List,
            FlagBanner,
            AssemblyVotingBanner,
            DigitalVotingBanner,
            Modal
        },
        computed: {
          ...mapGetters({
            digitalVotingEventIsHappening: 'digitalVoting/eventIsHappening',
            digitalVotingEvents: 'digitalVoting/events'
          }),

          firstActiveEvent() {
            if (this.digitalVotingEventIsHappening && this.digitalVotingEvents && this.digitalVotingEvents.length) {
              return this.digitalVotingEvents[0];
            }

            return null;
          }
        },

        watch: {
          digitalVotingEventIsHappening: function(val) {
            if (val) {
              this.$nextTick(() => {
                this.$refs.digitalVotingModal.showModal();
              });
            }
          }
        },
        methods: {
            showAssemblyVotingModal() {
                this.$refs.assemblyVotingModal.showModal();
            },
            goToDigitalVotingEvent(eventId) {
              this.$router.push({
                name: 'digitalvotingspage',
                params: {
                  id: eventId
                }
              })
            }
        }
    }
</script>

<style lang="scss">
@import '../scss/essentials/variables';

.digital-voting-modal {
    .modal_header {
        display: flex;

        .icon-wrapper {
            height: 40px;
            border-radius: 20px;
            background-color: $lib_green;
            display: inline-block;
            margin-right: 10px;
        }

        .sub-title {
            font-weight: normal;
        }
    }

    .modal_body {
        padding-top: 15px;
    }
}

.assembly-modal {
    .assembly-modal-body {
        margin-bottom: 10px;
    }

    .modal_footer {
        display: none;
    }
}
</style>
