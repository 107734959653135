import Vue from 'vue';
import Vuex from 'vuex';

import user from './modules/user';
import loader from './modules/loader';
import items from './modules/items';
import specialEvents from './modules/specialEvents';
import assemblyVoting from './modules/assemblyVoting';
import digitalVoting from './modules/digitalVoting';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        user,
        loader,
        items,
        specialEvents,
        digitalVoting,
        assemblyVoting
    },
    //plugins: [vuexLocal.plugin]
});

export default store;
