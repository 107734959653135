<template>
    <div class="my_page">
        <h1 class="headline blue">Min sida</h1>
        <UserData />
        <h5>Vill du avsluta ditt medlemskap mejlar du till <a href="mailto:support@liberalerna.se">support@liberalerna.se</a></h5>
        <!--<h3 class="sub-head" v-on:click="showAreas = !showAreas">-->
            <!--Kommunikation-->
            <!--<i :class="(showAreas) ? 'mdi mdi-minus' : 'mdi mdi-plus'"></i>-->
        <!--</h3>-->
        <!--<div v-if="showAreas">-->

            <!--<areas  />-->
        <!--</div>-->

        <Loader type="component" />
    </div>
</template>
<script>
    import UserData from '@/components/UserData.vue'
    import Loader from "../components/Loader"

    export default {
        data: function() {
            return {
                devices: [],
                showAreas: false
            }
        },
        name: 'MyPage',
        components: {
            Loader,
            UserData,
        },

    }
</script>
