<template>
    <div class="login">
        <div v-if="!user_data.logged_in" class="login-view">
            <div class="image_wrapper">
                <img src="@/assets/Liberalerna.svg" title="Liberalerna" />
            </div>
            <div class="login_form_wrapper">
                <template v-if="mode === 'login'">
                    <form>
                        <div class="smart-field">
                            <input type="text" name="username" id="username" placeholder="Mejl/medlemsnr/personnr" v-model="username" v-on:keyup.enter="doLogin"  />
                            <label for="username">Användarnamn</label>
                        </div>
                        <div class="smart-field">
                            <input type="password" name="password" id="password" placeholder="Ditt lösenord" v-model="password" v-on:keyup.enter="doLogin" />
                            <label for="password">Lösenord</label>
                        </div>
                        <button class="blue" v-on:click.prevent="doLogin" :disabled="waiting">
                            <span v-if="!waiting">Logga in</span>
                            <span v-else>...</span>
                        </button>
                        <br/>
                        <a v-on:click="mode = 'start_forgot'">Glömt lösenordet?</a> <br/>
                    </form>
                </template>


                <form v-if="mode === 'start_forgot'">
                    <h2>Glömt lösenordet?</h2>
                    <p>Ingen fara, fyll i din mejl nedan så byter vi</p>
                    <div class="smart-field">
                        <input type="text" name="forgot_id" autocomplete="new-password" id="forgot_id" placeholder="Mejl/medlemsnr/personnummer" v-model="username" v-on:keyup.enter="doStartForgotPassword"  />
                        <label for="forgot_id">Medlemsnummer/mejl/personnummer</label>
                    </div>
                    <div class="button-toolbar">
                        <button class="blue" v-on:click.prevent="doStartForgotPassword" :disabled="waiting">
                            <span v-if="!waiting">Byt lösenord</span>
                            <span v-else>...</span>
                        </button>

                        <button v-on:click.prevent="mode = 'change_password'">Jag har en kod</button>

                        <button v-on:click.prevent="mode = 'login'">Avbryt</button>
                    </div>
                </form>

                <form v-if="mode === 'change_password'">
                    <h2>Byt lösenord</h2>
                    <p>Ange din kod för att byta lösenord.</p>
                    <div class="smart-field">
                        <input type="text" name="forgot_code" id="forgot_code" placeholder="Aktiveringskod" v-model="forgot_code" v-on:keyup.enter="doValidateForgotCode"  />
                        <label for="forgot_code">Kod för att byta lösenord</label>
                    </div>

                    <div class="smart-field">
                        <input type="text" name="forgot_activate_id" id="forgot_activate_id" placeholder="Mejl/medlemsnr/personnr" v-model="username" v-on:keyup.enter="doValidateForgotCode"  />
                        <label for="forgot_activate_id">Mejl/Medlemsnr/Personnr</label>
                    </div>

                    <div class="smart-field">
                        <input type="password" name="forgot_activation_password" id="forgot_activation_password" placeholder="Välj lösenord" v-model="forgot_activation_password" />
                        <label for="forgot_activation_password">Nytt lösenord</label>
                    </div>

                    <div class="smart-field">
                        <input type="password" name="forgot_activation_password_confirm" id="forgot_activation_password_confirm" placeholder="Verifiera lösenord" v-model="forgot_activation_password_confirm" />
                        <label for="forgot_activation_password_confirm">Verifiera ditt nya lösenord</label>
                    </div>

                    <div class="row">
                        <div class="col">
                            <button class="blue" v-on:click.prevent="doValidateForgotCode" :disabled="waiting">
                                <span v-if="!waiting">Byt lösenord</span>
                                <span v-else>...</span>
                            </button>
                        </div>
                        <div class="col">
                            <button v-on:click.prevent="abortForgot()">Avbryt</button>
                        </div>
                    </div>
                </form>

                <form v-if="mode === 'activate'" class="activation-form">
                    <h2>Välkommen!</h2>
                    <p>Är det första gången du loggar in så behöver du aktivera ditt konto först. Det gör du genom att skriva in ditt medlemsnummer/ mejl/personnummer nedan.
                        Du kommer då få en aktiveringskod till din mejl som du kan använda för att aktivera dig och skapa ditt lösenord.
                        Hittar du inget mejl med aktiveringskoden så kan det hamnat i skräpposten så dubbelkolla där innan du kontaktar oss.</p>
                    <div class="smart-field">
                        <input type="text" name="activate_id" autocomplete="new-password" id="activate_id" placeholder="Medlemsnummer/mejl/personnummer" v-model="username" v-on:keyup.enter="doActivate"  />
                        <label for="activate_id">Medlemsnummer/mejl/personnummer</label>
                    </div>

                    <div class="button-toolbar">

                            <button class="blue" v-on:click.prevent="doActivate" :disabled="waiting">
                                <span v-if="!waiting">Hämta aktiveringskod</span>
                                <span v-else>...</span>
                            </button>

                            <button v-on:click.prevent="mode = 'activate_code'">Jag har redan kod</button>

                            <button v-on:click.prevent="mode = 'login'">Avbryt</button>

                    </div>
                </form>

                <form v-if="mode === 'activate_code'">
                    <h2>Ange din aktiveringskod</h2>
                    <p>Ange din aktiveringskod nedan.</p>
                    <div class="smart-field">
                        <input type="text" name="activation_code" id="activation_code" placeholder="Aktiveringskod" v-model="activation_code" v-on:keyup.enter="doValidateActivationCode"  />
                        <label for="activation_code">Aktiveringskod</label>
                    </div>

                    <div class="smart-field">
                        <input type="text" name="activate_id" id="activate_id" placeholder="Mejl/medlemsnummer/personnummer" v-model="username" v-on:keyup.enter="doValidateActivationCode"  />
                        <label for="activate_id">Medlemsnummer/mejl/personnummer</label>
                    </div>

                    <div class="smart-field">
                        <input type="password" name="activation_password" id="activation_password" placeholder="Välj lösenord" v-model="activation_password" />
                        <label for="activation_password">Lösenord</label>
                    </div>

                    <div class="smart-field">
                        <input type="password" name="activation_password_confirm" id="activation_password_confirm" placeholder="Verifiera lösenord" v-model="activation_password_confirm" />
                        <label for="activation_password_confirm">Verifiera ditt lösenord</label>
                    </div>

                    <div class="row">
                        <div class="col">
                            <button class="blue" v-on:click.prevent="doValidateActivationCode" :disabled="waiting">
                                <span v-if="!waiting">Aktivera mitt konto</span>
                                <span v-else>...</span>
                            </button>
                        </div>
                        <div class="col">
                            <button v-on:click.prevent="mode = 'login'">Avbryt</button>
                        </div>
                    </div>
                </form>

            </div>

            <div class="login_form_footer gray" v-if="mode !== 'activate_code' && mode !== 'activate' && mode !== 'change_password' && mode !== 'start_forgot'">
                <div class="inner_content">
                    <p>Första gången du är här?</p>
                    <a class="purple btn" href="#" v-on:click="mode = 'activate'">Aktivera ditt medlemskonto</a>
                </div>
            </div>
            <div class="login_form_footer" v-if="mode !== 'activate_code' && mode !== 'activate' && mode !== 'change_password' && mode !== 'start_forgot'">
                <div class="inner_content">
                    <p>Inte medlem i Liberalerna?</p>
                    <router-link class="orange btn" to="/register">Bli medlem nu</router-link>
                </div>
            </div>
        </div>

<!--        <div v-if="user_data.logged_in && (hasConfirmed || !isAdmin)">-->
<!--            <div class="logged_in">-->
<!--                <div class="intro">-->
<!--                    <div class="image-wrapper">-->
<!--                        <img :src="getAvatarUrl" />-->
<!--                    </div>-->
<!--                    <div class="headline blue">-->
<!--                        Välkommen, {{ getUserData.first_name }} {{ getUserData.middle_name }} {{ getUserData.last_name }}-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="content">-->
<!--                    <div class="content-header">Välkommen till Liberalernas medlemsapp.</div>-->
<!--                    <p>Här hittar du allt du behöver veta om politik, möten och kampanj i Liberalerna.</p>-->
<!--                    <p>Du bestämmer själv om du vill ha mejl, pushnotiser eller enbart information i appen.</p>-->
<!--                    <p>Ändra inställningarna för din kommunikation på <strong>Min sida</strong>, som du hittar under <strong>Mer</strong> längst ner till höger.</p>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</template>
<script>

    import { mapActions, mapState } from 'vuex';
    import apiCall from "@/utils/api";
    import { DetectIosApp } from '@/mixins/DetectIosApp';

    export default {
        name: 'Login',
        data() {
            return {
                baseUrl: process.env.VUE_APP_API_DOMAIN+"backend/",
                waiting: false,
                activation_code: this.$route.params.activation_key === "logout" ? "" : this.$route.params.activation_key,
                forgot_code: this.$route.query.forgot_key,
                mode: "login",
                username: "", // erik@westart.se
                password: "", // waerik
                activation_password: "",
                activation_password_confirm: "",
                forgot_activation_password: "",
                forgot_activation_password_confirm: "",
                hasConfirmed: false
            }
        },
        mixins: [
            DetectIosApp
        ],
        directives: {
            focus: {
                inserted: function (el) {
                    el.focus()
                }
            }
        },
        computed: {
            getUserData(){
                let udata = this.$store.getters['user/userData'];
                return udata;
            },
            getAvatarUrl() {
                return this.baseUrl + 'avatar/id/' + this.$store.getters['user/userData'].id;
            },
            isAdmin(){
                return this.$store.getters["user/isAdmin"];
            },
            ...mapState({
                user_data: state => state.user
            })
        },
        mounted() {

            if(this.user_data.logged_in){
                this.$router.push("/");
            }

            if(this.activation_code !== "" && this.activation_code !== undefined && this.activation_code !== "logout" && !isNaN(this.activation_code)){
                this.mode = "activate_code";
            } else if(this.forgot_code !== "" && this.forgot_code !== undefined) {
                this.mode = "change_password";
            } else if(this.$route.params.activation_key === 'activate') {
                this.mode = "activate";
            }
        },
        methods: {
            doActivate() {
                this.waiting = true;
                apiCall('/user/activate', {
                    data: {
                        username: this.username,
                    }
                }).then(res => {
                    this.waiting = false;
                    if(res.activation_sent) {
                        this.$notify({
                            type: 'success',
                            group: 'general',
                            title: 'Du har snart mejl..',
                            text: 'Vi har nu skickat ett mejl med din aktiveringskod i. Fyll i koden nedan och önskat lösenord så är du snart igång!',
                            duration: 10000,
                            speed: 1000
                        });
                        this.mode = 'activate_code'
                    } else if(res.already_active){
                        this.$notify({
                            type: 'success',
                            group: 'general',
                            title: 'Redan aktiverad',
                            text: 'Du är redan aktiv, om du glömt ditt lösenord kan du skicka efter ett nytt på denna sida',
                            duration: 10000,
                            speed: 1000
                        });
                    }
                });
            },
            doStartForgotPassword() {
                this.waiting = true;
                apiCall('/user/startForgotPassword', {
                    data: {
                        username: this.username,
                    }
                }).then(res => {
                    this.waiting = false;
                    // if(res.sent === true) {
                    if(!res.error){
                        this.$notify({
                            type: 'success',
                            group: 'general',
                            title: 'Du har snart mejl..',
                            text: 'Vi har nu skickat ett mejl. Följ instruktionerna i mejlet så är ditt lösenord snart bytt.',
                            duration: 10000,
                            speed: 1000
                        });
                        this.mode = "change_password";
                    } else {
                        this.$notify({
                            type: 'error',
                            group: 'general',
                            title: 'Fel',
                            text: 'Vi kunde tyvärr inte hjälpa dig att byta lösenord, kontakta oss för mer hjälp.',
                            duration: 10000,
                            speed: 1000
                        });
                    }
                });
            },
            abortForgot() {
                this.mode = 'login'
                let query = Object.assign({}, this.$route.query);
                delete query.forgot_key;
                this.forgot_code = "";
                this.$router.replace({ query });
            },
            doValidateForgotCode() {
                this.waiting = true;
                if(!this.forgot_activation_password || this.forgot_activation_password === "") {
                    this.$notify({
                        type: 'error',
                        group: 'general',
                        title: 'Lösenord saknas',
                        text: 'Vänligen fyll i ett lösenord',
                        duration: 10000,
                        speed: 1000
                    });
                    this.waiting = false;
                } else if(this.forgot_activation_password != this.forgot_activation_password_confirm) {
                    this.$notify({
                        type: 'error',
                        group: 'general',
                        title: 'Lösenorden stämmer inte',
                        text: 'Du har inte angivit samma lösenord, vänligen skriv samma lösenord i båda fälten',
                        duration: 10000,
                        speed: 1000
                    });
                    this.waiting = false;
                } else {
                    apiCall('/user/changePassword', {
                        data: {
                            username: this.username,
                            code: this.forgot_code,
                            password: this.forgot_activation_password,
                        }
                    }).then(res => {
                        this.waiting = false;
                        if(res.changed === true) {
                            this.$notify({
                                type: 'success',
                                group: 'general',
                                title: 'Lösenordet är nu bytt!',
                                text: 'Du kan nu logga in med ditt nya lösenord',
                                duration: 10000,
                                speed: 1000
                            });
                            this.abortForgot();
                        } else {
                            this.$notify({
                                type: 'error',
                                group: 'general',
                                title: 'Gick inte att byta lösenord',
                                text: 'Det gick inte att byta lösenord på ditt konto. Prova att beställa en ny kod, och säkerställ att du använder den senaste koden du fått.',
                                duration: 10000,
                                speed: 1000
                            });
                        }
                    });
                }
            },
            doValidateActivationCode() {
                this.waiting = true;
                if(!this.activation_password || this.activation_password === "") {
                    this.$notify({
                        type: 'error',
                        group: 'general',
                        title: 'Lösenord saknas',
                        text: 'Vänligen fyll i ett lösenord',
                        duration: 10000,
                        speed: 1000
                    });
                    this.waiting = false;
                } else if(this.activation_password != this.activation_password_confirm) {
                    this.$notify({
                        type: 'error',
                        group: 'general',
                        title: 'Lösenorden stämmer inte',
                        text: 'Du har inte angivit samma lösenord, vänligen skriv samma lösenord i båda fälten',
                        duration: 10000,
                        speed: 1000
                    });
                    this.waiting = false;
                } else {
                    apiCall('/user/validateAndActivate', {
                        data: {
                            username: this.username,
                            code: this.activation_code,
                            password: this.activation_password,
                        }
                    }).then(res => {
                        this.waiting = false;
                        if(res.activated === true) {
                            this.$notify({
                                type: 'success',
                                group: 'general',
                                title: 'Aktiverad och klar!',
                                text: 'Ditt konto är nu aktiverat. Välkommen!',
                                duration: 10000,
                                speed: 1000
                            });
                            this.password = this.activation_password;
                            this.mode = 'login';
                            this.doLogin();
                        } else {
                            this.$notify({
                                type: 'error',
                                group: 'general',
                                title: 'Aktivering misslyckades',
                                text: 'Det gick inte att aktivera ditt konto. Prova att beställa en ny aktiveringskod, och säkerställ att du använder den senaste koden du fått.',
                                duration: 10000,
                                speed: 1000
                            });
                        }
                    });
                }

            },
            doLogout() {
                this.hasConfirmed = false;
                this.logoutAction();
            },
            // setCookie(){
            //     VueCookies.set("hasConfirmed", true, "30D", "/", window.location.hostname, true);
            //     if(!VueCookies.get("isAdmin")){
            //         VueCookies.set("isAdmin", true, "30D", "/", window.location.hostname, true);
            //     }
            //     this.hasConfirmed = true;
            //     this.$router.push("/");
            // },
            doLogin() {
                if(this.waiting !== true) {
                    let vm = this;
                    this.waiting = true;
                    this.loginAction(
                        {
                            username: this.username,
                            password: this.password,
                            ios: this.detectIosApp()
                        }
                    ).then(function () {
                        // if(this.isAdmin){
                        //     this.hasConfirmed = false;
                        //     this.$refs.confirm_gdpr.showModal();
                        // }
                        vm.waiting = false;
                    });
                }
            },
            ...mapActions({
                loginAction: 'user/loginUser',
                logoutAction: 'user/logoutUser',
            })
        }
    }

</script>
