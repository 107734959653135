<template>
    <div class="post_comments">
        <div class="comments">
            <div class="comment" v-for="(comment, index) in comments" :key="index">

                <div class="gravatar">
                    <img :src=" baseUrl + 'avatar/id/' + comment.member_number " />
                </div>
                <div class="comment_text">
                    <span class="member-name">{{ comment.member_name }}</span>
                    <div class="comment-timestamp">
                        {{ humanDate(comment.creation_date) }}
                    </div>
                    <div class="comment-content">
                        {{ comment.comment }}

                    </div>
                </div>




                <div v-show="suredelete !== comment.id" class="comment_remove cursor-pointer" v-on:click="suredelete = comment.id" v-if="comment.member_number === user_data.id || canRemove">
                    <i class="mdi mdi-trash-can"></i> Ta bort
                </div>
                <div v-show="suredelete === comment.id" class="comment_remove cursor-pointer" v-on:click="removeComment(comment.id)">
                    <div class="error">
                        <i class="mdi mdi-trash-can"></i> Säker?
                    </div>
                </div>

            </div>
        </div>
        <div class="new_comment" v-if="!restricted">
            <div class="gravatar">
                <img :src=" baseUrl + 'avatar/id/' + user_data.id " />
            </div>
            <div class="new_comment_text">
                <resizable-textarea>
                    <textarea rows="1" v-on:keyup.enter="postComment" v-model="comment" placeholder="Kommentera..."></textarea>
                </resizable-textarea>
            </div>
            <button class="new_comment_button" v-on:click="postComment"><i class="mdi mdi-send"></i></button>
        </div>
    </div>
</template>

<script>
    import ResizableTextarea from '@/utils/ResizableTextarea';
    import { mapState, mapGetters } from "vuex";
    import moment from 'moment';

    export default {
        props: {
            comments: {
                type: Array
            },
            item: {
                type: Object
            },
        },
        components: {
            ResizableTextarea
        },
        data(){
            return {
                comment: "",
                baseUrl: process.env.VUE_APP_API_DOMAIN+"backend/",
                area_id: this.item.area,
                suredelete: false,
                timeout: null
            }
        },
        watch: {
            suredelete(val) {
                let vm = this;
                clearInterval(this.timeout);
                this.timeout = setTimeout(function() {
                    vm.suredelete = false;
                }, 3000);
            }
        },
        computed: {
            ...mapState({
                user_data: state => state.user.data
            }),
            ...mapGetters({
                restricted: 'user/restricted',
            }),
            humanDate(date){
                return date => {
                    return moment(date).locale("sv").format('DD MMMM [kl.] HH:mm');
                }
            },
            area() {
                return this.user_data.areas.filter(item => item.id === this.area_id);
            },
            canRemove() {
                if(this.area.length > 0){
                    if(this.area[0].role && this.area[0].role.rules && this.area[0].role.rules.publishing && this.area[0].role.rules.publishing.comments && this.area[0].role.rules.publishing.comments.delete) {
                        return true;
                    } else {
                        return false;
                    }
                }
                // Item is a group item, so user can only remove if they posted the comment themselves
                return false;
            }
        },
        methods: {
            postComment(){
                this.$emit('postComment', this.comment);
                this.comment = "";
            },
            removeComment(id){
                // if(confirm("Är du säker?")){
                    this.$emit("removeComment", id);
                // }
            }
        }
    }
</script>
