<template>
    <div class="gutter">
        <div class="page-header">
            <h1 class="headline blue">Länkar och resurser</h1>
        </div>
        <div class="content">
            <ul class="links">
                <li><a href="mailto:info@liberalerna.se">Mejla Liberalernas riksorganisation (info@liberalerna.se)</a></li>
                <li><a href="mailto:support@liberalerna.se">Mejla frågor om betalning, medlemskap och teknik (support@liberalerna.se)</a></li>
                <li>Liberalernas växel: <a href="tel:020-53 53 53">020-53 53 53</a></li>
                <li>Press- och jourtelefon: <a href="tel:070-854 90 20">070-854 90 20</a></li>
            </ul>
            <p></p>
            <ul class="links">
                <li><a href="http://www.liberalerna.se/riks">Bilder, logotyper, mallar och andra gemensamma dokument</a></li>
                <li><a href="http://liberalerna.se/valrorelse">Aktuell valrörelse</a></li>
                <li><a href="https://liberalerna.felestad.se/">Prylshop (Prylar, kläder, knappar och kampanjmaterial)</a></li>
                <li><a href="http://tryck.liberalerna.se">Webbtryckeri (Flygblad, broschyrer, värvningskort, kuvert etc)</a></li>
                <li><a href="http://www.liberalerna.se/politik-a-o/">Politik A–Ö</a></li>
                <li><a href="https://goo.gl/forms/rTr793RCQLtmHLTq1">Beställa @liberalerna.se-adress</a></li>
                <li><a href="mailto:app@liberalerna.se">Mejla synpunkter och idéer om appen (app@liberalerna.se)</a></li>
            </ul>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'Links',
        computed: {

        },
        components: {
        },
        data(){
            return {
                baseUrl: process.env.VUE_APP_API_DOMAIN+"backend/",
            }
        },
    }
</script>