<template>
    <div class="breadcrumbs">
        <ul>
            <li class="options">
                <img v-if="pathTooLong" src=".././assets/folder2.svg" @click="showOptions = !showOptions" alt="folder">
                <ul v-if="showOptions">
                    <li v-for="p in shortenedPathStart" :key="p.id + 'p'" @click="handleClick(p)">{{p.name}}</li>
                </ul>
            </li>
            <li v-for="p in shortenedPathEnd" :key="p.id + 'p'" :class="{breadcrumb_active: p.id !== lastPath.id}" @click="handleClick(p)">{{p.name}}
                <img src=".././assets/arrow.svg" class="rotate" >
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        props: {
            path: Array,
            lastPath: Object
        },
        data(){
          return {
              showOptions: false
          }
        },
        computed: {
            pathTooLong(){
              return this.path.length > 2;
            },
            shortenedPathEnd(){
                if(this.pathTooLong){
                    return this.path.slice(this.path.length - 2)
                }
                return this.path;
            },
            shortenedPathStart(){
                if(this.pathTooLong){
                    return this.path.slice(0, this.path.length - 2).reverse();
                }
                return [];
            }
        },
        methods: {
           handleClick(path){
               this.$emit('openFolder', path);
               this.showOptions = false;
           }
        }
    }
</script>