<template>
    <div class="gutter notice-main" >
        <div id="list">
            <div class="headline blue">Aviseringar</div>
            <!--<p class="intro-text">I listan nedan hittar du dina senaste notiser.</p>-->
            <div class="notification_list">
                <div v-if="notifications.length <= 0">
                    <p>Här var det tomt! Men var inte ledsen, så fort det börjar hända saker kommer det vara fullt här.</p>
                </div>
                <div :key="index" v-for="(notification, index) in notifications" class="notification"
                     v-on:click="handleNotificationClick(notification)"
                     :class="{ unseen: (notification.seen != 1), clickable: (notification.view_url != '') }">
                    <div class="creator">
                        <div class="gravatar">
                            <img :src="baseUrl + 'avatar/id/' + notification.creator.id" />
                        </div>
                    </div>
                    <div class="content">
                        <div class="title">
                            <strong>{{notification.name_of_person}}</strong> {{notification.name_of_action}} <strong>{{notification.name_of_subject}}</strong>
                            <span v-if="notification.area_name !== ''"> i {{notification.area_name}}</span>
                        </div>
                        <p v-if="notification.text">"{{shortenedText(notification.text)}}"</p>
                        <div class="date">{{ humanDate(notification) }}</div>
                    </div>
                    <div class="icon">
                        <img v-if="notification.type == 'event'" src="@/assets/calendar.svg" />
                        <img v-if="notification.type == 'folder'" src="@/assets/folder.svg" />
                        <img v-if="notification.type == 'file'" src="@/assets/pdf.svg" />
                    </div>
                </div>
            </div>

            <Loader type="component" />
        </div>

    </div>
</template>

<script>

    import {mapActions} from "vuex";
    import Loader from "@/components/Loader"
    import apiCall from "@/utils/api"
    import moment from 'moment';

    export default {
        name: "Notices",
        components: {
            Loader,
        },
        data: function() {
            return {
                baseUrl: process.env.VUE_APP_API_DOMAIN+"backend/",
            }
        },
        computed: {
            notifications() {
                return this.$store.getters["user/notifications"];
            },
            shortenedText(text){
                return text => {
                    if(text.length > 200){
                        return text.slice(0, 200) + "...";
                    } return text;
                }
            }
        },
        created() {
            apiCall("/user/clearNotifications").then();

            this.$store.dispatch("user/clearUnseenNotifications");
        },
        methods: {
            handleNotificationClick(notification) {
                if(notification.view_url != "") {
                    this.$router.push(notification.view_url);
                }
            },
            humanDate(notification) {
                return moment(notification.creation_date)
                    .locale("sv").fromNow();
            },
            ...mapActions({
                userLoggedIn: 'user/userLoggedIn',
                showLoading: 'loader/show',
                hideLoading: 'loader/hide',
            })
        }
    }
</script>
