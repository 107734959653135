<template>
    <div>
        <div class="name">
            <p>{{item.title}}
                <!--<img v-if="!item.starred" src=".././assets/empty_star.svg">-->
                <!--<img v-else src=".././assets/star.svg">-->
            </p>
        </div>
        <div class="created"><p>Tillagd {{dateString}} {{humanDate}}</p></div>
    </div>
</template>

<script>
    import moment from "moment";

    export default {
        props: {
            item: Object
        },
        computed: {

            dateString() {
                if (this.type === "folder") {
                    return "Senast ändrad";
                } else {
                    return "";
                }
            },
            humanDate() {
                const date = this.type === "file" ? this.item.creation_date : this.item.modification_date;
                return moment(date)
                    .locale("sv")
                    .format("DD MMMM YYYY");
            }
        }

    }

</script>
