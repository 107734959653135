<template>
    <div>
        <div class="event-date" v-if="fullDay && isSameDay"> {{formatDate(event.start)}} <span class="event-date" v-if="fullDay"> - Hela dagen</span></div>
        <div class="event-date" v-if="!isSameDay">{{formatDate(event.start)}} till {{formatDate(event.end)}}</div>

    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        props: {
            event: {
                type: Object
            }
        },
        data(){
            return {
                fullDay: false,
                isSameDay: false
            }
        },
        computed: {
            formatDate(value) {
                return value => {
                    let date = moment(String(value)).locale('sv');

                    if(!this.fullDay){
                        date = date.format('D MMMM HH:mm');
                    } else {
                        date = date.format('D MMMM');
                    }
                    return date;

                }
            }
        },
        methods: {
            checkIfFullDay(){
                if(this.event.start.includes("T00:00:00.000Z") && this.event.start.includes("T00:00:00.000Z")){
                    this.fullDay = true;
                    let start = moment(this.event.start).locale('sv').format("D MMMM");
                    let end = moment(this.event.end).locale('sv').format("D MMMM");
                    if(end === start){
                        this.isSameDay = true;
                    }
                }
            }
        },
        created(){
            this.checkIfFullDay();
        },
    }
</script>