<template>
    <Modal ref="modal_push" :className="'mini-modal'" @closed="closed">
        <template slot="header">
            Notiser
        </template>
        <template slot="body">
            <div class="communication">
               <p>Vill du ha pushnotiser för {{ title }}?</p>
            </div>
            <div class="push-submit">
                <button class="blue" @click="saveNotificationSettings(true)">Ja</button>
                <button class="white" @click="saveNotificationSettings(false)">Nej</button>
            </div>
        </template>
    </Modal>
</template>

<script>
    import Modal from "@/components/Modal";
    import {mapGetters} from "vuex";
    import VueCookies from "vue-cookies";
    import apiCall from '@/utils/api';

    export default {
        name: "PushModal",
        components: {
            Modal
        },
        computed: {
            ...mapGetters({
                title: "specialEvents/title",
                event: "specialEvents/specialEvent"
            }),
            borderClass(){
                if(this.email || this.push){
                    return 'border blue';
                }
                return 'border'
            },
            checkedClass(type){
                return type => {
                    if(this[type] === true){
                        return 'checked';
                    }
                    return '';
                }
            },
        },
        methods: {
            openModal(){
                this.$refs.modal_push.showModal();
                this.$emit('opened');
            },
            closed(){
                this.$emit('closed');
            },
            closeModal(){
                this.$refs.modal_push.close();
                VueCookies.set("has_set_push_for_" + this.event.id, true, "30D", "/", window.location.hostname, true);
                this.$emit('saved');
            },
            saveNotificationSettings(push){
                this.closeModal();

                let id = `sevent_${this.event.id}`;

                let data = {
                    area_id: id,
                    email: 0,
                    push: push
                };

                apiCall("/user/saveNotificationSettings", { data });

            }
        },

    }
</script>

<style scoped>

</style>
