<template>
    <div class="gutter">
        <div class="page-header">
            <h1 class="headline blue" v-if="newbie">Väkommen till Liberalerna!</h1>
            <h1 class="headline blue" v-if="!newbie">Hjälp</h1>
            <!-- <p>Se våra introduktionsfilmer</p> -->
        </div>

        <!-- <div class="video-container">
            <iframe :src="'https://www.youtube.com/embed/'+video" class="video" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>

        <div class="film-picker">
            <div>Välj film:</div>
            <div class="button-row">
                <div :class="((video === '31ZCM0j2WG0') ? 'active' : '')+' btn picker'" v-on:click="video = '31ZCM0j2WG0'" >Appen</div>
                <div :class="((video === 'YxmTrp6p7OE') ? 'active' : '')+' btn picker'" v-on:click="video = 'YxmTrp6p7OE'" >Liberalerna</div>
                <div :class="((video === 'VZ3AGjA69y0') ? 'active' : '')+' btn picker'" v-on:click="video = 'VZ3AGjA69y0'" >Liberalism</div>
            </div>
        </div>
        -->

        <div class="content" v-if="newbie">
            <div class="tag orange">VÄLKOMMEN</div>
            <div class="content-header">Liberalernas app för alla medlemmar</div>
            <p>Här kan du som är medlem i Liberalerna hitta allt du behöver: nyheter, möten, dokument och din sida där du administrerar dina uppgifter och ditt medlemskap. Vi sjösätter appen under våren 2019, och än så länge är det en betaversion – så ha tålamod om inte allt är på plats ännu. Hör gärna av dig med idéer, frågor och synpunkter till <a href="mailto:app@liberalerna.se">app@liberalerna.se</a>.
                <br/><br/>Välkommen!</p>
        </div>
        <div class="content">
            <div class="tag orange">KOM IGÅNG</div>
            <div class="content-header">Så funkar appen</div>
            <p>
                I Liberalernas app samlar vi all information på ett ställe. Längst ned hittar du en meny med fem olika symboler:
            </p>
            <p>
                <strong>Hemflödet.</strong> Fungerar ungefär som Facebook. Nyheter och inlägg från din förening, ditt förbund, från Liberalernas riksorganisation och från olika grupper som du kan tillhöra: en styrelse eller fullmäktigegrupp till exempel. I vissa grupper kan du själv posta, i andra kan du bara kommentera.
            </p>
            <p>
                <strong>Filer.</strong> Här hittar du dokument och bilder. Enklast hittar du genom att söka, men du kan också leta i mappar på de olika nivåerna.
            </p>
            <p>
                <strong>Kalender.</strong> En kalender för alla liberala möten och kampanjer. Hantera inbjudningar och ladda ned till din egen kalender om du vill.
            </p>
            <p>
                <strong>Aviseringar.</strong> Ett snabbt sätt att få överblick på vad som hänt sedan sist som berör dig: möten, tillagda filer, nyheter och kommentarer.
            </p>
            <p>
                <strong>Mer.</strong> Här hittar du framför allt Min sida, där du kan ändra dina uppgifter och styra hur du får din information från Liberalerna.
            </p>
            <p>
                Du som är ordförande eller anställd är också administratör i appen. Då kan du skapa poster och kalenderhändelser, administrera medlemmar och lägga upp filer till exempel. Mer om hur appen fumgerar för både användare och aministratörer, hittar du i supportmappen bland filerna.
            </p>
            <p>
                Hör gärna av dig med idéer, frågor och synpunkter till <a href="mailto:app@liberalerna.se">app@liberalerna.se</a>
                <br/>Välkommen!
            </p>
        </div>

        <div>
            <div class="pale-header">Kontakt</div>
            <div class="button-row">
                <input type="button" class="picker kommun" value="Förening" @click="$refs.modal_kommun.showModal()" />
                <input type="button" class="picker forbund" value="Förbund" @click="$refs.modal_forbund.showModal()" />
                <input type="button" class="picker riks" value="Riks" @click="$refs.modal_riks.showModal()" />
            </div>
        </div>

        <Modal ref="modal_kommun">
            <template slot="header">
                <div class="header">
                    Kontaktuppgifter till {{ foreningName }}
                </div>
            </template>
            <template slot="body">
                <p>Nedan hittar du kontaktuppgifter till din förening.</p>
                <div class="contact" v-for="(item, index) in foreningContacts" :key="index">
                    <div class="avatar">
                        <img :src=" baseUrl + 'avatar/id/' + item.member_no " />
                    </div>
                    <div class="contact-info">
                        <h3>{{ item.first_name }} {{ item.last_name }} <small>{{ item.role }}</small></h3>
                        <div><i class="mdi mdi-email-outline"></i> <a :href="'mailto:'+item.email">{{ item.email }}</a></div>
                    </div>
                </div>
                <h5 v-if="foreningContact != ''">Kontaktinformation</h5>
                <div v-html="foreningContact"></div>
            </template>
        </Modal>

        <Modal ref="modal_forbund">
            <template slot="header">
                <div class="header">
                    Kontaktuppgifter till {{ forbundName }}
                </div>
            </template>
            <template slot="body">
                <p>Nedan hittar du kontaktuppgifter till ditt förbund.</p>
                <div class="contact" v-for="(item, index) in forbundContacts" :key="index">
                    <div class="avatar">
                        <img :src=" baseUrl + 'avatar/id/' + item.member_no " />
                    </div>
                    <div class="contact-info">
                        <h3>{{ item.first_name }} {{ item.last_name }} <small>{{ item.role }}</small></h3>
                        <div><i class="mdi mdi-email-outline"></i> <a :href="'mailto:'+item.email">{{ item.email }}</a></div>
                    </div>
                </div>
                <h5 v-if="forbundContact != ''">Kontaktinformation</h5>
                <div v-html="forbundContact"></div>
            </template>
        </Modal>

        <Modal ref="modal_riks">
            <template slot="header">
                <div class="header">
                    Kontaktuppgifter till riksorganisationen
                </div>
            </template>
            <template slot="body">
                <p>Nedan hittar du kontaktuppgifter.</p>
                <h5 v-if="riksContact != ''">Kontaktinformation</h5>
                <div v-html="riksContact"></div>
            </template>
        </Modal>

    </div>
</template>

<script>
    import Modal from "@/components/Modal"

    export default {
        name: 'Help',
        computed: {
            foreningName() {
                return this.$store.getters["user/allAreas"].filter(item => item.level >= 3)[0].name;
            },
            forbundName() {
                return this.$store.getters["user/allAreas"].filter(item => item.level === 2)[0].name;
            },
            foreningContacts() {
                let foreningar = this.$store.getters["user/allAreas"].filter(item => item.level >= 3);
                return foreningar[0].contact_persons;
            },
            forbundContacts() {
                let foreningar = this.$store.getters["user/allAreas"].filter(item => item.level === 2);
                return foreningar[0].contact_persons;
            },
            foreningContact() {
                let contact_info = "";
                let foreningar = this.$store.getters["user/allAreas"].filter(item => item.level >= 3);
                for(var i in foreningar) {
                    if(foreningar[i].contact_info != "") {
                        contact_info += foreningar[i].contact_info;
                    }
                }
                return contact_info;
            },
            forbundContact() {
                let contact_info = "";
                let foreningar = this.$store.getters["user/allAreas"].filter(item => item.level === 2);
                for(var i in foreningar) {
                    if(foreningar[i].contact_info != "") {
                        contact_info += foreningar[i].contact_info;
                    }
                }
                return contact_info;
            },
            riksContact() {
                let contact_info = "";
                let foreningar = this.$store.getters["user/allAreas"].filter(item => item.level === 1);
                for(var i in foreningar) {
                    if(foreningar[i].contact_info != "") {
                        contact_info += foreningar[i].contact_info;
                    }
                }
                return contact_info;
            },
        },
        components: {
            Modal
        },
        data(){
            return {
                baseUrl: process.env.VUE_APP_API_DOMAIN+"backend/",
                video: "31ZCM0j2WG0",
                newbie: this.$route.params.newbie
            }
        },
    }
</script>
