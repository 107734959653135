<template>
    <div class="file-upload">
        <div v-if="limitReached" class="oversized">
            <strong><i class="mdi mdi-alert"></i> För stora bilagor</strong>
            <p>Max gränsen för uppladdning är nådd, du kan max ladda upp {{ max_size_mb }}mb</p>
        </div>

        <template v-if="edit === true">
            <div class="uploaded-file" v-for="uploadedFile in uploadedFiles" v-bind:key="uploadedFile.id">
                <UploadedFile :file="uploadedFile" :isFile="isFile(uploadedFile.type)" @removeFile="removeUploadedFile($event)" :uploadedFileString="uploadedFileString(uploadedFile.type)"/>
            </div>
        </template>

        <div v-for="(input, index) in file_inputs" v-bind:key="index">
            <div class="buttons-wrapper">
                <div class="btn blue" v-if="canUploadImage(input)" :class="flexClass(input)">
                    <input v-if="input.has_file === false" type="file" :name="input.name"
                           @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
                           accept="image/*" class="input-file">
                    <i class="mdi mdi-image-outline"></i>Lägg till bild
                </div>
                <div class="btn blue" v-if="canUploadFile(input)" :class="flexClass(input)">
                    <input v-if="input.has_file === false" type="file" :name="input.name"
                           @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
                           :accept="acceptedFileExtensions" class="input-file">
                    <img src="@/assets/clip.svg" />Bifoga fil
                </div>
            </div>

            <div v-if="input.has_file === true" class="uploaded-file">
                <template>
                    <span v-if="isImage(input)">
                        <img v-if="input.url && type !== 'file'" :src="input.url" />
                        <div v-if="type !== 'file' && input.url" class="content-header blue">Om bilder ser felvänd ut så ordnar det till sig när du väl laddar upp.</div>
                    </span>
                    <div class="file-name">
                        <i v-if="!isImage(input)" class="mdi mdi-file"></i>
                        <span class="uploaded-file-name">{{ input.file_name }}</span>
                        <button class="btn warning" @click="removeFile(index)" type="button"><i class="mdi mdi-trash-can"></i>Ta bort {{removeUploadingFileString(input)}}</button>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
f
<!-- Javascript -->
<script>
    import UploadedFile from "@/components/UploadedFile";

    export default {
        name: "FileUpload",
        props: {
            max_files: {
                type: String
            },
            type: {
                type: String,
                default: "post"
            },
            edit: {
                type: Boolean
            },
            uploadedFiles: {
                type: Array
            }
        },
        components: {
          UploadedFile
        },
        data() {
            return {
                file_inputs: [
                    {
                        name: "file_1",
                        file_name: null,
                        file_size: null,
                        has_file: false,
                        data: null
                    }
                ],
                uploadFieldName: 'photos',
                max_size_mb: 20,
                suredelete: false
            }
        },
        watch: {
            suredelete(val) {
                let vm = this;
                if(val) {
                    setTimeout(function() {
                        vm.suredelete = false;
                    }, 3000)
                }
            }
        },
        computed: {
            canUploadImage(input){
                return input => {
                    return input.has_file === false && this.file_inputs.length <= this.max_upload_files && (this.type === 'post' || this.type === 'event') && !this.limitReached;
                }
            },
            flexClass(input){
                return input => {
                    if(this.canUploadImage(input) && this.canUploadFile(input)){
                        return "both"
                    } else {
                        return "one"
                    }
                }
            },
            canUploadFile(input){
                return input => {
                    return input.has_file === false && this.file_inputs.length <= this.max_upload_files && this.type !== 'folder' && !this.limitReached;
                }
            },
            max_upload_files() {
                if(this.type === 'file'){
                    return 1;
                }
                if(this.max_files > 0) {
                    return this.max_files;
                } else {
                    return 10;
                }
            },
            isFile(type){
              return type => {
                  return ["gif", "png", "jpg", "image"].includes(type) === false;
              }
            },
            uploadedFileString(type){
                return type => {
                    if(this.isFile(type)){
                        return "fil"
                    } else {
                        return "bild"
                    }
                }
            },
            typeString(){
                if(this.type === "post") {
                    return "fil/bild";
                } else if(this.type === "event") {
                    return "bild"
                } else {
                    return "fil"
                }
            },
            acceptedFileExtensions(){
                if(this.type === "post"){
                    return ".docx,.pptx,.pdf,.indd,.psd,.xltx,.potx,.dotx";
                } else {
                    return ".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf,.indd,.psd,.xltx,.potx,.dotx";
                }
            },
            removeUploadingFileString(file){
                return file => {
                    if(this.isImage(file)){
                        return "bild";
                    } else {
                        return "fil";
                    }
                }
            },
            isImage(file) {
                return file => {
                    if(typeof(file.data) !== "undefined" && file.data != null) {
                        let validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
                        for (var i in validImageTypes) {
                            if (file.data.search(validImageTypes[i]) !== -1) {
                                return true;
                            }
                        }
                    }
                    return false;
                }
            },
            limitReached() {
                return this.totalFileSize > this.max_size_mb;
            },
            totalFileSize() {
                let size = 0;
                for(var i in this.file_inputs) {
                    if(this.file_inputs[i].has_file === true) {
                        size += this.file_inputs[i].file_size;
                    }
                }
                if(size > 0) {
                    size = size / 1024 / 1024;
                }
                return size;
            }
        },
        mounted() {
            this.reset();
        },
        methods: {
          /*  isImage(file) {
                if(typeof(file.data) !== "undefined" && file.data != null) {
                    let validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
                    for (var i in validImageTypes) {
                        if (file.data.search(validImageTypes[i]) !== -1) {
                            return true;
                        }
                    }
                }
                return false;
            },*/
            resetFiles(){
              this.file_inputs = [
                  {
                      name: "file_1",
                      file_name: null,
                      file_size: null,
                      has_file: false,
                      data: null
                  }
              ];
            },
            removeUploadedFile(fileID){
                // this.uploadedFiles = this.uploadedFiles.filter(file => file.id !== fileID);
                this.$emit('removeUploadedFile', fileID);
            },
            reset() {

            },
            getFilesData() {
                let files = [];
                for(let i in this.file_inputs) {
                    if(this.file_inputs[i].data !== null) {
                        files.push({
                            name: this.file_inputs[i].file_name,
                            data: this.file_inputs[i].data,
                        });
                    }
                }
                return files;
            },
            getBase64(file, file_input_index) {
                let reader = new FileReader();
                let vm = this;
                reader.readAsDataURL(file);
                reader.onload = function () {
                    vm.file_inputs[file_input_index].data = reader.result;
                };
            },
            createNewInput() {
                this.file_inputs.push({
                    name: "file_"+(this.file_inputs.length+1),
                    file_name: null,
                    file_size: null,
                    has_file: false,
                    data: null,
                });
            },
            removeFile(index) {
                this.file_inputs.splice(index, 1);
            },
            convertToMb(size) {
                if(size > 0) {
                    return size / 1024 / 1024;
                } else {
                    return 0;
                }
            },
            filesChange(fieldName, fileList) {
                let success = true;
                for(let i in this.file_inputs) {
                    if(this.file_inputs[i].name === fieldName) {
                        if((this.convertToMb(fileList[0].size) + this.totalFileSize) > this.max_size_mb) {
                            this.$notify({
                                type: 'warn',
                                group: 'general',
                                title: '',
                                text: 'Filen du försökte ladda upp är för stor och överskred gränsen på '+this.max_size_mb+'mb.',
                                duration: 10000,
                                speed: 1000
                            });
                            success = false;
                        } else {
                            this.getBase64(fileList[0], i);
                            this.file_inputs[i].file_name = fileList[0].name;
                            this.file_inputs[i].file_size = fileList[0].size;
                            this.file_inputs[i].has_file = true;
                            this.file_inputs[i].url = URL.createObjectURL(fileList[0]);
                        }
                    }
                }

                // Create a new input
                if(success === true){
                    this.createNewInput();
                }
            }
        },
    }
</script>
