<template>
    <div class="search-wrapper">
        <div id="search-input">
            <div class="closer" v-if="searchView">
                <div @click="closeSearch"><img src="../assets/close.svg" /></div>
            </div>
            <img src="../assets/search.svg" />
            <input type="text" placeholder="Sök" @click="openSearch" @keyup="search" v-model="searchText" />
        </div>
        <transition name="search-holder">
            <div v-if="searchView" class="search-box">
                <div class="headline blue" v-if="searching">Sökresultat</div>
                <div v-if="searching && result.length < 1 && searchText.length >= 2" class="no-results">
                    <i class="mdi mdi-cloud-question"></i> Inga resultat kunde hittas.
                </div>
                <div class="member-list">
                    <template v-for="(member, index) in result" >
                        <GroupMember :key="'member' + index" :member="member" searchView />
                    </template>
                </div>
            </div>
        </transition>

    </div>
</template>

<script>
    import GroupMember from "@/components/GroupMember"
    export default {
        name: "GroupSearch",
        props: {
            members: {
                type: Array
            }
        },
        components: {
            GroupMember
        },
        data(){
            return {
                searchText: "",
                searchView: false,
                result: [],
                searching: false,
                memberChosen: false,
                searchMember: {},
                allMembers: []
            }
        },
        methods: {
            openSearch(){
                this.searchView = true;
                this.$emit('opened');
                this.$router.push({hash: 'search' })
            },
            closeSearch(){
                this.searchText = "";
                this.result = [];
                this.searching = false;
                this.searchView = false;
                this.$router.push({hash: '' })
                this.$emit('closed');
            },
            search(){
                this.result = [];
                if(this.searchText.length > 1){
                    this.searching = true;
                    this.result = this.allMembers.filter(member => {
                        if(member.mobile){
                            if(member.mobile.includes(this.searchText)){
                                return true;
                            }
                        }
                        if(member.name){
                            if(member.name.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase())){
                                return true;
                            }
                        }
                        return false;
                    });
                }
            },
            chooseMember(member){
                this.memberChosen = true;
                this.searchMember = member;
            }
        },
        created() {
            let allMembers = [];
            for (let area of this.members) {
                allMembers.push(...area.members)
            }
            this.allMembers = allMembers;
        },
        watch: {
            '$route' (to, from) {
                if(to.hash === '#search'){
                    this.searchView = true;
                    this.$emit('opened');
                } else {
                    this.$emit('closed');
                    this.searchView = false;
                }
            }
        }
    }
</script>

<style scoped>

</style>
