<template>
    <transition name="fade-in" v-if="votingIsHappening">
        <div class="banner assembly-voting-banner" @click="onClick">
            <svg class="banner-bg" width="53" height="90" viewBox="0 0 53 90" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d)">
                    <path d="M5.88672 43.0014C5.88672 59.4819 19.2469 72.8421 35.7274 72.8421H43C48.5228 72.8421 53 77.3192 53 82.8421V3.16066C53 8.6835 48.5228 13.1607 42.9999 13.1607H35.7274C19.2469 13.1607 5.88672 26.5208 5.88672 43.0014Z"/>
                </g>
                <defs>
                    <filter id="filter0_d" x="0.886719" y="0.160645" width="57.1132" height="89.6814" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                        <feOffset dy="2"/>
                        <feGaussianBlur stdDeviation="2.5"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                    </filter>
                </defs>
            </svg>

            <div class="icon-wrapper">
                <img src="@/assets/assembly-voting-icon.svg" alt="">
            </div>
        </div>
    </transition>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        data() {
            return {
                loaded: false
            }
        },

        computed: {
            ...mapGetters({
                votingIsHappening: 'assemblyVoting/votingIsHappening'
            })
        },

        methods: {
            onClick() {
                this.$emit('bannerClicked');
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '../scss/essentials/variables';

    .banner {
        cursor: pointer;
    }

    .banner-bg {
        fill: $lib_lightblue;
    }
</style>
