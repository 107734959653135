export default {
    methods: {
        resizeTextarea (event) {
            event.target.style.height = 'auto'
            event.target.style.height = (event.target.scrollHeight) + 'px'
        },
        getContent () {
            return {
                text: this.$el.value,
                start: this.$el.selectionStart,
                end: this.$el.selectionEnd,
            }
        },
        updateContent(text, start, end) {

            // Updates the textarea's value.
            this.$el.value = text;

            // Trigger an 'input' event directly on the DOM element.
            triggerEvent(this.$el, 'input');

            // Select the right portion of text.
            this.$el.selectionStart = start;
            this.$el.selectionEnd = end;

            // Focus the textarea since we propably clicked on a button.
            this.$el.focus();

            return text
        },
        wrapWith (pattern) {
            let { text, start, end } = this.getContent();

            // Cut the text before, inside and after the selection.
            let { before, selection, after } = cutTextWithSelection(text, start, end);

            if(before.substr(-(pattern.length)) === pattern && after.substr(0, pattern.length) === pattern) {
                // Already padded, remove wrap
                before = before.slice(0, before.length-(pattern.length));
                after = after.slice(pattern.length, after.length);
            } else {
                // Add the pattern around the selection.
                before = before + pattern;
                after = pattern + after;
            }
            return this.updateContent(
                before + selection + after,       // Reassemble the text
                before.length,                    // Start selection after `before`
                before.length + selection.length, // End selection after `before + selection`
            )
        },
    },
    mounted () {
        this.$nextTick(() => {
            this.$el.setAttribute('style', 'height:' + (this.$el.scrollHeight) + 'px;overflow-y:hidden;')
            this.$el.setAttribute('style', 'height:auto;overflow-y:hidden;')
        })

        this.$el.addEventListener('input', this.resizeTextarea)
    },
    beforeDestroy () {
        this.$el.removeEventListener('input', this.resizeTextarea)
    },
    render () {
        return this.$slots.default[0]
    },
}

function triggerEvent (el, type) {
    if ('createEvent' in document) {
        // modern browsers, IE9+
        let e = document.createEvent('HTMLEvents')
        e.initEvent(type, false, true)
        el.dispatchEvent(e)
    } else {
        // IE 8
        let e = document.createEventObject()
        e.eventType = type
        el.fireEvent('on'+e.eventType, e)
    }
}

function cutTextWithSelection (text, start, end) {
    return {
        before: text.substring(0, start),
        selection: text.substring(start, end),
        after: text.substring(end, text.length),
    }
}
