<template>
    <div class="gutter groups-page" >
    <template v-if="loaded">
        <template v-if="groups.length > 0">

            <div class="content-header blue">Skicka SMS till gruppmedlemmar</div>
            <p>
                Nedan listas grupperna du kan hantera. Välj en i listan.
            </p>
            <div class="select-holder">
                <select v-model="selectedGroup" v-on:change="getMembersInGroup()">
                    <option value="0">Välj grupp</option>
                    <template v-for="group in groups">
                        <option :key="group.id" v-if="group.can_send === true" :value="group.id">{{ group.title }}</option>
                    </template>
                </select>
            </div>
        </template>
        <template v-else>
            <p>Du har inga grupper du kan hantera för närvarande.</p>
        </template>

        <div class="create_post">

            <Modal ref="modal_group_action">
                <template slot="header">
                    <div class="header">SMS till grupp</div>
                </template>
                <template slot="body">
                    <form>
                        <div class="areas-wrapper">
                            <div class="content-header">Skicka SMS till medlemmar i {{ selectedGroupTitle }}. </div>

<!--                            <div class="publish-as-holder" v-if="myUserToPublishAs.length > 0">-->
<!--                                <span>Publicera&nbsp;som:&nbsp;</span>-->
<!--                                <select v-model="publishAs">-->
<!--                                    <option value="">Mig själv</option>-->
<!--                                    <option v-for="(person, index) in myUserToPublishAs" :key="'person' + index" :value="person.member_id">{{ person.name }}</option>-->
<!--                                </select>-->
<!--                            </div>-->
                        </div>

                        <div class="form-title">

                            <ResizableTextarea>
                                <textarea rows="1" v-model="message" placeholder="Skriv ditt meddelande här... " @keyup="checkMessageLength"></textarea>
                            </ResizableTextarea>

                        </div>
                        <transition name="fade">
                            <div v-if="messageExceeding" class="areas-wrapper message-exceeding">
                                <div class="content-header"> OBS. Meddelandet överskrider längden för ett SMS. Du kan fortfarande skicka meddelandet, men extra kostnader kan tillkomma.</div>
                            </div>
                        </transition>

                        <div class="receivers">
                            <p>Meddelandet kommer att skickas till följande personer <strong>({{receivers.length}} stycken)</strong></p>
                            <p><a v-on:click="checkAllReceivers">Välj alla</a> / <a v-on:click="uncheckAllReceivers">Avmarkera alla</a></p>
                            <div class="receiver-list">
                                <div class="receiver" v-for="member in filteredUsers" :key="member.member_no">
                                    <input type="checkbox" :id="'send_to_'+member.member_no" v-model="receivers" :value="member.member_no" />
                                    <label :for="'send_to_'+member.member_no">{{ member.name }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="create_submit">
                            <button type="button" class="btn blue" @click="validate">Skicka meddelande</button>
                        </div>
                    </form>
                </template>
            </Modal>

            <Modal ref="modal_group_confirm">
                <template v-slot:header>
                    <div class="header">Viktigt</div>
                </template>
                <template v-slot:body>
                    <form>
                        <div class="agreement">
                            <div class="text-message">
                                <div class="pale-header">Priset är 30 öre per SMS.</div>
                                <p>När du klickar på "Skicka meddelande" godkänner du samtidigt att kostnaden faktureras din förening/förbund. Fakturering sker halvårsvis.</p>
                            </div>
                            <div class="buttons-wrapper">
                                <button v-on:click="send" type="button"  class="btn blue"><i class="mdi mdi-check"></i> Jag godkänner</button>
                                <button v-on:click="$refs.modal_group_confirm.close()" type="button"  class="btn"><i class="mdi mdi-close-circle"></i> Avbryt</button>
                            </div>
                        </div>
                    </form>
                </template>
            </Modal>
        </div>

        <Loader type="component" />
    </template>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import Modal from "@/components/CreateModal";
    import Loader from "@/components/Loader";
    import ResizableTextarea from "@/utils/ResizableTextarea";
    import apiCall from "@/utils/api";

    export default {
        name: "Groups",

        components: {
            Loader,
            Modal,
            ResizableTextarea,
        },

        data() {
            return {
                message: "",
                messageExceeding: false,
                usersInGroup: [],
                receivers: [],
                selectedGroup: "0",
                groups: [],
                publishAs: null,
                loaded: false,
            }
        },

        computed: {
            hasSelectedGroup(){
              return this.selectedGroup.length > 0;
            },
            myUserToPublishAs() {
                return this.$store.getters["user/myUserToPublishAs"];
            },
            selectedGroupTitle(){
                const group = this.groups.find(g => parseInt(g.id) === parseInt(this.selectedGroup));
                if(group){
                    return group.title;
                }
                return "";
            },
            filteredUsers(){
                return this.usersInGroup.filter(u => u.mobilephone && u.mobilephone.length > 0);
            },
        },
        methods: {

            checkAllReceivers(){
                this.receivers = this.usersInGroup.map(m => m.member_no);
            },
            uncheckAllReceivers(){
                this.receivers = [];
            },
            checkMessageLength(){
                this.messageExceeding = this.message.length > 160;
            },
            send(){
                this.$refs.modal_group_action.close();
                this.$refs.modal_group_confirm.close();
                let data = {
                    area_id: this.selectedGroup,
                    subject: "",
                    message: this.message,
                    receivers: this.receivers,
                    type: "sms",
                    files: [],
                    publish_as: this.publishAs,
                    group: true
                };

                this.sendMessage(data);

            },
            validate(){
                let valid = true;
                let errors = [];
                if(this.message === "") {
                    valid = false;
                    errors.push("Du måste skriva ett meddelande");
                }
                if(this.receivers.length <= 0) {
                    valid = false;
                    errors.push("Du måste minst ange en person att skicka till");
                }

                if(valid) {
                    this.$refs.modal_group_confirm.showModal();
                } else {
                    this.$notify({
                        type: 'error',
                        group: 'general',
                        title: 'Du har inte fyllt i alla fält',
                        text: 'Vänligen rätta nedan: <br>'+errors.join("<br>"),
                        duration: 10000,
                        speed: 1000
                    });
                }
            },
            getGroupsForMember(){
                this.showLoading("component");
                apiCall("/user/getMyGroups").then(res => {
                    this.groups = res;
                    this.loaded = true;
                    this.hideLoading("component");
                })
            },
            getMembersInGroup(){
                if(this.selectedGroup.length > 0){
                    let data = {
                        param_group: this.selectedGroup
                    };
                    this.showLoading("component");
                    apiCall("/user/getUsersInGroup", { data }).then(res => {
                        this.usersInGroup = res.users_in_group;
                        this.hideLoading("component");
                    });
                    this.toggleForm();
                }
            },
            toggleForm(){
                this.receivers = [];
                this.$refs.modal_group_action.showModal();
            },

            ...mapActions({
                sendMessage: 'user/sendMessage',
                showLoading: 'loader/show',
                hideLoading: 'loader/hide'
            })
        },
        created(){
            this.getGroupsForMember();
        },

    }
</script>
