<template>
    <div id="list">
        <Search @opened="searchView = true" @closed="searchView = false"/>
        <Form ref="Form" :type="type" />
        <Areafilter ref="Areafilter" @switch="loadMoreItems($event)" />
        <ToggleViews :view="view_type" @toggle="switchView($event)" :firstView="'calendar'" :secondView="'list'"/>
        <vue-event-calendar :events="compItems" @day-changed="handleDayChanged" @month-changed="handleMonthChanged">
            <template slot-scope="props">
                <div v-if="view_type === 'list'">
                    <h2 class="date" v-if="myCompItems.length > 0">Tackat ja</h2>
                    <template v-if="myCompItems && myCompItems.length">
                        <div v-for="(event) in myCompItems" :key="event.id" class="event-items">
                            <div class="cal-events">
                                <router-link :to="{ path: '/calendar/' + event.slug }">
                                    <calendar-item :eventID="event.id"/>
                                </router-link>
                            </div>
                        </div>
                    </template>
                    <h2 class="date">Alla händelser</h2>
                </div>
                <div id="event-scroll"></div>
                <div v-for="(event,index) in props.showEvents" :key="index" class="event-items">
                    <div class="cal-events" >
                        <router-link :to="{ path: '/calendar/' + event.slug }">
                            <calendar-item :eventID="event.id"/>
                        </router-link>
                    </div>
                </div>
            </template>
        </vue-event-calendar>
    </div>
</template>

<script>
    import Search from "./Search";
    import {mapActions, mapGetters} from "vuex";
    import Form from "@/components/Form.vue";
    import Areafilter from "./Areafilter";
    import CalendarItem from "@/components/CalendarItem.vue";
    import ToggleViews from "@/components/ToggleViews";

    export default {
        name: "Calendar",
        components: {
            Form,
            Search,
            Areafilter,
            CalendarItem,
            ToggleViews
        },
        data () {
            return {
                componentLoaded: false,
                items: [],
                hidden_calendar: true,
                hidden_list: false,
                view_type: "list",
                today: new Date(),
                chosen_date: ""
            }
        },
        watch: {
            items: function() {
                this.$nextTick(function () {
                    this.setDots();
                });
            },
            compItems: function() {
                this.$nextTick(function () {
                    this.setDots();
                });
            }
        },
        props: {
            type: {
                type: String,
                default: "event"
            }
        },

        computed: {
            ...mapGetters({
                events: 'items/events',
                currentGroup: 'user/currentGroup',
                userData: 'user/userData'
            }),
            filteredItems() {
                let current_group = this.currentGroup;
                if(current_group.id > 0) {
                    return this.draftFilteredEvents;
                } else {
                    return this.draftFilteredEvents.filter(item => this.$refs.Areafilter.filters.includes(parseInt(item.area_level)));
                }
            },
            draftFilteredEvents() {
                if(!this.componentLoaded) {
                    return [];
                }
                if(this.currentGroup.id > 0) {
                    return this.events;
                } else {
                    return this.events.filter(item => {
                        return item.status !== 'draft' || item.user === this.userData.id
                    });
                }
            },
            groupedFilteredItems(){
                return this.groupBy(this.filteredItems, 'id').sort(this.sortItemsByStartingDate);
            },
            compItems() {
                if(!this.componentLoaded) {
                    return [];
                }
                let array = [];
                if(this.view_type === "calendar") {
                    array = this.filteredItems;
                } else {
                    array = this.groupedFilteredItems;
                }
                return array;
            },
            myCompItems() {
                if(!this.componentLoaded) {
                    return [];
                }
                const items = this.groupBy(this.compItems.filter(item => item.attend === 1), "id");

                return items.sort(this.sortItemsByStartingDate);
            },
            dateFormatted() {
                let year = this.today.getFullYear();
                let month = this.today.getMonth() + 1;
                let day = this.today.getDate();
                return year + "/" + month + "/" + day;
            }
        },
        created() {
            this.loadItems();
        },
        mounted() {
            this.componentLoaded = true;
            document.getElementsByClassName("cal-wrapper")[0].style.display = 'none';
            this.hideCalendar();
            this.setCalendarTitle();
            this.setListTitle();
            this.$watch(
                "$refs.Areafilter.filters",
                () => {
                    // this.$EventCalendar.toDate(this.chosen_date);
                    this.setCalendarTitle();
                }
            );
        },
        methods: {
            loadMoreItems(areas) {
                let data = {
                    type: "event",
                    area: 0
                };
                if(areas && areas.length === 1){
                    data.area = areas[0];
                    data.start_at = this.filteredItems.length;
                }
                this.showLoading("component");
                this.$store.dispatch("items/loadMoreItems", data).then(res => {
                    this.hideLoading("component");
                });
            },
            ...mapActions({
                showLoading: 'loader/show',
                hideLoading: 'loader/hide',
            }),
            sortItemsByStartingDate(a, b){
                if(a.start < b.start){
                    return -1;
                }return 1;
            },
            groupBy(array, key){
                const result = {}
                array.forEach(item => {
                    if (!result[item[key]]){
                        result[item[key]] = []
                    }
                    result[item[key]] = item
                });
                return Object.values(result);
            },
            handleDayChanged (data) {
                if(data.events.length > 0){
                    location.href = "#event-scroll";
                }
                if(data.events.length > 0) {
                    this.$EventCalendar.toDate(data.date);
                    this.chosen_date = data.date;
                    this.$nextTick(function () {
                        this.showList();
                        this.setDots();
                        this.setListTitle();
                    });
                }
            },
            handleMonthChanged () {
                this.$nextTick(function () {
                    this.hideList();
                    this.setCalendarTitle();
                    this.setDots();
                });
            },
            loadItems() {
                if(this.$store.getters["items/eventsNeedsInitialLoad"] === true) {
                    this.showLoading("component");
                    this.$store.dispatch("items/loadItems", "event").then(res => {
                        this.hideLoading("component");
                    });
                }
            },
            setDots() {
                let event_items = document.getElementsByClassName("event");
                for (let i = 0; i < event_items.length; i++) {
                    if(event_items[i].querySelector('.dots') !== null) {
                        event_items[i].querySelector('.dots').parentNode.removeChild(event_items[i].querySelector('.dots'));
                    }
                    let wrapper = document.createElement("div");
                    wrapper.className = "dots";
                    for(let j = 0; j < event_items[i].classList.length; j++) {
                        let reg_classes = ["today","selected-day","item","event"];
                        if(reg_classes.indexOf(event_items[i].classList[j]) === -1) {
                            let el = document.createElement("span");
                            el.className = event_items[i].classList[j];
                            wrapper.appendChild(el);
                        }
                    }
                    event_items[i].appendChild(wrapper);
                }
            },
            setCalendarTitle() {
                let curYear = this.$children[4].$children[0].$props.calendar.params.curYear;
                let curMonth = this.$children[4].$children[0].$props.calendar.params.curMonth;
                let curMonthName = this.$children[4].$children[0].i18n.sv.monthNames[curMonth];
                let calHeader = document.querySelectorAll('div.title')[0];
                calHeader.innerHTML = curMonthName;
            },
            setListTitle() {
                if(this.hidden_calendar) {
                    let listHeader = document.querySelectorAll('h2.date')[0];
                    listHeader.innerHTML = "";
                } else {
                    let curMonth = this.$children[4].$children[0].$props.calendar.params.curMonth;
                    let curMonthName = this.$children[4].$children[0].i18n.sv.monthNames[curMonth];
                    let curDay = this.$children[4].$children[0].$props.calendar.params.curDate;
                    let listHeader = document.querySelectorAll('h2.date')[0];
                    listHeader.innerHTML = curDay + " " + curMonthName;
                }
            },
            // clickCalendar() {
            //     this.view_type = "calendar";
            //     this.$EventCalendar.toDate(this.dateFormatted);
            //     this.showCalendar();
            //     this.hideList();
            //     this.setCalendarTitle();
            //     this.setDots();
            // },
            switchView(view){
              if(view === "calendar"){
                  this.view_type = "calendar";
                  this.$EventCalendar.toDate(this.dateFormatted);
                  this.showCalendar();
                  this.hideList();
                  this.setCalendarTitle();
                  this.setDots();
              } else {
                  this.view_type = "list";
                  this.$EventCalendar.toDate("all");
                  this.hideCalendar();
                  this.showList();
                  this.setListTitle();
              }
            },
            // clickList() {
            //     this.view_type = "list";
            //     this.$EventCalendar.toDate("all");
            //     this.hideCalendar();
            //     this.showList();
            //     this.setListTitle();
            // },
            hideCalendar() {
                document.getElementsByClassName("cal-wrapper")[0].style.display = 'none';
                this.hidden_calendar = true;
            },
            showCalendar() {
                document.getElementsByClassName("cal-wrapper")[0].style.display = 'block';
                this.hidden_calendar = false;
            },
            hideList() {
                document.getElementsByClassName("events-wrapper")[0].style.display = 'none';
                this.hidden_list = true;
            },
            showList() {
                document.getElementsByClassName("events-wrapper")[0].style.display = 'block';
                this.hidden_list = false;
                this.setListTitle();
            }
        }
    }
</script>
