<template>
    <div class="item-filter-wrapper">
        <div class="item-filters" v-if="group_id === 0">
            <div class="area-filter">
                <div v-if="hasForening" v-on:click="toggle(3)" :class="(filters.includes(3)) ? 'kommun' : 'disabled'">
                    Förening
                </div>
                <div v-if="hasForbund" v-on:click="toggle(2)" :class="(filters.includes(2)) ? 'forbund' : 'disabled'">
                    Förbund
                </div>
                <div v-on:click="toggle(1)" :class="(filters.includes(1)) ? 'riks' : 'disabled'">
                    Riks
                </div>
            </div>
            <div class="group-picker-toggle" v-on:click="toggleGroupPicker" v-if="groups.length > 0" title="Grupper">
                <span class="badge" v-if="groupNotifications.length > 0">{{groupNotifications.length}}</span>
                <i class="mdi mdi-account-supervisor"></i>
            </div>
        </div>

        <div class="group-filter" v-if="group_id > 0">
            <div>
                {{ group_name }}
                <a id="close-group" v-on:click="exitGroup()">
                    <i class="mdi mdi-close"></i>
                </a>
            </div>
        </div>

        <div class="tag-filter" v-if="chosenTag.length > 0">
            <div>
                #{{ chosenTag }}
                <a id="close-tag" v-on:click="exitTag()">
                    <i class="mdi mdi-close"></i>
                </a>
            </div>
        </div>

        <transition name="fade">
            <GroupPicker v-if="display_group_picker" @openGroup="openGroup($event)" :groups="groupsWithNotifications"/>
        </transition>

    </div>
</template>

<script>

    import {mapActions} from "vuex";
    import apiCall from "@/utils/api"
    import GroupPicker from "@/components/GroupPicker";

    export default {
        name: "Areafilter",
        components: {
            GroupPicker
        },
        props: {
          chosenTag: {
              type: String,
              default: ""
          }
        },
        data: function() {
            return {
                kommun: true,
                riks: true,
                forbund: true,
                filters: [0,1,2,3,4],
                group_id: 0,
                group_name: "Okänd",
                display_group_picker: false,
            }
        },
        computed: {
            notifications() {
                return this.$store.getters["user/notifications"];
            },
            groupNotifications(){
                const nonSubscriptionGroups = this.groups.map(g => {
                    if (g.subscription_group === "0") {
                        return g.id;
                    }
                });
                return this.notifications.filter(notification => nonSubscriptionGroups.includes(notification.group) && notification.group > 0 && parseInt(notification.seen) === 0);
            },
            groups(){
                return this.$store.getters["user/groups"];
            },
            groupsWithNotifications() {
                const groups = this.groups.map(function(el) {
                    let object = Object.assign({}, el);
                    object.notifications = 0;
                    return object;
                });

                let groupsWithNotifications = [];

                for(let group of groups){
                    for(let notification of this.groupNotifications){
                        if(notification.group === group.id){
                            group.notifications++;
                        }
                    }
                    groupsWithNotifications.push(group);
                }
                return groupsWithNotifications;
            },
            hasForening() {
                let areas = this.$store.getters["user/allAreas"];
                for(var i in areas) {
                    if(areas[i].level === 3) {
                        return true;
                    }
                }
                return false;
            },
            hasForbund() {
                let areas = this.$store.getters["user/allAreas"];
                for(var i in areas) {
                    if(areas[i].level === 2) {
                        return true;
                    }
                }
                return false;
            }
        },
        created(){
            let current_group = this.$store.getters['user/currentGroup'];
            if(current_group.id > 0) {
                this.group_id = current_group.id;
                this.group_name = current_group.title;
            }
        },
        methods: {
            openGroup(group) {
                this.showLoading("component");
                this.setCurrentGroup(group);
                this.group_id = group.id;
                this.group_name = group.title;
                this.toggleGroupPicker();
                this.$emit('switch', this.filters);
                this.$store.dispatch("items/loadAllItems").then(res => {
                    this.hideLoading("component");
                });
            },
            toggleGroupPicker(){
                this.display_group_picker = !this.display_group_picker;
            },
            toggle(area_level) {
                if(this.filters.includes(area_level) && this.filters.length === 1){
                    this.filters = [0,1,2,3,4];
                } else {
                    if(this.filters.indexOf(area_level) === -1){
                        this.filters.push(area_level);
                    }
                    this.filters = this.filters.filter(area => area === area_level);
                }
                this.$emit('switch', this.filters);
            },
            exitGroup() {
                this.showLoading("component");
                this.group_id = 0;
                this.setCurrentGroup({});
                this.$store.dispatch("items/loadAllItems").then(res => {
                    this.hideLoading("component");
                });
            },
            exitTag(){
              this.$emit("exitTag");
            },
            getActiveAreas() {
                return this.filters;
            },
            getIsAreaActive(area_level) {
                return this.filters.indexOf(area_level) !== -1;
            },
            ...mapActions({
                setCurrentGroup: 'user/setCurrentGroup',
                showLoading: 'loader/show',
                hideLoading: 'loader/hide',
            })
        }
    }
</script>
