<template>
  <div class="file" :class="{ highlight: highlight }" :id="item.slug" >
    <div class="file_options">

      <div class="file_type_icon">
        <a  v-if="item.type === 'file' && item.url != ''" :href="item.url">
          <i v-if="item.type === 'file'" class="mdi mdi-file" :class="itemClass(item.area_level)"></i>
        </a>
        <i v-if="specialEventFile" class="mdi mdi-file kommun"></i>

        <i v-if="item.type === 'folder'" class="mdi mdi-folder" :class="itemClass(item.area_level)" @click="openFolder"></i>

      </div>
    </div>

    <div class="file_details" >
      <a  v-if="item.type === 'file' && item.url != ''" :href="item.url">
        <file-details :item="item"/>
      </a>
      <template v-else >
        <div @click="openFolder">
          <file-details :item="item"/>
        </div>
      </template>
    </div>

    <div v-if="hasAccess && !specialEventFile && itemIsEditable" class="file_more" @click="showMore = !showMore" v-on-clickaway="closeShowMore">
      <img src=".././assets/dots.svg">
      <transition name="popup">
        <ul class="more" v-if="showMore">
          <li v-if="item.type !== 'folder'" @click="markAsChosen">
            {{ markOrUnmark }} som utvald
          </li>
          <template v-if="item.event_folder !== '1'">
          <li @click="openEditModal">
            Redigera
          </li>
          <li @click="openFolderModal">
            Flytta
          </li>
        </template>
          <li @click="$emit('delete', item.id)">
            Ta bort
          </li>
        </ul>
      </transition>
    </div>

    <Modal ref="modal_change" :type="'change-folder'" >
      <template slot="header">
        Välj mapp
      </template>
      <template slot="body">
        <folder-tree v-if="showTree" :item="item" :area="item.area" :type="item.type" @folderSelect="setFolder($event)" :newItem="false"/>
        <div class="modal-buttons">
          <button @click="chooseFolder" class="btn btn-default blue">Välj</button>
          <button @click="closeModal('change')" class="btn btn-default">Avbryt</button>
        </div>

      </template>
    </Modal>
    <Modal ref="modal_edit" :type="'edit-title'">
      <template slot="header">
        Byt namn på {{item.title}}
      </template>
      <template slot="body">
        <input type="text" name="title" id="title" ref="title" v-model="title" placeholder="Titel" />

        <div class="modal-buttons">
          <button @click="editItem" class="btn btn-default blue">Byt namn</button>
          <button @click="closeModal('edit')" class="btn btn-default">Avbryt</button>
        </div>

      </template>

    </Modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import FileDetails from "@/components/FileDetails";
import apiCall from "@/utils/api";
import Modal from "@/components/Modal";
import FolderTree from "@/components/FolderTree";
import { mixin as clickaway } from 'vue-clickaway';

export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    selected: {
      type: Boolean,
      default: false
    },
    highlightID: {
      type: String
    },
    specialEventFile: {
      type: Boolean,
      default: false
    }
  },
  mixins: [clickaway],
  data(){
    return {
      showMore: false,
      folders: [],
      showTree: false,
      selectedFolderID: null,
      title: this.item.title.slice(),
      modalOpen: false
    }
  },
  components: {
    Modal,
    FileDetails,
    FolderTree
  },
  computed: {
    ...mapState({
      user_data: state => state.user.data
    }),
    itemIsEditable(){
      return this.item.title !== 'Dokument' && this.item.title !== 'Möten';
    },
    markOrUnmark(){
      if(this.item.chosen === true){
        return "Avmarkera";
      } else {
        return "Markera";
      }
    },
    id(){
      if(this.highlight){
        return this.item.slug;
      }
      return "";
    },
    highlight(){
      return this.highlightID === this.item.id;
    },
    key() {
      return this.$vnode.key;
    },
    itemClass(area) {
      return area => {
        let area_level = parseInt(area);
        if (area_level === 1) {
          return "riks";
        } else if (area_level === 2) {
          return "forbund";
        } else if (area_level === 3 || area_level === 4) {
          return "kommun";
        } else if(area_level === 0){
          return "group";
        }
        return "";
      };
    },
    currentGroup(){
      return this.$store.getters['user/currentGroup'];
    },
    hasAccess(){
      if(this.item.standard === true) {
        return false;
      }else if(this.item.event_file === '1') {
        return false;
      } else if(this.currentGroup.id > 0 || parseInt(this.item.area_level) === 0){
        return this.item.user === this.user_data.id || this.$store.getters['user/isGroupOwner'](this.currentGroup.id);
      } else {
        let type = this.item.type;
        if(type === 'folder'){
          type = 'file';
        }
        return this.$store.getters['user/hasAccess'](type, this.item.area);
      }
    },
  },
  methods: {
    ...mapActions({
      showLoading: "loader/show",
      hideLoading: "loader/hide"
    }),
    markAsChosen(){
      let data = {
        item_id: this.item.id
      };

      apiCall("/chosen/markAsChosen", { data }).then(res => {
        this.item.chosen = !this.item.chosen;
        //console.log(res);
      });
    },
    select(event) {
      this.$emit("select", event.target.checked, this.item);
    },
    openFolder(){
      if(this.item.type === "folder"){
        this.$emit('openFolder', this.item);
      }
    },
    openEditModal(){
      this.$refs.modal_edit.showModal();
    },
    openFolderModal(){
      this.$refs.modal_change.showModal();
      this.showTree = true;
    },
    closeModal(type){
      if(type === 'edit'){
        this.$refs.modal_edit.close();
      } else {
        this.$refs.modal_change.close();
      }
    },
    closeShowMore(){
      this.showMore = false;
    },
    setFolder(folderID = this.selectedFolderID){
      if(folderID === "Hem"){
        this.selectedFolderID = "0";
      } else {
        this.selectedFolderID = folderID;
      }
    },
    chooseFolder(){
      if(this.selectedFolderID !== null){
        let data = {
          file_id: this.item.id,
          folder_id: this.selectedFolderID,
          type: this.item.type
        };

        apiCall("/item/putItemInFolder", {data}).then(res => {
          if(res === true){
            this.$refs.modal_change.close();
            this.$emit('folderChanged', this.selectedFolderID);
          }
        })

      } else {
        //console.log("Ingen mapp vald")
      }
    },
    editItem(){
        if(this.title.length > 0){
            let data = {
                item_id: this.item.id,
                title: this.title
            };
            apiCall("/item/editItem", {data}).then(res => {
                this.$refs.modal_edit.close();
                this.item.title = this.title;
            })
        }

    }
  }
};
</script>
