<template>
        <div class="post" :class="[itemClass]">

            <div class="post_images" v-if="item.main_image != null">

                <Lightbox :images="allImages"/>
                <!--<div class="images">-->
                    <!--<div class="main-image image-instance">-->
                        <!--<a :href="item.main_image.url">-->
                            <!--<img :src="item.main_image.url + '_medium'" />-->
                        <!--</a>-->
                    <!--</div>-->
                    <!--<template v-if="item.images.length > 0">-->
                        <!--<div class="collage image-instance">-->
                            <!--<div class="image" v-for="(file, index) in item.images" :key="index">-->
                                <!--<a :href="file.url" v-if="file.type == 'image'">-->
                                    <!--<img :title="file.name" :src="file.url + '_medium'" />-->
                                <!--</a>-->
                            <!--</div>-->
                            <!--&lt;!&ndash;<template v-if="item.images.length > 4">&ndash;&gt;-->
                                <!--&lt;!&ndash;<div class="counter">&ndash;&gt;-->
                                    <!--&lt;!&ndash;+{{ imageCount }}&ndash;&gt;-->
                                <!--&lt;!&ndash;</div>&ndash;&gt;-->
                            <!--&lt;!&ndash;</template>&ndash;&gt;-->
                        <!--</div>-->
                    <!--</template>-->
                <!--</div>-->
            </div>

            <div class="post_video" v-if="item.video != null">
                <iframe width="560" height="315" :src="item.video.url" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>

            <div class="post_creator">
                <div class="post_creator_inner">
                    <div class="gravatar">
                        <img :src=" baseUrl + 'avatar/id/' + item.user " />
                    </div>
                    <div>
                        <div class="name">
                            {{ item.creator_name }}
                        </div>
                        <div v-if="item.creator_title != ''" class="title">{{ item.creator_title }}</div>
                        <div class="date">
                            {{ humanDate }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="post_content_wrapper">

                <div class="post_tags">
                    {{ item.area_name }}
                </div>

                <!--<div class="tags">-->
                    <!--<template v-for="tag in this.item.tags" >-->
                        <!--<div :key="tag.id">-->
                            <!--<span class="tag_name">#{{tag.name}}</span>-->

                        <!--</div>-->
                    <!--</template>-->
                <!--</div>-->

                <tags :tags="item.tags" @clicked="$emit('filterTaggedItems', $event)"/>
                <div class="post_header">
                    {{ item.title }} <span v-if="isDraft">(Ej publicerat)</span>
                </div>
                <p class="post_content">
                    <span v-if="(item.excerpt !== null && show_full_text !== true)">
                        <span v-html="item.excerpt"></span>...
                        <div class="post_read_more">
                            <a v-on:click="showFullText()" href="javascript:void(0)">Läs mer</a>
                        </div>
                    </span>
                    <span v-if="(item.excerpt == null || show_full_text === true)">
                        <span v-html="item.html_text"></span>
                        <div class="post_read_more" v-if="show_full_text === true">
                            <a v-on:click="showLessText()" href="javascript:void(0)">Läs mindre</a>
                        </div>
                    </span>
                </p>

                <div class="post_event" v-if="item.event_info">
                    <router-link :to="{ path: '/calendar/' + item.event_info.slug + '/' + 'post-event' }">
                        <CalendarItem :eventID="item.event_info.id" postEvent/>
                    </router-link>
                    <!--<transition name="fade">-->
                        <!--<EventAttend :eventID="item.event_info.id" @setAttend="setAttend($event)"/>-->
                    <!--</transition>-->

                </div>

                <div class="post_link" v-if="item.link != null">
                    <a :href="item.link.url" target="_blank">
                        <div class="post_link_image">
                            <img :src="item.link.preview.cover" />
                        </div>
                        <div class="post_link_content">
                            <img class="link_icon" src="@/assets/link.svg" />
                            <div class="post_link_title">{{ item.link.preview.title }}</div>
                            <a>{{ item.link.url }}</a>
                            <p>{{ item.link.preview.description }}</p>
                        </div>
                    </a>
                </div>

                <div class="post_attachments">
                    <div class="post_reactions" v-if="total_reactions > 0">
                        <img
                                class="reaction"
                                v-for="(reaction) in reactionList"
                                :src="reactionUrl(reaction.name)"
                                :key="reaction.name"
                                @click="onReaction(reaction.name)"/>
                        <div class="reaction-count">  {{ total_reactions }}</div>
                    </div>

                    <div class="files-wrapper" v-if="item.files.length > 0">
                        <button class="attachments" :class="itemClass" @click="showAttachments = !showAttachments">
                            <span>{{item.files.length}}</span>
                            <img src="@/assets/clip.svg" />
                        </button>

                        <transition name="popup">
                            <div class="files" v-if="showAttachments">
                                <ul>
                                    <li v-for="(file, i) in item.files" :key="`A-${i}`">
                                        <span class="file_name">{{ file.name || file.title }}</span>
                                        <a :href="file.url"><button class="attachments">Ladda ner</button></a>
                                    </li>
                                </ul>
                            </div>
                        </transition>


                    </div>
                </div>
            </div>

            <div class="post_tools">
                <div class="post_tools_wrapper reactions">
                    <div class="react-button" v-on:click="show_reactions = !show_reactions" v-show="!item.user_reaction"><i class="mdi mdi-thumb-up"></i> Gilla</div>
                    <div class="react-button" v-on:click="removeReaction" v-show="item.user_reaction">Sluta gilla</div>
                    <transition name="popup">
                        <div class="reaction-popup" v-if="show_reactions">
                            <img class="reaction" v-show="show_reactions" v-for="(reaction, index) in reactions" :src="reactionUrl(reaction)" :key="index + 'r'" @click="onReaction(reaction)"/>
                        </div>
                    </transition>

                </div>

                <div class="post_tools_wrapper" v-if="item.allow_comments">
                    <div class="comments_button">
                        <a href="javascript:void(0)" v-on:click="showComments()">
                            <i class="mdi mdi-comment"></i> Kommentarer ({{ item.comments }})
                        </a>
                    </div>
                </div>

                <div class="post_tools_wrapper" v-if="item.user === user_data.id || item.real_publisher === user_data.id">
                    <div class="dropdown-wrapper">
                        <div class="icon" v-on:click="showChoices = !showChoices" v-on-clickaway="closeChoices">
                            <svg viewBox="0 0 22 6">
                                <path d="M11 5.75C12.5188 5.75 13.75 4.51878 13.75 3C13.75 1.48122 12.5188 0.25 11 0.25C9.48122 0.25 8.25 1.48122 8.25 3C8.25 4.51878 9.48122 5.75 11 5.75Z" />
                                <path d="M19.25 5.75C20.7688 5.75 22 4.51878 22 3C22 1.48122 20.7688 0.25 19.25 0.25C17.7312 0.25 16.5 1.48122 16.5 3C16.5 4.51878 17.7312 5.75 19.25 5.75Z" />
                                <path d="M2.75 5.75C4.26878 5.75 5.5 4.51878 5.5 3C5.5 1.48122 4.26878 0.25 2.75 0.25C1.23122 0.25 0 1.48122 0 3C0 4.51878 1.23122 5.75 2.75 5.75Z" />
                            </svg>
                        </div>
                        <transition name="popup">
                            <div class="dropdown" v-if="showChoices">
                                <ul class="more">
                                    <li v-on:click.prevent="$parent.$parent.$refs.Form.toggleForm(item)"><i class="mdi mdi-pencil"></i>Editera</li>
                                    <li v-show="!suredelete" v-on:click="suredelete = true"><i class="mdi mdi-trash-can"></i>Ta bort</li>
                                    <li v-show="suredelete" v-on:click.prevent="$parent.$parent.deleteItem(item.id, index)" class="background-red suredelete"><i class="mdi mdi-trash-can background-red"></i>Säker?</li>
                                </ul>
                            </div>
                        </transition>

                    </div>
                </div>
            </div>

            <comments v-if="show_comments && item.allow_comments" :comments="comments" :item="item" @postComment="postComment($event)" @removeComment="removeComment" :baseUrl="baseUrl" />

        </div>
</template>

<script>
    import { mapState, mapActions, mapGetters } from "vuex";
    import moment from 'moment';
    import apiCall from "@/utils/api";
    import Tags from "@/components/Tags";
    import Comments from "@/components/Comments";
    import { mixin as clickaway } from 'vue-clickaway';
    import Lightbox from "@/components/Lightbox";
    import CalendarItem from "./CalendarItem";

    export default {
        name: "Listitem",
        components: {
            CalendarItem,
            Tags,
            Comments,
            Lightbox
        },
        mixins: [clickaway],
        computed: {
            isDraft() {
                return this.item.status === 'draft';
            },
            isViewable() {
                if(this.isDraft) {
                    return this.item.user === this.user_data.id;
                } else {
                    return true;
                }
            },
            reactionUrl(url){
                return url => {
                    return require("@/assets/libemo/" + url + ".svg");
                }
            },
            itemClass(){
                let class_string = '';
                let area_level = parseInt(this.item.area_level);
                if(area_level === 1) {
                    class_string = "riks";
                } else if(area_level === 2) {
                    class_string = "forbund";
                } else if(area_level === 3 || area_level === 4) {
                    class_string = "kommun";
                } else if(area_level === 0){
                    class_string = "group";
                }
                if(this.isDraft) {
                    class_string += " draft";
                }
                return class_string;
            },
            ...mapState({
                user_data: state => state.user.data
            }),
            ...mapGetters({
                currentGroup: 'user/currentGroup'
            }),
            humanDate(){
                return moment(this.item.creation_date).locale("sv").format('DD MMMM [kl.] HH:mm');
            },
            reactionList(){
                return this.item.reactions;
            },
            total_reactions() {
                this.componentKey;
                const reactions = this.item.reactions;
                return Object.values(reactions).reduce((acc, val) => {
                    return acc + val.count;
                }, 0);
            },
            // currentGroup(){
            //     return this.$store.getters['user/currentGroup'];
            // },
            allImages(){
                let images = this.item.images.slice();
                images.unshift(this.item.main_image,);
                return images;
            },
            // restricted(){
            //     return this.$store.getters["user/restricted"];
            // }

        },
        props: {
           item: {
             type: Object,
             default: () => {}
           },
            index: {
             type: Number,
             default: () => null
           },
            type: {
             type: String,
             default: () => "post"
           },
        },
        data: function () {
          return {
              showAttachments: false,
              baseUrl: process.env.VUE_APP_API_DOMAIN+"backend/",
              show_full_text: null,
              show_reactions: false,
              showChoices: false,
              comments: [],
              show_comments: false,
              comments_loading: false,
              reactions: ["thumb-up", "angel-face", "cake", "heart", "laugh-17", "malicious", "party-popper", "smile"],
              componentKey: 0,
              suredelete: false
          }
        },
        watch: {
            suredelete(val) {
                let vm = this;
                if(val) {
                    setTimeout(function() {
                        vm.suredelete = false;
                    }, 3000)
                }
            }
        },
        methods: {
            closeChoices(){
                if(!this.suredelete) {
                    this.showChoices = false;
                }
            },
            showComments() {
                this.comments_loading = true;
                let data = {
                  parent: "post",
                  parent_identifier: this.item.id
                };
                apiCall("/comment/getComments", { data }).then(res => {
                    this.comments = res;
                    this.comments_loading = false;
                });
                this.show_comments = !this.show_comments;
            },
            postComment(comment) {
                let vm = this;
                if(comment.length > 0) {
                    let data = {
                        parent: "post",
                        parent_id: this.item.id,
                        comment: comment
                    };
                    apiCall("/comment/makeComment", { data }).then(res => {
                        vm.comment = "";
                        vm.item.comments++;
                        this.comments.push(res);
                    });
                }
            },
            removeComment(id) {
                let data = {
                    id: id
                };
                apiCall("/comment/removeComment", { data }).then(res => {
                    if(!res.error) {
                        this.item.comments--;
                        this.comments = this.comments.filter(obj => {
                            return obj.id !== id
                        });
                        this.$notify({
                            type: 'success',
                            group: 'general',
                            title: 'Tog bort kommentar',
                            text: '',
                            duration: 10000,
                            speed: 1000
                        });
                    }
                });
            },
            showFullText() {
                this.show_full_text = true;
            },
            showLessText() {
                this.show_full_text = false;
            },
            async onReaction(name) {
                await this.saveReactionAction([name, this.item.id, this.type]);
                this.show_reactions = false;
                this.$forceUpdate();
                this.componentKey += 1;
            },
            async removeReaction() {
                if(this.item.user_reaction) {
                    await this.removeReactionAction(this.item.id);
                    this.$forceUpdate();
                    this.componentKey += 1;
                }
            },

            setAttend(answer) {


            },

            ...mapActions({
                removeReactionAction: 'items/removeReaction',
                saveReactionAction: 'items/saveReaction'
            })
        }
    }
</script>

<style lang="scss" scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>
