<template>
    <div class="event-item" :class="{passed: datePassed}" :id="closest ? 'item' + item.id: ''">
        <div class="event-icon">
            <div class="event-icon-inner">
                <div class="icon">
                    <svg viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg" class="kommun">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.91311 0V2.26094H19.2175V0H21.4783V2.26094H23.7391C25.6121 2.26094 27.1304 3.77928 27.1304 5.65224V7.91297V10.1738V22.6088C27.1304 24.4817 25.6121 26.0001 23.7391 26.0001H3.3913C1.51834 26.0001 0 24.4817 0 22.6088V10.1738V7.91297V5.65224C0 3.77928 1.51834 2.26094 3.3913 2.26094H5.65224V0H7.91311ZM19.2175 4.52181V5.65217H21.4783V4.52181H23.7391C24.3635 4.52181 24.8696 5.02792 24.8696 5.65224V7.91297H2.26087V5.65224C2.26087 5.02792 2.76698 4.52181 3.3913 4.52181H5.65224V5.65217H7.91311V4.52181H19.2175ZM24.8696 10.1738V22.6088C24.8696 23.2331 24.3635 23.7392 23.7391 23.7392H3.3913C2.76698 23.7392 2.26087 23.2331 2.26087 22.6088V10.1738H24.8696Z" />
                    </svg>
                </div>
            </div>

        </div>

        <div class="event-text">
            <div class="event-content">
                <div class="event-title">
                    {{item.title}}
                </div>
                <div class="event-address">
                    {{item.venue}}
                </div>
            </div>
            <div class="event-date">
                {{ formatDate(item.start) }} - {{ formatDateToTime(item.end) }}
            </div>
        </div>

    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: "HappeningItem",
        props: {
            item: {
                type: Object
            },
            closest: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            formatDate(value) {
                return value => {
                    let date = moment(String(value)).locale('sv');
                    return date.format('D MMMM HH:mm');
                }
            },
            formatDateToTime(value) {
                return value => {
                    let date = moment(String(value)).locale('sv');
                    return date.format('HH:mm');
                }
            },
            datePassed(){
                return moment(this.item.end).isBefore(moment());
            }
        }
    }
</script>

<style scoped>

</style>
