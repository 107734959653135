<template>
    <div class="special-events" :class="{'no-click': modalOpen}">
        <div class="header" id="header-sticky">
            <div class="header-content">
                <div class="special-events-header">
                    <div class="icon-wrapper">
                        <img src="@/assets/digital-voting-icon.svg" alt="">
                    </div>
                    <div class="content-header">
                        Votering
                    </div>
                </div>
            </div>
            <div class="menu">
                <div class="menu-list" id="scrolling-wrapper">
                    <ul id="scrolling">
                        <li v-for="(menuItem, index) in events"
                            :key="'menu' + index"
                            :class="{active: isActive(index)}"
                            :id="'menu-item' + index"
                            @click="switchView(index, menuItem.id)">
                            <span>{{ menuItem.title }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <transition-group tag="div" name="event-views" class="event-views gutter" :class="isSlidingToPrevious ? 'sliding-to-previous' : ''">
            <div v-for="(event, index) in events" :key="'tab-' + event.id"  class="event-view">
                <digital-voting-event v-if="isActive(index)" :event="event"/>
            </div>
        </transition-group>

        <Loader type="component" />
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Loader from '@/components/Loader';
import DigitalVotingEvent from '@/components/digitalVoting/DigitalVotingEvent';

export default {
    props: {
        id: [Number, String]
    },

    components : {
        Loader,
        DigitalVotingEvent
    },

    data() {
        return {
            current: 0,
            isSlidingToPrevious: false,
            modalOpen: false,
            scroll: false,
            headerBar: null,
            offset: 0
        }
    },

    computed: {
        ...mapGetters({
            events: 'digitalVoting/events',
            eventsLoaded: 'digitalVoting/eventsLoaded',
            eventIsHappening: 'digitalVoting/eventIsHappening',
        })
    },

    watch: {
        eventsLoaded: function(val) {
            if (val && !this.eventIsHappening) {
                this.$router.push('/');
            }

            if (val) {
                this.current = this.events.findIndex((event) => Number(event.id) === Number(this.id));
            }
        },

        eventIsHappening: function (val) {
            if (!val && this.eventsLoaded) {
                this.$router.push('/');
            }
        }
    },

    mounted () {
        if (
            this.$store.getters['digitalVoting/eventsLoaded'] &&
            this.$store.getters['digitalVoting/eventIsHappening'] === false
        ) {
            this.$router.push('/');
        }

        this.headerBar = document.getElementById('header-sticky');
        this.offset = document.getElementById('header-sticky').offsetTop;
        window.addEventListener('scroll', this.handleScroll);

        if (this.id) {
            const queriedIndex = this.events.findIndex((event) => Number(event.id) === Number(this.id));
            if (queriedIndex !== -1) {
                this.current = queriedIndex;
            }
        }
    },

    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
    },

    methods: {
        isActive(viewIndex){
            return this.current === viewIndex;
        },

        switchView(viewIndex, id) {
            this.scrollMenu(viewIndex);
            if(this.current > viewIndex){
                this.isSlidingToPrevious = true;
            }
            this.current = viewIndex;
            this.$router.push('/digitalvoting/' + id);
        },

        scrollMenu(index) {
            const menuItem = document.getElementById('menu-item' + index);

            const leftPosition = menuItem.offsetLeft;
            const container = document.getElementById('scrolling');
            let minusOrPlus = 'minus';

            if (index > this.current) {
                minusOrPlus = 'plus';
            }

            let scrollAmount = container.scrollLeft;
            const slideTimer = setInterval(function() {
                if (minusOrPlus === 'minus') {
                    container.scrollLeft -= 10;
                    scrollAmount -= 10;
                } else {
                    container.scrollLeft += 10;
                    scrollAmount += 10;
                }

                if (
                    (minusOrPlus === 'plus' && scrollAmount >= leftPosition -40) ||
                    (minusOrPlus === 'minus' && scrollAmount <= leftPosition -90)
                ) {
                    clearInterval(slideTimer);
                }
            }, 25);
        },

        addStickyToHeader() {
            if (window.pageYOffset >= this.offset) {
                this.headerBar.classList.add('sticky')
            } else {
                this.headerBar.classList.remove('sticky');
            }
        },

        handleScroll() {
            this.addStickyToHeader();
        },
    },
}
</script>

<style lang="scss">
    @import '../scss/essentials/variables';

    #header-sticky {
        background-color: #fff;
    }
    
    .special-events-header {
        .icon-wrapper {
            height: 40px;
            border-radius: 20px;
            background-color: $lib_green;
        }
    }
</style>
