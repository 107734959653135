<template>
    <div class="campaigns">
        <Loader type="component"/>

        <div class="campaigns-content">
            <h1>Kampanjkartor</h1>
            <div>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
                <p>
                    Klicka på knapparna nedan för att öppna kartor i en extern webbläsare.
                </p>
            </div>
            <button
                v-for="view of View"
                :key="view"
                class="blue"
                @click="requestView(view)"
            >
                {{ viewsTitles[view] }}
            </button>
            <p class="smallText">
                <strong>Observera:</strong> Om du klickar på en länk och inte ser något eller den kräver inloggning, försök att ladda om länkarna.
            </p>
            <button class="campaigns-reloadBtn" @click="loadUrls()">
                <ReloadIcon/>
                Ladda om länkar
            </button>
        </div>

        <a class="campaigns-theLink" :href="urls[requestedView]" target="_blank" ref="proceedLinkEl">&nbsp;</a>

        <Modal ref="confirm">
            <template v-slot:header>
                <div class="header">
                    Du lämnar appen
                </div>
            </template>
            <template v-slot:body>
                <p>
                    <b>{{ viewsTitles[requestedView] }}</b> kommer att öppnas i en extern webbläsare
                </p>
                <div class="button-group">
                    <button class="blue" @click="openRequestedView()">OK</button>
                    <button class="orange" @click="openRequestedView({ persist: true })">Öppna alltid</button>
                    <button @click="cancelViewRequest()">Avbryt</button>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import apiCall from '@/utils/api';
import Loader from '@/components/Loader.vue';
import { mapActions } from 'vuex';
import ReloadIcon from '@/components/icons/ReloadIcon.vue';
import Modal from '@/components/Modal.vue';

const View = Object.freeze({
    Signs: 'signs',
    DoorToDoor: 'door_to_door',
    Forecast: 'forecast',
    // Events: 'events',
});

const titles = {
    [View.Signs]: 'Affischer',
    [View.DoorToDoor]: 'Dörrknackning',
    [View.Forecast]: 'Prognos',
    // [View.Events]: 'Events',
};

let skipLinkConfirmation = false;

export default {
    name: 'campaigns-page',

    components: {
        Loader,
        ReloadIcon,
        Modal,
    },

    data() {
        return {
            View,
            urls: {
                [View.Signs]: '',
                [View.DoorToDoor]: '',
                [View.Forecast]: '',
            },
            viewsTitles: titles,
            requestedView: null,
        };
    },

    mounted() {
        this.loadUrls();
    },

    methods: {

        async loadUrls() {
            await this.showLoading('component');
            await Promise.all(Object.values(View).map(async view => {
                let error = null;
                try {
                    const resp = await apiCall('/user/getUniversumUrl', { data: { view } });
                    if (resp.error) {
                        error = resp.error;
                    } else {
                        this.urls[view] = resp.url;
                    }
                } catch (err) {
                    error = err;
                }
                if (error) {
                    console.warn(error);
                    this.$notify({
                        type: 'error',
                        group: 'general',
                        title: 'Knacka svarar inte just nu',
                        text: 'Det verkar inte vara någon hemma just nu, var god försök senare',
                        duration: 10000,
                        speed: 1000,
                    });
                }
            }));
            await this.hideLoading('component');
        },

        resetUrls() {
            this.urls = {
                [View.Signs]: '',
                [View.DoorToDoor]: '',
                [View.Forecast]: '',
            };
        },

        ...mapActions({
            showLoading: 'loader/show',
            hideLoading: 'loader/hide',
        }),

        async requestView(view) {
            this.requestedView = view;
            if (skipLinkConfirmation) {
                this.$nextTick(() => {
                    this.openRequestedView();
                });
            } else {
                await this.$refs.confirm?.showModal();
            }
        },

        async cancelViewRequest() {
            await this.$refs.confirm?.close();
            this.requestedView = null;
        },

        openRequestedView(args = {}) {
            if (args.persist) {
                skipLinkConfirmation = true;
            }
            this.$refs.proceedLinkEl?.click();
            this.$nextTick(() => {
                this.cancelViewRequest();
            });
        },
    },
};
</script>
