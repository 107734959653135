import localforage from 'localforage';

export default {
    init() {
        localforage.config({
            name: 'intralibStorage',
            storeName: 'global'
        });
    },

    /**
     * Get item from persistent storage
     *
     * @param key
     * @returns {Promise<unknown>}
     */
    getItem(key) {
        return localforage.getItem(key);
    },

    /**
     * Set item to persistent storage
     *
     * @param {string} key Allows alphanumeric + underscore
     * @param value
     * @returns {Promise<*>}
     */
    setItem(key, value) {
        return localforage.setItem(key, value);
    },

    /**
     * Remove an item from persistent storage
     *
     * @param key
     * @returns {Promise<void>}
     */
    removeItem(key) {
        return localforage.removeItem(key);
    }
}
