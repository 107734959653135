<template>
    <div class="special-events" :class="{'no-click': modalOpen}">
        <div class="header" :style="headerBackground" id="header-sticky">
            <div class="header-content">
               <Header />
            </div>
            <div class="menu">
                <div class="menu-list" id="scrolling-wrapper">
                    <ul id="scrolling">
                        <li v-for="(menuItem, index) in pages" :key="'menu' + index" @click="switchView(index, menuItem.id)" :class="{active: isActive(index)}" :id="'menu-item' + index"> <span>{{ menuItem.title }}</span> </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="agenda" v-if="current === 'Agenda'">
            <Agenda />
        </div>

        <transition-group tag="div" name="event-views" class="event-views gutter" :class="isSlidingToPrevious ? 'sliding-to-previous' : ''">
            <div v-for="(page, index) in pages" :key="'tab'+index"  class="event-view">
                <div v-if="current === index">
                    <template v-if="page.title === 'Agenda'">
                        <Agenda :scroll="scroll"/>
                    </template>
                    <EventTemplate v-else :page="page" />
                </div>
            </div>
        </transition-group>

        <PushModal ref="pushModal" @opened="modalOpen = true" @closed="modalOpen = false" @saved="scroll = true"/>
        <Loader type="component" />

    </div>
</template>

<script>

    import Agenda from "@/components/specialEvents/Agenda";
    import EventTemplate from "../components/specialEvents/EventTemplate";
    import Header from "@/components/specialEvents/Header";
    import { mapGetters } from "vuex";
    import PushModal from "@/components/specialEvents/PushModal";
    import VueCookies from "vue-cookies";
    import Loader from "@/components/Loader";


    export default {
        name: "SpecialEvents",
        data() {
            return {
                current: 0,
                isSlidingToPrevious: false,
                modalOpen: false,
                scroll: false,
                headerBar: "",
                offset: ""
            }
        },
        components : {
            Agenda,
            EventTemplate,
            Header,
            PushModal,
            Loader
        },
        computed: {

            ...mapGetters({
                event: 'specialEvents/specialEvent',
                eventIsHappening: 'specialEvents/eventIsHappening',
                headerImage: 'specialEvents/headerImage',
                pages: 'specialEvents/pages',
                title: 'specialEvents/title'
            }),
            eventID(){
              return this.event.id ? this.event.id : "";
            },
            headerBackground(){
                return {
                    // 'background': "linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://www.liberalerna.se/wp-content/uploads/lib_blaklint16.jpg), #C4C4C4"


                        "background-image": `url('${this.headerImage}')`,
                }
            },

            isActive(view){
                return view => {
                    return this.current === view;

                }
            },
        },
        methods: {
            switchView(component, id){
                this.scrollMenu(component);
                if(this.current > component){
                    this.isSlidingToPrevious = true;
                }
                this.current = component;
                if(id){
                    this.$router.push('/specialevents/' + id);
                } else {
                    this.$router.push('/specialevents');
                }

            },
            scrollMenu(index){
                const menuItem = document.getElementById('menu-item' + index);

                const leftPosition = menuItem.offsetLeft;
                const container = document.getElementById('scrolling');
                let minusOrPlus = 'minus';

                if (index > this.current) {
                    minusOrPlus = 'plus';
                }

                let scrollAmount = container.scrollLeft;
                const slideTimer = setInterval(function() {
                    if (minusOrPlus === 'minus') {
                        container.scrollLeft -= 10;
                        scrollAmount -= 10;
                    } else {
                        container.scrollLeft += 10;
                        scrollAmount += 10;
                    }
                    if (minusOrPlus === 'plus' && scrollAmount >= leftPosition -40) {
                        clearInterval(slideTimer);
                    } else if (minusOrPlus === 'minus' && scrollAmount <= leftPosition -90) {
                        clearInterval(slideTimer);
                    }
                }, 25);
            },
            addStickyToHeader(){
                if (window.pageYOffset >= this.offset) {
                    this.headerBar.classList.add("sticky")
                } else {
                    this.headerBar.classList.remove("sticky");
                }
            },
            handleScroll(){
                this.addStickyToHeader();
            },
        },
        watch: {
            'eventID': {
                handler(val) {
                    if(val) {
                        this.$nextTick(async () => {
                            if(!VueCookies.get("has_set_push_for_" + this.eventID)){
                                this.$refs.pushModal.openModal();
                            }
                            if(this.$route.params && this.$route.params.id){
                                const page = this.pages.filter(p => parseInt(p.id) === parseInt(this.$route.params.id))[0];
                                const index = this.pages.indexOf(page);
                                this.current = index;
                            }
                        });
                    }
                },
                immediate: true,
            }
        },

        mounted () {
            if (
                this.$store.getters['specialEvents/eventLoaded'] &&
                this.$store.getters['specialEvents/eventIsHappening'] === false
            ) {
                this.$router.push("/");
            }

            this.headerBar = document.getElementById("header-sticky");
            this.offset = document.getElementById("header-sticky").offsetTop;
            window.addEventListener('scroll', this.handleScroll)
        },
        destroyed () {
            window.removeEventListener('scroll', this.handleScroll);
        },
    }
</script>
