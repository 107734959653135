<template>
    <div class="gutter associations-page">
        <div v-if="availableGroups.length <= 0 && !loading">
            <p>Du har tyvärr inte tillgång till några föreningar.</p>
        </div>
        <div class="groups-wrapper" v-if="availableGroups.length > 0 && !loading">

            <GroupSearch v-if="selected_group_id !== 0 && selected_group_id !== '0'" :members="filteredList(current_filter)" @closed="searching = false" @opened="searching = true"/>
            <div class="groups" v-show="!searching">
                <div class="headline blue">Förening</div>

                <div v-if="!authenticated">
                    <p>Du måste ange ditt lösenord igen för att komma åt dina föreningar.</p>
                    <input type="password" v-model="authentication" placeholder="Lösenord" />
                    <button v-on:click="authenticate()" class="blue"><i class="mdi mdi-shield-lock"></i> <span class="white">Verifiera</span></button>
                </div>
                <div v-if="authenticated">
                    <div v-if="availableGroups.length > 0">

                        <h6 v-if="selected_group_id === 0 || selected_group_id === '0'">Välj förening</h6>
                        <p v-if="selected_group_id === 0 || selected_group_id === '0'">
                            Nedan listas dina föreningar du kan hantera. Välj en i listan.
                        </p>
                        <h6 v-if="selected_group_id !== 0">Vald förening</h6>
                        <div class="select-holder">
                            <select v-model="selected_group_id" v-on:change="loadGroup()">
                                <option value="0">- Välj förening</option>
                                <option v-for="(group, index) in availableGroups" :key="index" :value="group.area">{{ group.name }}</option>
                            </select>
                        </div>
                        <div v-if="selected_group_id !== 0 &&  selected_group_id !== '0' && loading === false">

                            <div class="download-button">
                                <a :href="baseUrl+'api/v1/controller/user/getVoteLengthXLS/id/'+selected_group_id" target="_blank">
                                    <button class="btn blue">Ladda ner medlemslista (XLS)</button>
                                </a>
                            </div>

                            <div class="member-stats">
                                <div class="member-stat">
                                    <h6 class="blue">{{ members.men + members.women + members.unknown }}</h6>
                                    <div class="title">Medlemmar</div>
                                    <div class="kpi">
                                        <div>Kvinnor</div>
                                        <div>{{ members.women }}</div>
                                    </div>
                                    <div class="kpi">
                                        <div>Män</div>
                                        <div>{{ members.men }}</div>
                                    </div>
                                    <div class="kpi">
                                        <div>Okänd</div>
                                        <div>{{ members.unknown }}</div>
                                    </div>
                                </div>
                                <div class="member-stat">
                                    <h6 class="blue">{{ members.new }}</h6>
                                    <div class="title">Nya medlemmar</div>
                                    <div class="kpi">
                                        <div>Värvningsmål {{ year }}</div>
                                        <div>{{ members.goal }}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="group-member-list" v-if="role_list.length > 0">
                                <h4 class="cursor-pointer" v-on:click='showRoles = !showRoles'>{{ (showRoles ? "Dölj" : "Visa") }} interna uppdrag <i :class="(showRoles ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down')"></i></h4>
                                <div class="member-list roles" v-show="showRoles">
                                    <div class="member" v-for="(role, member_index) in role_list" :key="member_index">
                                        <div class="member_role">{{ role.role }}</div>
                                        <div class="member_authority">{{ role.authority }}</div>
                                        <div class="member_role_name">{{ role.name }}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="group-member-list" v-if="role_list_external.length > 0">
                                <h4 class="cursor-pointer" v-on:click='showRolesExternal = !showRolesExternal'>{{ (showRolesExternal ? "Dölj" : "Visa") }} externa uppdrag <i :class="(showRolesExternal ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down')"></i></h4>
                                <div class="member-list roles" v-show="showRolesExternal">
                                    <div class="member" v-for="(role_external, member_index) in role_list_external" :key="member_index">
                                        <div class="member_role">{{ role_external.mission }}</div>
                                        <div class="member_authority">{{ role_external.authority }}</div>
                                        <div class="member_role_name">{{ role_external.name }}</div>
                                    </div>
                                </div>
                            </div>

                            <h6>Filter</h6>
                            <div class="select-holder">
                                <select v-model="current_filter">
                                    <option v-for="(filter, index) in filters" :key="index" :value="filter.id">{{ filter.title }} <span>({{filteredListTotal(filter.id)}})</span></option>
                                </select>
                            </div>

                            <div class="group-member-list" >
                                    <div class="member-list" v-for="(area, index) in filteredList(current_filter)" :key="index + 'area'">
                                        <template v-if="area.filteredMembers.length > 0">
                                            <h4>{{area.name}}</h4>
                                            <group-member v-for="member in area.filteredMembers" :key="member.member_no" :member="member"/>
                                        </template>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Loader type="component" />

                <div class="create_post">
                    <Modal ref="modal_group_action">
                        <template slot="header">
                            <div class="header">Särskilt meddelande</div>
                        </template>
                        <template slot="body">
                            <form>
                                <div class="areas-wrapper">
                                    <div class="content-header">Skicka meddelande till särskilda urval.
                                        <p>OBS. Använd inte denna funktion för att skriva till alla medlemmar eller grupper som finns i appen</p></div>
                                    <div class="radio-group">
                                        <div class="custom-radio">
                                            <input type="radio" class="radio" id="mail" v-model="send_type" name="send_type" value="email"/>
                                            <label for="mail">
                                                <div class="area-title">Mejl</div>
                                            </label>
                                            <label for="mail" class="radio-gfx">
                                                <label for="mail" class="circle-wrapper"></label>
                                            </label>
                                        </div>
                                        <div class="custom-radio">
                                            <input type="radio" class="radio" id="sms" v-model="send_type" name="send_type" value="sms"/>
                                            <label for="sms">
                                                <div class="area-title">Sms</div>
                                            </label>
                                            <label for="sms" class="radio-gfx">
                                                <label for="sms" class="circle-wrapper"></label>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="publish-as-holder" v-if="myUserToPublishAs.length > 0 && send_type === 'sms'">
                                        <span>Publicera&nbsp;som:&nbsp;</span>
                                        <select v-model="publishAs">
                                            <option value="">Mig själv</option>
                                            <option v-for="(person, index) in myUserToPublishAs" :key="'person' + index" :value="person.member_id">{{ person.name }}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="form-title" :class="{'top-padding' : send_type === 'sms'}">

                                    <div class="smart-field inputs" v-if="send_type === 'email'">
                                        <input type="text" name="group_message_subject" id="group_message_subject" v-model="subject" placeholder=" " />
                                        <label for="group_message_subject" class="mandatory">Rubrik</label>
                                    </div>
                                    <ResizableTextarea>
                                        <textarea rows="1" v-model="message" placeholder="Skriv ditt meddelande här... " @keyup="checkMessageLength"></textarea>
                                    </ResizableTextarea>

                                </div>
                                <transition name="fade">
                                    <div v-if="messageExceeding && send_type === 'sms'" class="areas-wrapper message-exceeding">
                                        <div class="content-header"> OBS. Meddelandet överskrider längden för ett sms. Du kan fortfarande skicka meddelandet, men extra kostnader kan tillkomma.</div>
                                    </div>
                                </transition>

                                <div v-if="send_type === 'email'" class="share-controls">
                                    <file-upload :uploadedFiles="uploadedfiles" ref="files" type="attachment"></file-upload>
                                </div>

                                <div class="receivers">
                                    <p>Meddelandet kommer att skickas till följande personer <strong>({{receivers.length}} stycken)</strong></p>
                                    <p><a v-on:click="checkAllReceivers">Välj alla</a> / <a v-on:click="uncheckAllReceivers">Avmarkera alla</a></p>
                                    <div class="receiver-list">
                                        <div class="receiver" v-for="member in filteredSendList" :key="member.member_no">
                                            <input type="checkbox" :id="'send_to_'+member.member_no" v-model="receivers" :value="member.member_no" />
                                            <label :for="'send_to_'+member.member_no">{{ member.name }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="create_submit">
                                    <button type="button" class="btn blue" v-on:click="send(false)" v-bind:disabled="sending">Skicka meddelande</button>
                                </div>
                            </form>
                        </template>
                    </Modal>

                    <Modal ref="modal_group_confirm">
                            <template slot="header">
                                <div class="header">Viktigt</div>
                            </template>
                            <template slot="body">
                                <form>
                                    <div class="agreement">
                                        <div class="text-message" v-if="send_type === 'sms'">
                                            <div class="pale-header">Priset är 30 öre per SMS.</div>
                                            <p>När du klickar på "Skicka meddelande" godkänner du samtidigt att kostnaden faktureras din förening/förbund. Fakturering sker halvårsvis.</p>
                                        </div>
                                        <div class="text-message" v-if="send_type === 'email'">
                                            <p>Om du vill skicka meddelande till alla medlemmar eller en grupp som finns i appen: skriv istället ett inlägg i flödet. Det kommer också att skickas som mejl, är enkelt att hitta i appen, och går att kommentera.
                                                Den här funktionen är framför allt till för att skicka meddelanden till särskilda urval som t ex nya medlemmar eller medlemmar som inte betalt årets avgift.</p>
                                        </div>
                                        <div class="buttons-wrapper">
                                            <button v-on:click="send(true)" type="button"  class="btn blue"><i class="mdi mdi-check"></i> Jag godkänner</button>
                                            <button v-on:click="$refs.modal_group_confirm.close()" type="button"  class="btn"><i class="mdi mdi-close-circle"></i> Avbryt</button>
                                        </div>
                                    </div>
                                </form>
                            </template>
                    </Modal>
                </div>
                <div class="create_button" v-if="selected_group_id > 0" v-on:click="toggleForm">
                    <img src="../assets/plus.svg" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {mapActions} from "vuex";
    import Loader from "@/components/Loader"
    import apiCall from "@/utils/api"
    import Modal from "@/components/CreateModal";
    import GroupMember from "@/components/GroupMember";
    import FileUpload from "@/components/FileUpload";
    import GroupSearch from "@/components/GroupSearch";
    import ResizableTextarea from '@/utils/ResizableTextarea';

    // import moment from 'moment';

    export default {
        name: "Associations",
        components: {
            Loader,
            Modal,
            GroupMember,
            FileUpload,
            GroupSearch,
            ResizableTextarea
        },
        computed: {
            authenticated() {
                return this.$store.getters["user/isAdminAuthenticated"];
            },
            filteredListTotal(filter){
                return filter => {
                    return this.filteredList(filter).map(area => {
                        return area.filteredMembers.length;
                    }).reduce((a,b) => a + b, 0);
                };
            },
            filteredSendList(){

                let memberList = this.filteredList(this.current_filter).map(area => {
                    return area.filteredMembers;
                });
                memberList = [].concat.apply([], memberList);
                return memberList.filter(member => {
                    if(this.send_type === "email"){
                        return member.email && member.email.length > 0;
                    } else if(this.send_type === "sms"){
                        return member.mobile && member.mobile.length > 0;
                    }
                });
            },
            filteredList(filter){
                return filter => {
                    return this.member_list.map((area) =>{
                        if(filter !== "none"){
                            area.filteredMembers = area.members.filter((member) => {
                                if(filter === "unpaid"){
                                    return !member.paid
                                } else if(filter === "new"){
                                    return member.new;
                                } else if(filter === "noEmail") {
                                    return !member.email || member.email.length <= 0;
                                }
                            });
                            return area;
                        }
                        else {
                            area.filteredMembers = [...area.members];
                        }
                        return area;
                    });
                }
            },
          availableGroups() {
              let groups = this.$store.getters['user/availableAreas']("admin");
              groups.sort(function(a, b){
                  if(a.name < b.name) { return -1; }
                  if(a.name > b.name) { return 1; }
                  return 0;
              });
              return groups;
          },
            myUserToPublishAs() {
                return this.$store.getters["user/myUserToPublishAs"];
            },
        },
        data: function() {
            return {
                authentication: "",
                loading: false,
                selected_group_id: 0,
                year: new Date().getFullYear().toString(),
                members: {
                    men: 0,
                    women: 0,
                    new: 0,
                    goal: 0,
                    unknown: 0
                },
                member_list: [],
                role_list: [],
                role_list_external: [],
                current_filter: "none",
                filters: [
                    {
                        id: "none",
                        title: "Inget filter"
                    },
                    {
                        id: "unpaid",
                        title: "Obetald"
                    },
                    {
                        id: "new",
                        title: "Ny medlem (Sen 1:a januari)"
                    },
                    {
                        id: "noEmail",
                        title: "Saknar mejladdress"
                    },
                ],
                // Message data
                send_type: "email",
                subject: "",
                message: "",
                receivers: [],
                sending: false,
                uploadedfiles: [],
                searching: false,
                showRoles: false,
                showRolesExternal: false,
                baseUrl: process.env.VUE_APP_API_DOMAIN+"backend/",
                messageExceeding: false,
                selectedAreaTitle: "",
                publishAs: null
            }
        },
        mounted() {

        },
        methods: {
            checkMessageLength(){
                this.messageExceeding = this.message.length > 160;
            },
            checkAllReceivers() {
                // let receivers = this.filteredSendList.map(area => {
                //     return area.filteredMembers.map(member => member.member_no);
                // });
                let receivers = this.filteredSendList.map(member => member.member_no);
                //Flatten array
                this.receivers = [].concat.apply([], receivers);
            },
            uncheckAllReceivers() {
                this.receivers = [];
            },
            authenticate() {
                this.showLoading("component");
                let data = {
                    password: this.authentication,
                };

                apiCall("/user/authenticateAdmin", {data}).then(res => {
                    this.hideLoading("component");
                    if(typeof(res.success) !== "undefined" && res.success === true) {
                        this.$store.dispatch("user/setAdminAuthenticated", true);
                    } else {
                        this.$notify({
                            type: 'error',
                            group: 'general',
                            title: 'Fel lösenord',
                            text: 'Vi kunde inte verifiera ditt lösenord.',
                            duration: 10000,
                            speed: 1000
                        });
                    }
                });
            },
            send(approve) {
                if(!approve) {
                    let valid = true;
                    let errors = [];

                    if(this.send_type === "email") {
                        if(this.subject === "") {
                            valid = false;
                            errors.push("Du behöver ange ett ämne");
                        }
                    }
                    if(this.message === "") {
                        valid = false;
                        errors.push("Du måste skriva ett meddelande");
                    }
                    if(this.receivers.length <= 0) {
                        valid = false;
                        errors.push("Du måste minst ange en person att skicka till");
                    }
                    if(valid) {
                        this.$refs.modal_group_confirm.showModal();
                    } else {
                        this.$notify({
                            type: 'error',
                            group: 'general',
                            title: 'Du har inte fyllt i alla fält',
                            text: 'Vänligen rätta nedan: <br>'+errors.join("<br>"),
                            duration: 10000,
                            speed: 1000
                        });
                    }
                } else {
                    this.$refs.modal_group_action.close();
                    this.$refs.modal_group_confirm.close();
                    this.showLoading("component");

                    if(this.send_type === "sms") {
                        this.subject = "";
                    }

                    let data = {
                        area_id: this.selected_group_id,
                        subject: this.subject,
                        message: this.message,
                        receivers: this.receivers,
                        type: this.send_type,
                        files: (this.send_type === 'email') ? this.$refs.files.getFilesData() : [],
                        publish_as: this.publishAs,
                        group: false
                    };

                    this.sendMessage(data);
                }
            },
            toggleForm() {
                this.receivers = [];
                this.$refs.modal_group_action.showModal();
            },
            loadGroup() {
                if(this.selected_group_id > 0) {
                    this.loading = true;
                    this.showLoading("component");
                    let data = {
                        area_id: this.selected_group_id
                    };
                    apiCall("/user/getAreaInformation", {data}).then(res => {
                        this.loading = false;
                        this.hideLoading("component");
                        if (res.error) {
                            this.$notify({
                                type: 'error',
                                group: 'general',
                                title: 'Kunde inte ladda förening',
                                text: 'Du har inte tillgång till vald förening',
                                duration: 10000,
                                speed: 1000
                            });
                            this.selected_group_id = 0;
                        } else {
                            this.selectedAreaTitle = res.title;
                            this.member_list = [];
                            this.members.goal = res.goal;
                            this.members.women = res.women;
                            this.members.men = res.men;
                            this.members.new = res.new;
                            this.members.unknown = res.unknown;
                            for (let i in res.users_in_area) {
                                this.member_list.push({
                                    name: i,
                                    members: res.users_in_area[i]
                                });
                            }
                            this.role_list = [];
                            for (let i in res.roles) {
                                this.role_list.push({
                                    name: res.roles[i]["member_title"],
                                    role: res.roles[i]["membershiprole"],
                                    authority: res.roles[i]["authority"]
                                });
                            }
                            this.role_list_external = [];
                            for (let i in res.roles_external) {
                                this.role_list_external.push({
                                    name: res.roles_external[i]["member_title"],
                                    mission: res.roles_external[i]["mission"],
                                    authority: res.roles_external[i]["authority"]
                                });
                            }
                        }
                    });
                } else {
                    // Do nothing..
                }
            },
            ...mapActions({
                userLoggedIn: 'user/userLoggedIn',
                showLoading: 'loader/show',
                hideLoading: 'loader/hide',
                sendMessage: 'user/sendMessage'
            })
        }
    }
</script>
