<template>
    <div>
        <div class="special-events gutter" v-if="item">
            <div class="special-event-item-view post">
                <div class="header">
                    <div class="return-button">
                        <router-link :to="{path: '/specialevents'}">
                            <i class="mdi mdi-chevron-left"></i>
                            <span>Gå tillbaka</span>
                        </router-link>
                    </div>
                    <div class="header-content">
                        <Header happeningView/>
                    </div>
                </div>
                <div class="post_images" v-if="item.image_data">
                    <div class="images">
                        <div class="main-image image-instance">
                            <img :src="item.image_data" :alt="item.image_name">
                        </div>
                    </div>
                </div>
                <div class="post_content_wrapper description">
                    <div class="icon">
                        <svg viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg" class="kommun">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.91311 0V2.26094H19.2175V0H21.4783V2.26094H23.7391C25.6121 2.26094 27.1304 3.77928 27.1304 5.65224V7.91297V10.1738V22.6088C27.1304 24.4817 25.6121 26.0001 23.7391 26.0001H3.3913C1.51834 26.0001 0 24.4817 0 22.6088V10.1738V7.91297V5.65224C0 3.77928 1.51834 2.26094 3.3913 2.26094H5.65224V0H7.91311ZM19.2175 4.52181V5.65217H21.4783V4.52181H23.7391C24.3635 4.52181 24.8696 5.02792 24.8696 5.65224V7.91297H2.26087V5.65224C2.26087 5.02792 2.76698 4.52181 3.3913 4.52181H5.65224V5.65217H7.91311V4.52181H19.2175ZM24.8696 10.1738V22.6088C24.8696 23.2331 24.3635 23.7392 23.7391 23.7392H3.3913C2.76698 23.7392 2.26087 23.2331 2.26087 22.6088V10.1738H24.8696Z" />
                        </svg>
                    </div>
                    <div class="content-header">
                        {{item.header}}
                    </div>
                    <div class="date">
                        {{ formatDate(item.start) }} - {{ formatDate(item.end) }}
                    </div>
                    <div class="address">
                        {{item.venue}}
                    </div>
                </div>
                <div class="post_content_wrapper">
                    <div class="content-header orange">
                        {{ title }}
                    </div>
                    <div class="event-text" v-html="item.description">
                    </div>
                </div>

                <div class="files" v-if="files.length > 0" :class="{single: files.length === 1}">
                    <div class="pale-header">Bifogade filer</div>
                    <template v-for="file in files">
                        <a :href="file.url" :key="file.id">
                            <FileItem :item="file" specialEventFile/>
                        </a>
                    </template>
                </div>
            </div>
        </div>
        <Loader type="component" />
    </div>
</template>

<script>
    import FileItem from "@/components/Fileitem";
    import Header from "@/components/specialEvents/Header";
    import { mapGetters } from "vuex";
    import moment from 'moment';
    import Loader from "@/components/Loader";

    export default {
        name: "HappeningView",
        data(){
            return {
                baseUrl: process.env.VUE_APP_API_DOMAIN+"backend/api/v1/controller/specialevent/file?type=happening&id=",
            }
        },
        components: {
            FileItem,
            Header,
            Loader
        },
        computed: {
            formatDate(value) {
                return value => {
                    let date = moment(String(value)).locale('sv');
                    return date.format('D MMMM HH:mm');
                }
            },

            ...mapGetters({
                happeningItems: "specialEvents/happeningItems",
                title: "specialEvents/title",
                eventIsHappening: "specialEvents/eventIsHappening"
            }),

            files(){
                return this.$store.getters["specialEvents/files"](this.item.id, "happening").map(file => {
                    file.url = this.baseUrl + file.id;
                    return file;
                });
            },

            item(){
                return this.happeningItems.filter(h => parseInt(h.id) === parseInt(this.$route.params.id))[0];
            }
        },

        mounted() {
            if (
                this.$store.getters['specialEvents/eventLoaded'] &&
                this.$store.getters['specialEvents/eventIsHappening'] === false
            ) {
                this.$router.push("/");
            }
        }
    }
</script>

<style scoped>

</style>
