import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/';
import Notifications from 'vue-notification';
import persistentStorage from './utils/persistentStorage';
import '@mdi/font/css/materialdesignicons.css';
import Vue2TouchEvents from 'vue2-touch-events';
import 'vue-event-calendar/dist/style.css';
import vueEventCalendar from 'vue-event-calendar';
import Datetime from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import vClickOutside from 'v-click-outside';
import VueCookies from 'vue-cookies';
import pushToken from './utils/pushToken';
import VueGtag from 'vue-gtag';
import { Settings } from 'luxon';

if (process.env.VUE_APP_GA_KEY) {
    Vue.use(VueGtag, {
        config: {
            id: process.env.VUE_APP_GA_KEY,
        },
    }, router);
}

persistentStorage.init();
Vue.prototype.$persistentStorage = persistentStorage;

Vue.use(Vue2TouchEvents);

Vue.use(vueEventCalendar, {
    locale: 'sv',
    weekStartOn: 1,
});

Vue.use(Datetime);
Vue.use(vClickOutside);

Settings.defaultLocale = 'sv';

Vue.config.productionTip = false;
Vue.use(Notifications);

window.registerAppPushToken = pushToken.register;

router.beforeEach(async (to, from, next) => {
    const persu = await persistentStorage.getItem('persu');
    const userId = await persistentStorage.getItem('uuid');
    
    if (!pushToken._registered &&
        persu &&
        userId &&
        to.name !== 'register' &&
        to.name !== 'login'
    ) {
        pushToken.store(userId);
    }
    
    if (to.name !== 'login' && to.name !== 'register' && to.name !== 'registerResult' && to.name !== 'loginActivation' && !persu) {
        next('/login');
    } else if ((to.name === 'login' || to.name === 'register') && persu) {
        next('/');
    } else {
        next();
    }
});

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
