import apiCall from '@/utils/api';

export default {
    namespaced: true,
    state: {
        event: {
            header_color: "",
            happenings: [],
            pages: []
        },
        eventHappening: false,
        eventLoaded: false,
        files: {
            happening_files: [],
            page_files: []
        }
    },
    getters: {
        eventIsHappening(state){
            return state.eventHappening;
        },
        eventLoaded(state){
            return state.eventLoaded;
        },
        specialEvent(state){
            return state.event;
        },
        happenings(state){
            return state.event.happenings;
        },
        title(state){
            return state.event.title;
        },
        headerColor(state){
            if(state.event.header_color){
                return state.event.header_color;
            }
        },

        pages(state){
            if(state.event.pages){
                state.event.pages.unshift({title: "Agenda"});
                return state.event.pages;
            }
            return [];
        },

        happeningItems(state){
            if(state.event.happenings){
                const happenings = state.event.happenings.filter(h => h.happenings.length > 0);
                return happenings.map(h => h.happenings).flat();
            }
            return [];
        },

        headerImage(state){
            if(state.event.background_image && state.event.background_image.file_data){
                return state.event.background_image.file_data;
            }
        },

        files(state){
            return (id, type) => {
                let fileType = "happening_files";
                let idType = "happening_id";

                if(type === "page"){
                    fileType = "page_files";
                    idType = "page_id";
                }

                const files =  state.files[fileType].filter(f => {
                    return f[idType] === id;
                })[0];

               if(files){
                   return files.files
               }
               return [];
            }
        },
    },
    actions: {
        async getEvent({ commit, state, getters, dispatch, rootGetters }) {

            if(getters.eventIsHappening === false){
                dispatch('loader/show', "component", {root: true});
            }

            let eventRequest;
            try {
                eventRequest = await apiCall('/user/getSpecialEvent');
            } catch (err) {
                console.log("API ERROR");
            }

            if(eventRequest && (eventRequest.hasOwnProperty('happenings'))){
            // if (eventRequest && (Object.entries(eventRequest).length > 0 && eventRequest.constructor === Object)){
                commit("SET_EVENT_HAPPENING", true);
                commit("SET_EVENT", eventRequest);
                dispatch("getSpecialEventFiles");

            } else {
                //Event is not happening
                commit("SET_EVENT_HAPPENING", false);
            }

            commit('SET_EVENT_LOADED', true);
            if(rootGetters["loader/isLoading"]){
                dispatch('loader/hide', "component", {root: true});

            }
        },
        async getSpecialEventFiles({commit, getters}){
            let eventFiles;
            let id = getters["specialEvent"].id;
            if(id) {
                try {
                    eventFiles = await apiCall('/user/getSpecialEventFiles', { data: { id: parseInt(id)}});
                } catch (err) {
                    console.log("API ERROR");
                }

                if(eventFiles){
                    commit("SET_FILES", eventFiles)
                }
            }
        }
    },
    mutations: {
        SET_EVENT_HAPPENING(state, data){
            state.eventHappening = data;
        },
        SET_EVENT_LOADED(state, data) {
            state.eventLoaded = data;
        },
        SET_EVENT(state, data){
            state.event = data;
        },
        SET_FILES(state, files){
            state.files = files;
        }
    }

}
