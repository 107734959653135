export default {
    namespaced: true,

    state: {
        elements: {
            global: false,
            component: false
        },
        globalLoading: 0,
        componentLoading: 0
    },

    mutations: {
        HIDE_COMPONENT_LOADING(state){
            if(state.componentLoading){
                state.componentLoading--;
            }

            if (!state.componentLoading) {
                state.elements.component = false;
            }
        },
        SHOW_COMPONENT_LOADING(state){
            state.componentLoading++;
            state.elements.component = true;
        },
        HIDE_GLOBAL_LOADING(state){
            if(state.globalLoading){
                state.globalLoading--;
            }

            if (!state.globalLoading) {
                state.elements.global = false;
            }
        },
        SHOW_GLOBAL_LOADING(state){
            state.globalLoading++;
            state.elements.global = true;
        }
    },

    getters: {
        isLoading(state) {
            return state.elements.global || state.elements.component;
        }
    },

    actions: {
        show({ commit, state }, payload) {
            // console.log("SHOW", payload);
            if(payload === "component"){
                commit("SHOW_COMPONENT_LOADING");
            } else if(payload === "global"){
                commit("SHOW_GLOBAL_LOADING");
            }

            // if (Object.keys(state.elements).includes(payload)) {
            //
            //
            // }
            //
            //
            // if (Object.keys(state.elements).includes(payload) && state[payload] !== true) {
            //
            //     let res = {
            //         global: state.elements.global,
            //         component: state.elements.component
            //     };
            //
            //     if (res[payload] !== true) {
            //         res[payload] = true;
            //     }
            //     commit('LOADING', res);
            // }
        },

        hide({ commit, state }, payload) {
            // console.log("HIDE", payload);
            if(payload === "component"){
                commit("HIDE_COMPONENT_LOADING");
            } else if(payload === "global"){
                commit("HIDE_GLOBAL_LOADING");
            }

            // if (Object.keys(state.elements).includes(payload)) {
            //
            //     if(payload === "component" && state.componentLoading){
            //         commit("HIDE_COMPONENT_LOADING");
            //     } else if(payload === "global"){
            //         commit("HIDE_GLOBAL_LOADING");
            //     }
            //
            //
            //     let res = {
            //         global: state.elements.global,
            //         component: state.elements.component
            //     };
            //
            //     if (res[payload] !== false) {
            //         res[payload] = false;
            //     } else if (payload === 'all') {
            //         res.global = false;
            //         res.component = false;
            //     }
            //
            //     commit('LOADING', res);
            // }
        },
    }
};
