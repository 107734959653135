<template>
    <div class="special-events-header">
        <FlagAnimation />
        <div class="content-header" :style="headerStyle">
            {{ header }}
        </div>
    </div>

</template>

<script>
    import FlagAnimation from "./FlagAnimation";

    export default {
        name: "Header",
        props: {
            happeningView: {
                type: Boolean,
                default: false
            }
        },
        components: {
            FlagAnimation,
        },
        computed: {
            header() {
                return this.$store.getters["specialEvents/title"];
            },
            headerStyle(){
                return {
                    "color": this.headerColor
                }
            },
            headerColor(){
                if(this.happeningView){
                    return "#000000";
                } else {
                    return this.$store.getters["specialEvents/headerColor"] === "black" ? "#000000" : "#FFFFFF";
                }
            }
        }
    }
</script>
