<template>
    <div class="gutter">
        <div class="headline blue">Prenumerera</div>
        <p>Nedan hittar du prenumerationsgrupper som du kan följa. Dom kommer då dyka upp i ditt flöde.</p>
        <p v-if="subscriptionGroups.length <= 0" class="muted">Här finns inga prenumerationsgrupper ännu, men det kommer nog snart!</p>
        <template v-for="group in subscriptionGroups">
            <div class="letter" :key="group.id + 'subscription'">
                <div>
                    <div class="custom-checkbox-wrapper">
                        <div class="custom-letter-checkbox">
                            <input type="checkbox" :id="'group' + group.id" :value="group.id" :checked="group.subscribed" @click="group.subscribed = !group.subscribed" v-on:change="saveSettings"/>
                            <label :for="'group' + group.id">
                                <div class="area-title">{{ group.title }}</div>
                                <div class="area-members">{{ group.description }}</div>
                            </label>
                            <label :for="'group' + group.id" class="radio-gfx">
                                <label :for="'group' + group.id" class="circle-wrapper"></label>
                            </label>

                        </div>
                        <div class="com-settings" v-if="group.subscribed && com_settings_loaded">
                            <p>Kommunikationsinställningar för prenumeration</p>
                            <div class="settings">
                                <div class="mini-custom-checkbox">
                                    <input type="checkbox" :id="'group_push' + group.id" v-model="communication_settings['pren_group_'+group.id].push" v-on:change="saveNotificationSettings(group.id, 'push')">
                                    <label :for="'group_push' + group.id">
                                        <div class="mini-title">
                                            Push
                                        </div>
                                    </label>
                                    <label :for="'group_push' + group.id" class="radio-gfx">
                                        <label :for="'group_push' + group.id" class="circle-wrapper"></label>
                                    </label>
                                </div>
                                <div class="mini-custom-checkbox">
                                    <input type="checkbox" :id="'group_email' + group.id" v-model="communication_settings['pren_group_'+group.id].email" v-on:change="saveNotificationSettings(group.id, 'email')">
                                    <label :for="'group_email' + group.id">
                                        <div class="mini-title">
                                            Mejl
                                        </div>
                                    </label>
                                    <label :for="'group_email' + group.id" class="radio-gfx">
                                        <label :for="'group_email' + group.id" class="circle-wrapper"></label>
                                    </label>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </template>
        <div class="load_wrapper">
            <Loader type="component"/>
        </div>
    </div>
</template>


<script>
    import {mapState} from "vuex";
    import apiCall from "@/utils/api"
    import { mapActions } from "vuex";
    import Loader from "@/components/Loader";

    export default {
        name: "Newsletter",
        data(){
            return {
                selectedNewsletters: [],
                newsletters: [],
                unSelectedNewsletters: [],
                error: "",
                communication_settings: [],
                com_settings_loaded: false
            }
        },
        components: {
            Loader
        },
        computed: {
            ...mapState({
                user_data: state => state.user.data
            }),
            subscriptionGroups(){
                return this.$store.getters["user/subscriptionGroups"];
            }
        },

        methods: {
            ...mapActions({
                showLoading: "loader/show",
                hideLoading: "loader/hide"
            }),
            select(e, subscribed){
                if(e.target.checked){
                    this.unSelectedNewsletters = this.unSelectedNewsletters.filter(letter => letter !== e.target.value);
                    if(!subscribed){
                        this.selectedNewsletters.push(e.target.value);
                    }
                } else {
                    this.selectedNewsletters = this.selectedNewsletters.filter(letter => letter !== e.target.value);
                    if(subscribed){
                        this.unSelectedNewsletters.push(e.target.value);
                    }
                }
            },
            subscribe(endPoint) {
                const list = endPoint === "subscribeList" ? this.selectedNewsletters : this.unSelectedNewsletters;

                for(let letter of list){
                    let data = {
                        id: letter
                    };
                    apiCall("/user/" + endPoint, {data}).then(res => {
                        if(res.data === false){
                            this.error = "Ett fel har uppstått, försök igen";
                        } else {
                            apiCall("/user/getMailingLists", {}).then(res => {
                                this.newsletters = res;
                            });
                        }
                    });
                }
            },
            saveSettings(){
                if(this.selectedNewsletters.length > 0){
                    this.subscribe('subscribeList');
                }
                if(this.unSelectedNewsletters.length > 0) {
                    this.subscribe('unSubscribeList');
                }
                if(this.subscriptionGroups.length > 0) {
                    let data = {
                        member_no: this.user_data.id,
                        groups: this.subscriptionGroups
                    };
                    this.showLoading("component");
                    apiCall("/user/subscribeToGroup", {data}).then(res => {
                        this.$parent.doHeartBeat(true);
                        this.$store.dispatch("items/loadItems", "post").then(res => {
                            this.hideLoading("component");
                        })
                        this.$store.dispatch("user/setGroups", this.subscriptionGroups);
                    });
                }
            },
            saveNotificationSettings(group_id, type) {
                let data = {
                    area_id: "pren_group_"+group_id,
                    email: this.communication_settings['pren_group_'+group_id].email,
                    push: this.communication_settings['pren_group_'+group_id].push
                };
                apiCall("/user/saveNotificationSettings", { data });
            },
            updateCommunicationSettings(){
                this.com_settings_loaded = false;
                for(let i in this.subscriptionGroups) {
                    // Make sure com settings exists
                    if(typeof this.communication_settings["pren_group_"+this.subscriptionGroups[i].id] === "undefined") {
                        this.communication_settings["pren_group_"+this.subscriptionGroups[i].id] = {
                            email: false,
                            push: false,
                            area: "pren_group_"+this.subscriptionGroups[i].id
                        };
                    }
                }
                this.com_settings_loaded = true;
            }
        },
        created() {
            this.showLoading("component");
            apiCall("/user/getNotificationSettings").then(res => {
                this.hideLoading("component");
                this.communication_settings = res;
                this.updateCommunicationSettings();
            });
        },
    }
</script>
