<template>
    <div class="communication-item">
        <div class="communication-title">
            {{ item.name || item.title }}
        </div>
        <div class="communication-checkbox" :class="checkedClass('email')">
            <input type="checkbox" :id="inputLabel + '-email'" v-model="settings.email" v-on:change="saveNotificationSettings" />
            <label :for="inputLabel + '-email'"></label>
        </div>
        <div :class="borderClass"></div>
        <div class="communication-checkbox" :class="checkedClass('push')">
            <input type="checkbox" :id="inputLabel + '-push'" v-model="settings.push" v-on:change="saveNotificationSettings"  />
            <label :for="inputLabel + '-push'"></label>
        </div>
    </div>
</template>

<script>
    import apiCall from "@/utils/api";
    export default {
        name: "Area",
        props: {
           item: {
             type: Object,
             default: () => {},
           },
            setting: {
               type: Object,
            }
        },
        data(){
          return {
              settings: {}
          }
        },
        created(){
          this.settings = {...this.setting};
        },
        computed: {
            inputLabel(){
                if(!this.item.title){
                    return this.item.level + this.item.id;
                } else {
                    return this.item.title + this.item.id;
                }
            },
            checkedClass(type){
                return type => {
                    if(this.settings[type] === true){
                        return 'checked';
                    }
                    return '';
                }
            },
            borderClass(){
                if(this.settings.email || this.settings.push){
                    return 'border blue';
                }
                return 'border'
            }
        },
        methods: {
            saveNotificationSettings() {
                let id = `groups_${this.item.id}`;
                if(!this.item.title) {
                    id = this.item.id;
                }

                let data = {
                    area_id: id,
                    email: this.settings.email,
                    push: this.settings.push
                };

                apiCall("/user/saveNotificationSettings", { data });
            }
        }
    }
</script>
