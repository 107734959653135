<template>
    <div class="calendar gutter">
        <Calendar/>
    </div>
</template>

<script>
    import Calendar from '@/components/Calendar.vue'

    export default {
        name: 'calendar',
        components: {
            Calendar
        }
    }
</script>
