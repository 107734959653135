export default {
    baseUrl: process.env.VUE_APP_API_DOMAIN + process.env.VUE_APP_API_BASE,
    token: process.env.VUE_APP_API_TOKEN,
    cookieName: 'intralib',
    debounceTimer: 100,
    untilTimeout: process.env.VUE_APP_API_TIMEOUT,

    errorTranslations: {
        '-1': 'errors.unknown',
        'Connection Timeout': 'errors.connection-time-out',
        'User does not exists': 'customer.login.not-found',
        '101': 'customer.login.not-found',
        '102': 'customer.login.wrong-password',
        '105': 'customer.user.not-logged-on',
        '313': 'customer.login.not-found',
        '316': 'customer.register.already-exists',
        'new_password_required': 'customer.password.needs-update',
        'Invalid key': 'customer.password.reset-error',
        '1255': 'discount.code.not-found',
        '4': 'api.errors.timed-out',
        'Resource Not Found': 'api.errors.connection',
        'Access Denied': 'api.errors.connection',
        'Network Error': 'api.errors.connection',
        'noPassengerId': 'api.no-passenger-id-found'
    }
};
