<template>
  <div class="toggle">
    <ul>
      <li @click="$emit('toggle', firstView)" :class="{active: view === firstView}">{{ firstViewText }}</li>
      <li @click="$emit('toggle', secondView)" :class="{active: view === secondView}">{{ secondViewText }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    view: {
      type: String
    },
    firstView: {
      type: String,
      default: "latest"
    },
    secondView: {
      type: String,
      default: "archive"
    }
  },
  computed: {
    firstViewText(){
      if(this.firstView === "latest"){
        return "Senaste";
      } else {
        return "Kalender";
      }
    },
    secondViewText(){
      if(this.firstView === "latest"){
        return "Mappar";
      } else {
        return "Lista";
      }
    }
  }
};
</script>
