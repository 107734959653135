import axios from 'axios';
import apiConfig from '@/config/api';
import merge from 'lodash/merge';

const apiCall = async (endpoint, options) => {
    let endResult;
    
    if (endpoint.startsWith('/')) {
        endpoint = endpoint.substring(1);
    }
    
    const baseUrl = apiConfig.baseUrl.endsWith('/') ? apiConfig.baseUrl : apiConfig.baseUrl + '/';

    if (typeof endpoint === 'string') {
        let headers = {
            Authorization: 'Bearer ' + apiConfig.token,
            'Content-Type': 'application/json',
        };

        let args = {
            url: baseUrl + endpoint,
            method: 'post',
            dataType: 'json',
            timeout: apiConfig.untilTimeout,
            withCredentials: true,
            headers: headers
        };

        if (typeof options !== 'undefined') {
            if (typeof options.data !== 'undefined') {
                args.data = options.data;
            }
            if (typeof options.method !== 'undefined') {
                args.method = options.method;
            }
            if (typeof options.withCredentials !== 'undefined') {
                args.withCredentials = options.withCredentials;
            }
            if(typeof options.direct_mode !== 'undefined' && options.direct_mode === true) {
                args.url = endpoint;
            }
            if (typeof options.headers !== 'undefined') {
                args.headers = merge(args.headers, options.headers);
            }
        }

        try {
            endResult = await fetch(baseUrl + endpoint, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(args.data || {}),
                credentials: "include",
            }).then(async res => await res.json());
            // // console.log({ args });
            // endResult = await axios(args);
        } catch (err) {
            throw err.message;
        }

        if (endResult.data) {
            return Promise.resolve(endResult.data);
        }

        return endResult;
    }

    return {
        error: 'no action selected'
    };
};

export default apiCall;
