<template>
    <div class="member">
        <div class="member_name" @click="openIfSearch">{{ member.name }}</div>
        <div class="member_new">
            <i v-if="member.new" title="Ny medlem" class="mdi mdi-account-plus green"></i>
            <i v-if="!member.new" class="mdi mdi-minus grey"></i>
        </div>
        <div class="member_paid">
            <i v-if="!member.paid" title="Har obetalda fakturor" class="mdi mdi-cash red"></i>
            <i v-if="member.paid" class="mdi mdi-minus grey"></i>
        </div>
        <div class="member_more" @click="openModal"><img src=".././assets/dots.svg"></div>
        <Modal ref="modal_member_more">
            <template slot="header">
                <div class="header">
                    Medlem
                </div>
            </template>
            <template slot="body">

                <div class="member-header">
                    <div class="member-image">
                        <div class="image-holder">
                            <img :src="avatarUrl">
                        </div>
                    </div>
                    <div class="member-info">
                        <div class="member-name">
                            {{ member.name }}
                        </div>
                        <div class="member-mission" v-if="member.title">
                            {{ member.title }}

                        </div>
                    </div>
                </div>

                <div class="member-info-block">
                    <div class="content-header blue">
                        Personuppgifter
                    </div>
                    <div class="member-info" v-if="member.ssn">
                        <div class="content-header">
                            Personnummer
                        </div>
                        <div class="member-content">
                            {{member.ssn}}
                        </div>
                    </div>
                    <div class="member-info" v-if="member.email">
                        <div class="content-header">
                            Mejladress
                        </div>
                        <div class="member-content">
                            <a :href="'mailto:' + member.email">{{member.email}}</a>
                        </div>
                    </div>
                    <div class="member-info" v-if="member.mobile">
                        <div class="content-header">
                            Mobilnummer
                        </div>
                        <div class="member-content">
                            <a :href="'tel:' + member.mobile">{{member.mobile}}</a>
                        </div>
                    </div>
                    <div class="member-info" v-if="member.phone">
                        <div class="content-header">
                            Fastnätsnummer
                        </div>
                        <div class="member-content">
                            <a :href="'tel:' + member.phone">{{member.phone}}</a>
                        </div>
                    </div>
                </div>

                <div class="member-info-block" v-if="!Array.isArray(member.address)">
                    <div class="content-header blue">
                        Adress
                    </div>
                    <div v-if="member.address.restricted === true">
                        <div class="member-content">
                            Vill inte bli kontaktad via adress.
                        </div>
                    </div>
                    <div v-if="member.address.restricted !== true">
                        <div class="member-info" v-if="member.address.address">
                            <div class="content-header">
                                Gata
                            </div>
                            <div class="member-content">
                                {{member.address.address}}
                            </div>
                        </div>
                        <div class="member-info"  v-if="member.address.zip">
                            <div class="content-header">
                                Postnummer
                            </div>
                            <div class="member-content">
                                {{member.address.zip}}
                            </div>
                        </div>
                        <div class="member-info"  v-if="member.address.city">
                            <div class="content-header">
                                Ort
                            </div>
                            <div class="member-content">
                                {{member.address.city}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="member-info-block">
                    <div class="content-header blue">
                        Medlemskap
                    </div>
                    <div class="member-info">
                        <div class="content-header">
                            Typ av medlemskap
                        </div>
                        <div class="member-content">
                            {{member.membership.membership_title}}
                        </div>
                    </div>
                    <div class="member-info">
                        <div class="content-header">
                            Betalstatus
                        </div>
                        <div class="member-content" v-if="member.paid">
                            <i class="mdi mdi-check"></i>Betald
                        </div>
                        <div class="member-content" v-else>
                            <i class="mdi mdi-close"></i>Obetald
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
    import Modal from "./CreateModal";

    export default {
        name: "GroupMember",
        props: {
            member: {
                type: Object,
                required: true
            },
            searchView: {
                type: Boolean,
                default: false
            }
        },
        data(){
            return {
                baseUrl: process.env.VUE_APP_API_DOMAIN+"backend/",
            }
        },
        components: {
            Modal
        },
        computed: {
            avatarUrl(){
                return this.baseUrl + 'avatar/id/' + this.member.member_no;
            },
        },
        methods: {
            openModal(){
                this.$refs.modal_member_more.showModal();
            },
            openIfSearch(){
                if(this.searchView){
                    this.openModal();
                }
            }
        }
    }
</script>

<style scoped>

</style>
