<template>
  <div id="list" class="file-list">
    <Search @opened="searchView = true" @closed="searchView = false" />

    <div v-show="!searchView">
      <Areafilter ref="Areafilter"/>
      <ArchiveToggle @toggle="switchView($event)" :view="view"/>
      <Form :type="'file'" :multipleChoices="true" :currentFolder="lastPath" :currentArea="currentArea"
            :currentFolderStandard="currentFolderStandard" :currentFolderParent="currentFolderParent"/>

      <template v-if="view === 'latest'">

        <div v-if="chosenFiles.length > 0">
          <h3 class="files_heading">Utvalda filer</h3>
          <transition-group name="fade" tag="div">
            <fileitem
                v-for="item in paginatedChosen"
                :key="item.id + 's'"
                :item="item"
                @select="selectItem"
                :selected="allSelected"
                @delete="deleteItem"
                @folderChanged="getArchiveItems($event)"
            />
          </transition-group>

          <template v-if="chosenFiles.length > 3">
          <p v-if="pageNumber === 1" class="show_more" @click="loadMore(2)">
            <img src=".././assets/arrow.svg" > Visa {{chosenLength}} fler
          </p>
          <p v-else class="show_more" @click="loadMore(1)">
            <img src=".././assets/arrow.svg" class="rotate" > Visa mindre
          </p>
          </template>
        </div>
        <div v-if="filteredFiles.length > 0">
          <h3 class="files_heading">Senaste filer</h3>
          <transition-group name="fade" tag="div">
            <fileitem
              v-for="file in latestFiles"
              :key="file.id + 'f'"
              :item="file"
              @select="selectItem"
              :selected="allSelected"
              @delete="deleteItem"
              @folderChanged="getArchiveItems($event)"
            />
          </transition-group>

        </div>
      </template>
      <template v-else>

        <breadcrumbs :path="path" :lastPath="lastPath" @openFolder="openFolder"/>

        <div class="archive-title">
          <h3 class="files_heading">Filarkiv</h3>

          <div class="allow-comments-holder" v-if="lastPath.name === 'Möten'">
            <div class="switch-input">
              <label for="sortByDate" class="switch-label">Sortera på datum</label>

              <label class="switch">
                <input id="sortByDate" type="checkbox" v-model="sortByDate">
                <span class="slider"></span>
              </label>
            </div>
          </div>

        </div>
            <transition-group name="fade" tag="div">
              <fileitem
                      v-for="item in filteredArchiveItems"
                      :key="item.id"
                      :item="item"
                      @select="selectItem"
                      :selected="allSelected"
                      @openFolder="openFolder"
                      @delete="deleteItem"
                      @folderChanged="getArchiveItems($event)"
                      :highlightID="highlightID"
              />
            </transition-group>
      </template>
      <div class="load_wrapper">
        <Loader type="component"/>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import Form from "./Form";
  import apiCall from "@/utils/api";
  import Loader from "@/components/Loader";
  import Search from "./Search";
  import Areafilter from "./Areafilter";
  import Fileitem from "@/components/Fileitem";
  import ArchiveToggle from "@/components/ToggleViews";
  import Breadcrumbs from "@/components/Breadcrumbs";
  import moment from 'moment';

  export default {
  name: "FileList",
  components: {
    Areafilter,
    Search,
    Form,
    fileitem: Fileitem,
    ArchiveToggle,
    Breadcrumbs,
    Loader
  },
  computed: {
      ...mapGetters({
          archive: 'items/archive',
          files: 'items/files',
          currentGroup: 'user/currentGroup',
          path: 'items/path',
          archiveNeedsInitialLoad: "items/archiveNeedsInitialLoad",
          filesNeedsInitialLoad: "items/filesNeedsInitialLoad"
      }),
      folderSlug(){
          return this.$route.params.folderSlug;
      },
      fileSlug(){
          return this.$route.params.fileSlug;
      },
    filteredArchiveItems(){
      if(!this.componentLoaded){
        return [];
      }
      if(this.currentGroup.id > 0) {
          return this.archive;
      } else {
         const archive = this.archive.filter(item =>
                this.$refs.Areafilter.filters.includes(parseInt(item.area_level)));
         if(this.sortByDate === true && this.lastPath.name === "Möten") {
            return archive.sort(this.compareEventFolderDates);
         } else {
           return archive;
         }
      }
    },
    filteredFiles() {
      if(!this.componentLoaded){
        return [];
      }
      if(this.currentGroup.id > 0) {
         return this.files;
      } else {
        return this.files.filter(item =>
                this.$refs.Areafilter.filters.includes(parseInt(item.area_level))
        );
      }
    },
    latestFiles(){
      return this.filteredFiles.slice(0, 5);
    },
    chosenFiles(){
      return this.filteredFiles.filter(item => item.chosen === true);
    },
    paginatedChosen(){
      if(this.chosenFiles.length > 3 && this.pageNumber === 1) {
        let sliced = this.chosenFiles.slice(0,3);
        return sliced;
      } else {
        return this.chosenFiles;
      }
    },
    chosenLength(){
      if(this.chosenFiles.length > 8){
        return '8+';
      } else {
        return this.chosenFiles.length - 3;
      }
    },
    selectedIds() {
      return this.selectedItems.map(file => file.id);
    },
    lastPath(){
        return this.path.slice(-1).pop();
    },
  },
  data() {
    return {
      title: "Filer",
      single_name: "filen",
      view: "latest",
      selectedItems: [],
      pageNumber: 1,
      allSelected: false,
      showBulkActions: false,
      componentLoaded: false,
      searchView: false,
      highlightID: "",
      currentArea: null,
      currentFolderStandard: false,
      currentFolderParent: null,
      sortByDate: false
    };
  },

  methods: {
    ...mapActions({
      showLoading: "loader/show",
      hideLoading: "loader/hide"
    }),
    compareEventFolderDates(a, b){
      if(moment(a.start_date).isBefore(b.start_date)){
        return -1;
      }
      return 0;
    },
    loadMore(page){
      this.pageNumber = page;
    },
    deleteItem(itemID = this.selectedIds) {

        if(this.view === "archive"){

          this.$store.dispatch("items/deleteItem", ["archive", itemID, null]);
        } else {
          this.$store.dispatch("items/deleteItem", ["file", itemID, null]);
        }
    },
    openFolder(folder){
      this.sortByDate = false;
      this.currentArea = folder.area;
      this.currentFolderStandard = folder.standard;
      this.currentFolderParent = folder.parent;
      this.showLoading("component");
      this.getArchiveItems(folder.id).then(res => {
        this.hideLoading("component");
      });
      this.highlightID = "";
    },
    selectItem(selected, file) {
      // @todo Not in use ?
      if (selected) {
        this.selectedItems.push(file);
        if (!this.showBulkActions) {
          this.showBulkActions = true;
        }
      } else {
        this.selectedItems = this.selectedItems.filter(
          file => file.id !== file.id
        );
        if (this.selectedItems.length < 1) {
          this.showBulkActions = false;
        }
      }
    },
    switchView(view){
      this.view = view;
      this.allSelected = false;
      this.showBulkActions = false;
      this.selectedItems = [];
      this.highlightID = "";
    },
    getFiles(){
        return this.$store.dispatch("items/loadItems", "file");
    },
    getArchiveItems(folder = 0, url = "/item/getFolder"){
        return this.$store.dispatch("items/loadArchive", [folder, url]);
    },
    loadItems(){
      if(this.archiveNeedsInitialLoad === true && this.filesNeedsInitialLoad === true) {
          this.showLoading("component");
          this.getArchiveItems().then(res => {
            this.getFiles().then(res => {
              this.hideLoading("component");
            })
          })
      } else if (this.archiveNeedsInitialLoad === true) {
        this.showLoading("component");
        this.getArchiveItems().then(res => {
            this.hideLoading("component");
        })
      } else if(this.filesNeedsInitialLoad === true) {
        this.getFiles().then(res => {
          this.hideLoading("component");
        })
      }
    },
    showSearchItem(){
      this.view = 'archive';
      this.showLoading("component");
      this.getArchiveItems(this.folderSlug, "/item/getFolderFromSlug").then(res => {
        this.hideLoading("component");
      });

      if(this.fileSlug !== undefined){
        /**
         * Item is a file and should be highlighted
         */
        let data = {
          type: 'file',
          slug: this.fileSlug
        };

        this.showLoading("component");
        apiCall("/item/loadItemFromSlug", {data}).then(res => {
          this.hideLoading("component");
          this.highlightID = res.id;
        })
      }
    },
  },
  mounted(){
    this.componentLoaded = true;
  },
  created() {
    if(this.folderSlug !== undefined){
      this.showSearchItem();
    } else {
      this.highlightID = "";
      this.loadItems();
    }
  },
    watch:{
      '$route' (to, from) {
        /**
         * Every time the route changes, check if the search item should be shown
         */
          if(this.folderSlug !== undefined){
            this.showSearchItem();
          } else {
            this.highlightID = "";
            this.loadItems();
          }
      }
    }
};
</script>
