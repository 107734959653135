<template>
    <div class="search-wrapper">
        <div id="search-input">
            <div class="closer" v-if="searchView">
                <div @click="closeSearch"><img src="../assets/close.svg" /></div>
            </div>
            <img src="../assets/search.svg" />
            <input type="text" placeholder="Sök" @click="openSearch" @keyup="search" v-model="searchText" />
        </div>

        <transition name="search-holder">
            <div v-if="searchView" class="search-box">
                <p class="search-description">Söker på filer, inlägg, mappar & events</p>
                <div v-if="recentSearches.length > 0 && !searching">
                    <div class="headline gray">Tidigare sökningar</div>
                    <ul class="recent-searches" v-if="!searching">
                        <template v-for="recent in recentSearches">
                            <li :key="recent.id" @click="searchRecent(recent.title)">{{recent.title}}</li>
                        </template>
                    </ul>
                </div>

                <div class="headline blue" v-if="searching">Sökresultat</div>
                <div class="search-results">
                    <div v-if="files.length === 0 && folders.length === 0 && events.length === 0 && posts.length === 0 && searching && !loading" class="no-results">
                        <i class="mdi mdi-cloud-question"></i> Inga resultat kunde hittas.
                    </div>

                    <template v-for="item in files">
                        <search-item :item="item" @click="saveToLocalStorage(item)" :key="item.id"/>
                    </template>

                    <template v-for="item in posts">
                        <search-item :item="item" @click="saveToLocalStorage(item)" :key="item.id"/>
                    </template>

                    <template v-for="item in folders">
                        <search-item :item="item" @click="saveToLocalStorage(item)" :key="item.id"/>
                    </template>

                    <template v-for="item in events">
                        <router-link :to="{path: '/calendar/' + item.slug} " :key="item.id" v-on:click.native="saveToLocalStorage(item)">
                            <calendar-item :eventID="item.id" searchItem/>
                        </router-link>
                    </template>
                </div>


            </div>
        </transition>

        <div class="load_wrapper">
            <Loader type="component"/>
        </div>
    </div>
</template>

<script>
    import apiCall from "@/utils/api";
    import { mapActions } from "vuex";
    import { mixin as clickaway } from 'vue-clickaway';
    import CalendarItem from "@/components/CalendarItem.vue";
    import Loader from "@/components/Loader";
    import SearchItem from "@/components/SearchItem";

    export default {
        name: "Search",
        data(){
            return {
                searchView: false,
                searchText: "",
                result: [],
                recentSearches: JSON.parse(localStorage.getItem("searchItems")) !== null ? JSON.parse(localStorage.getItem("searchItems")).reverse() : [],
                searching: false,
                search_interval: null,
                loading: false
            }
        },
        components: {
            CalendarItem,
            Loader,
            SearchItem
        },
        mixins: [clickaway],
        computed: {
            files(){
              return this.result.filter(item => item.type === 'file');
            },
            folders(){
              return this.result.filter(item => item.type === 'folder');
            },
            events(){
              return this.result.filter(item => item.type === 'event')
            },
            posts(){
                return this.result.filter(item => item.type === 'post')
            },
            itemID(item){
                return item => {
                    if(item.type === 'folder'){
                        return item.id;
                    } else {
                        return item.folder;
                    }
                }
            }
        },
        methods: {
            ...mapActions({
                showLoading: "loader/show",
                hideLoading: "loader/hide"
            }),
            openSearch(){
                this.searchView = true;
                this.$emit('opened');
                this.$router.push({hash: 'search' })
            },
            closeSearch(){
                this.searchText = "";
                this.result = [];
                this.searching = false;
                this.$emit('closed');
                this.$router.push({hash: '' })
            },
            searchRecent(title){
                this.searchText = title;
                this.search();
            },
            search(){
                if(this.searchText.length > 1){
                    this.searching = true;
                    this.loading = true;
                    this.showLoading("component");
                    if(this.search_interval !== null) {
                        clearInterval(this.search_interval);
                    }
                    this.search_interval = setTimeout(() => {
                            let data = {
                                title: this.searchText
                            };
                            apiCall("item/search", {data}).then(res => {
                                this.hideLoading("component");
                                this.result = res;
                                this.loading = false;
                            })
                        },
                        700);
                } else {
                    this.result = [];
                    this.searching = false;
                }

            },
            saveToLocalStorage(item){
                this.searchView = false;
                this.$emit('closed');
                this.searchText = "";
                let recentSearches = JSON.parse(localStorage.getItem("searchItems"));
                if(recentSearches === null){
                    recentSearches = [];
                }
                if(recentSearches.length >= 4){
                    let sliceLength = recentSearches.length - 4;
                    recentSearches = recentSearches.slice(sliceLength);
                }
                let duplicate = recentSearches.filter(searchItem => searchItem.title === item.title);
                if(duplicate.length === 0){
                    recentSearches.push(item);
                    this.recentSearches = recentSearches;
                    localStorage.setItem("searchItems", JSON.stringify(recentSearches));
                }
            }
        },
        watch: {
            '$route' (to, from) {
                if(to.hash === '#search'){
                    this.searchView = true;
                    this.$emit('opened');
                } else {
                    this.$emit('closed');
                    this.searchView = false;
                }
            }
        }
    }
</script>
