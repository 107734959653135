<template>
    <div class="icon-wrapper">
        <Lottie v-if="loaded" :options="defaultOptions" :height="40" :width="40" v-on:animCreated="handleAnimation"/>
    </div>
</template>

<script>
    import Lottie from 'vue-lottie';
    import * as animationData from '@/assets/flagAnimation.json';

    export default {
        name: "FlagAnimation",
        data(){
            return {
                defaultOptions: { animationData: animationData.default },
                animationSpeed: 1,
                loaded: false
            }
        },
        components: {
            Lottie
        },
        methods: {
            handleAnimation: function (anim) {
                this.anim = anim;
            },
        },
        mounted(){
            setTimeout(() => {
                this.loaded =true;
            }, 0);
        }
    }
</script>
