<template>
    <div class="anchor-link-modal">
        <div class="smart-field">
            <input type="text" name="link-text" id="link-text" ref="link-text" v-model="linkText" placeholder=" " />
            <label for="link-text">Text som ska visas</label>
        </div>
        <div class="smart-field">
            <input type="text" name="link" id="anchor-link" ref="anchor-link" v-model="anchorLink" placeholder=" "  />
            <label for="anchor-link">Länkadress</label>
        </div>
        <div class="button-wrapper">
            <button type="button" class="btn blue" @click="submit">Ok</button>
            <button type="button" class="btn" @click="$emit('close')">Avbryt</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AnchorLinkModal",
        data(){
          return {
              anchorLink: "",
              linkText: ""
          }
        },
        methods: {
            submit(){
                if(!this.anchorLink.startsWith("www.") && !this.anchorLink.startsWith("http://") && !this.anchorLink.startsWith("https://")){
                    this.anchorLink = "http://" + this.anchorLink;
                }
                let data = {
                    text: this.linkText,
                    link: this.anchorLink
                };
                this.$emit('submit', data);
            }
        }
    }
</script>

<style scoped>

</style>
