<template>
  <div>
    <filelist/>
  </div>
</template>

<script>
import FileList from "@/components/FileList.vue";

export default {
  name: "Archive",
  components: {
    filelist: FileList
  },
  props: {
    type: {
      type: String,
      default: "file"
    }
  },
};
</script>

