import persistentStorage from './persistentStorage';
import apiCall from './api';

export default {
    _loggedIn: false,
    _registered: false,

    register(token) {
        if (token) {
            persistentStorage.setItem('push_token', token);
        }
    },

    async store(userUuid) {
        const token = await persistentStorage.getItem('push_token');
        const deviceId = await persistentStorage.getItem('device_id');

        if (!token || !deviceId) {
            return;
        }

        const userAgent = window.navigator.userAgent.toLowerCase();
        const platform = /iphone|ipod|ipad/.test(userAgent) ? 'ios' : 'android';

        return apiCall('/user/registerPushToken', {
            data: {
                user_uuid: userUuid,
                token: token,
                platform: platform,
                device_id: deviceId
            }
        }).then(res => {
            if (res && (res.success || res.key_already_exists)) {
                this._registered = true;
            }
            return res;
        }).catch(err => {
            console.log('----> TOKEN ERRROR');
            console.log(err);
        });
    }
}
